.MemberInfoAllSlide {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 190vw;
}
#MemberGraphDiv {
  position: absolute;
  top: 8vw;
  left: 0;
  width: 100%;
  height: -moz-fit-content;
  height: 100vw;
}
#MemberInfoCommon {
  position: absolute;
  top: 100vw;
  left: 5%;
  width: 90%;
  height: 70vw;
  border-radius: 26px;
}
#ManageSMIBtn {
  position: absolute;
  top: 29vw;
  right: 0%;
  font-size: 5vw;
  line-height: 10vw;
  margin: 0;
  padding: 0px 6vw;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;
  color: black;
  z-index: 50;
}
#MemberDaysDiv {
  position: absolute;
  top: 43vw;
  left: 0%;
  width: 30%;
  height: 37vw;
  background-color: #363636;
  border-radius: 10px;
}
#MemberDaysDiv p:nth-child(1),
#MemberMadeTWeekDiv p:nth-child(1),
#MemberGrowthRateDiv p:nth-child(1) {
  position: absolute;
  top: 8vw;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #ffbf12;
  font-size: 7vw;
}
#MemberDaysDiv p:nth-child(2),
#MemberMadeTWeekDiv p:nth-child(2),
#MemberGrowthRateDiv p:nth-child(2) {
  position: absolute;
  top: 25vw;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: 3.3vw;
}
#MemberMadeTWeekDiv {
  position: absolute;
  top: 43vw;
  left: 34%;
  width: 32%;
  height: 37vw;
  background-color: #363636;
  border-radius: 10px;
}
#MemberGrowthRateDiv {
  position: absolute;
  top: 43vw;
  right: 0%;
  width: 30%;
  height: 37vw;
  background-color: #363636;
  border-radius: 10px;
}
