.Action__Background{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.451);
    z-index: 1100;

}
.ActionsMenu{
    position: fixed;
    width: 90vw;
    left: 5vw;
}
.ActionsMenu__container{
    margin-bottom: 5vw;
    border-radius: 5vw;
    background-color: white;
    box-shadow: 0 0 0.5vw #ffbf12;
}
.ActionsMenu__button{
    text-align: center;
    font-size: 4.25vw;
    line-height: 12vw;
    margin: 0 ;
    color: black;
    border: 0.25px solid black;
    /* background-color: white; */
}

