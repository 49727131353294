:root {
    
/* Colors: */
--unnamed-color-121212: #121212;
--unnamed-color-ffffff: #FFFFFF;
--unnamed-color-ffbf12: #FFBF12;
--unnamed-color-902366: #902366;
--unnamed-color-24beff: #24BEFF;
--unnamed-color-e66100: #E66100;
--unnamed-color-34c100: #34C100;

/* Font/text values */
--unnamed-font-family-open-sans: open-sans;
--unnamed-font-family-monarcha: monarcha;
--unnamed-font-style-normal: normal;
--unnamed-font-weight-600: 600px;
--unnamed-font-weight-bold: bold;
--unnamed-font-size-14: 14px;
--unnamed-font-size-16: 16px;
--unnamed-font-size-30: 30px;
--unnamed-character-spacing-0: 0px;
--unnamed-line-spacing-19: 19px;
--unnamed-line-spacing-22: 22px;
--unnamed-line-spacing-40: 40px;
--unnamed-text-transform-uppercase: uppercase;
}

/* Character Styles */
.unnamed-character-style-1 {
font-family: var(--unnamed-font-family-monarcha);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-30);
line-height: var(--unnamed-line-spacing-40);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-ffffff);
text-transform: var(--unnamed-text-transform-uppercase);
}
.headline {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-30);
line-height: var(--unnamed-line-spacing-40);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-ffffff);
}
.body-copy-bold {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-bold);
font-size: var(--unnamed-font-size-14);
line-height: var(--unnamed-line-spacing-19);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-ffffff);
}
.body-copy {
font-family: var(--unnamed-font-family-open-sans);
font-style: var(--unnamed-font-style-normal);
font-weight: var(--unnamed-font-weight-600);
font-size: var(--unnamed-font-size-16);
line-height: var(--unnamed-line-spacing-22);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-ffffff);
}