.Tabs {
  position: relative;
  width: 90vw;
}
.Tabs__Btns {
  width: 84vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
}
.Tabs__Btn {
  width: fit-content;
  font-size: 4.5vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
  color: #dadada;
}
.Tabs__BasicLine {
  display: block;
  width: 84vw;
  margin: 3vw 0vw;
}
.Tabs__Tab1SwitchLine {
  position: absolute;
  bottom: 0;
  left: 0vw;
  transition: all 0.5s;
}
.Tabs__Tab2SwitchLine {
  position: absolute;
  bottom: 0;
  right: 0vw;
  transition: all 0.5s;
}
