.AgentNotIPOedCreativeCard {
  position: relative;
  width: 100%;
  height: 34vw;
  box-shadow: 0px 13px 9px -6px #121212;
  z-index: 12;
}
#AgencyCreativeCard * {
  margin: 0;
  line-height: 5vw;
}
.AgentNotIPOedCreativeCard__Background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1f1f1f;
}
.AgentNotIPOedCreativeCard__Avatar {
  position: absolute;
  left: 4vw;
  top: 5.5vw;
  width: 23vw;
  height: 23vw;
  border: 5px solid #34c100;
  border-radius: 50%;
  box-shadow: 0px 7px 20px -7px black;
  background-size: 21vw 21vw;
}
.AgentNotIPOedCreativeCard__StarsContainer {
  width: 45vw;
}
.AgentNotIPOedCreativeCard__DataContainer {
  position: absolute;
  top: 4vw;
  left: 32vw;
  width: 52vw;
  height: 28vw;
}
.AgentNotIPOedCreativeCard__Name {
  font-size: 6vw;
  margin: 1vw 0 3vw;
  line-height: 5vw;
  text-align: left;
}
.AgentNotIPOedCreativeCard__NameLong {
  margin: 2vw 0;
  line-height: 5vw;
  font-size: 4vw;
  text-align: left;
}
.AgentNotIPOedCreativeCard__AgencyTxt {
  font-size: 3vw;
  font-weight: normal;
  margin: 1.5vw 0;
  line-height: 3vw;
  text-align: left;
}
.AgentNotIPOedCreativeCard__Location {
  font-size: 3vw;
  margin: 1.5vw 0;
  line-height: 3vw;
  text-align: left;
}
