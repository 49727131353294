.WellcomeMC {
  padding-bottom: 30vh !important;
}
.WellcomeMC__BackBtn {
  position: absolute;
  top: 7vw;
  left: 0;
  width: 12vw;
  z-index: 90;
  /* border: 0.5vw solid steelblue; */
  box-shadow: 0px 4vw 14.5vw 0vw black;
  /* border-left: 0; */
  border-bottom-right-radius: 10vw;
  border-top-right-radius: 10vw;
}
.WellcomeMC__BackgroundImg {
  width: 100%;
  height: 97vw;
  background-position: 0 0;
  background-size: contain;
  z-index: -1;
}
.WellcomeMC__WelcomeTxt {
  width: 100%;
  text-align: center;
  font-size: 6vw;
  line-height: 7vw;
  margin: -25vw 0;
  z-index: 2;
}
.WellcomeMC__Shadow {
  margin: 38vw 0 0 0;
  width: 100%;
  height: 30vw;
  background-color: black;
  box-shadow: 0 -12vw 21vw 8vw black;
}

.WellcomeMC__QuotationMCBegin {
  position: absolute;
  top: 91vw;
  left: 2vw;
  width: 13%;
}
.WellcomeMC__EmpoweringTxt {
  width: 100%;
  text-align: center;
  font-size: 3.9vw;
  line-height: 5vw;
  margin: -32vw 0;
  font-weight: 500;
}
.WellcomeMC__QuotationMCEnd {
  position: absolute;
  top: 114vw;
  left: 84vw;
  width: 13%;
}
.WellcomeMC__BtnsContainer {
    position: relative;
  display: flex;
  justify-content: space-evenly;
}
.WellcomeMC__WhatsTheVibeBtn {
  font-size: 3.5vw;
  line-height: 8vw;
  margin: 0;
  padding: 0 2.2vw;
  text-align: center;
  color: #ffbf12;
}
.WellcomeMC__ShowMoneyBtn {
  font-size: 3.5vw;
  line-height: 8vw;
  margin: 0;
  padding: 0 2.2vw;
  text-align: center;
  color: #ffbf12;
}
.WellcomeMC__selectedBtn {
    position: absolute;
    top: -1.2vw;
    height: 10vw;
    border: 0.7vw solid #ffffff;
    border-radius: 10vw;
    transition: .5s;
    margin: 0;
}
/* .WellcomeMC__WhatsTheVibeDiv{
    width: 100%;
    height: 120vw;
} */
.WellcomeMC__WhatsTheVibeExp {
  padding: 0vw 5vw;
  font-size: 4vw;
  font-weight: 500;
  line-height: 6vw;
  text-align: left;
  margin-top: 9vw;
}

.WellcomeMC__ShowMoneyDiv {
  width: 100%;
  margin-top: 14vw;
}
.WellcomeMC__StatsShowMoney {
  width: 100%;
  font-size: 15vw;
  line-height: 9vw;
  text-align: center;
  margin: 0;
}
.WellcomeMC__StatsShowMoney span {
  color: #ffbf12;
}
.WellcomeMC__StatsShowMoney__YouWillGetTxt {
  font-size: 4vw;
  line-height: 6vw;
  text-align: left;
  margin-top: 13vw;
  padding: 0 5vw;
}

.WellcomeMC__TheProcessStagesLine {
  position: absolute;
  top: 37vw;
  left: 5vw;
  border: 0.73vw solid #ffbf12;
  border-radius: 10vw;
  background: white;
}
.WellcomeMC__TheProcess__TxtContainer {
  width: 82vw;
}
.WellcomeMC__TheProcessNumbers {
    border: 1vw solid #ffbf12;
    border-radius: 10vw;
    height: 13.5vw;
    line-height: 3.5vw;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4vw;
    margin: 0;
    z-index: 2;
    background: black;
    position: relative;
}
.WellcomeMC__TheProcess__TxtContainer h2{
    padding-left: 10vw;
    font-size: 5vw;
    margin: 1vw 0;
    line-height: 6vw;
}
.WellcomeMC__TheProcess__TxtContainer p:nth-child(3){
        margin: 0;
        padding-left: 10vw;
        font-size: 3.8vw;
        line-height: 6vw;
}
.WellcomeMC__NavBtnsContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20vw;
  background-color: black;
  box-shadow: 0 -4vw 21vw 6vw black;
  z-index: 10;
}
.WellcomeMC__ArrowLeftBtn {
  position: absolute;
  top: 5vw;
  left: 5vw;
  width: 9vw;
}
.WellcomeMC__LearnMoreBtn {
  position: absolute;
  top: 7.5vw;
  left: 18vw;
  color: #707070;
  font-size: 4vw;
  line-height: 4vw;
  margin: 0;
}
.WellcomeMC__RegisterBtn {
  position: relative;
  float: right;
  background-color: #ffbf12;
  border-bottom-left-radius: 10vw;
  border-top-left-radius: 10vw;
  font-size: 5vw;
  line-height: 12vw;
  margin: 3vw 0;
  padding-left: 5vw;
  padding-right: 3vw;
}
