.HeaderWithLogo {
    position: fixed;
    top: 0vw;
    left: 0%;
    width: 100vw;
    height: 15vw;
    background-color: #1f1f1f;
    z-index: 15;
}

.HeaderWithLogo__Logo {
    position: absolute;
    top: 8vw;
    left: 5%;
    width: 36%;
}