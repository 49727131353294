#PassionPrdctDiv {
    position: relative;
    width: 100%;
    height: 15vw;
    margin: 0 0 10vw;
    background-color: #2E2E2E;
    border-radius: 10px;
}
#SMInstaLogo{
    position: absolute;
    top: 2vw;
    left: 2vw;
    width: 10vw;
}
#SMAmount {
    position: absolute;
    top: 4.5vw;
    left: 18vw;
    margin: 0;
    line-height: 4vw;
    font-size: 4vw;
}
#SMIHashtag {
    position: absolute;
    top: 4.5vw;
    right: 3vw;
    margin: 0;
    line-height: 4vw;
    font-size: 4vw;
}
#TradeBackBtn {
    position: absolute;
    top: 12vw;
    left: 16vw;
    margin: 0;
    padding: 0 4vw;
    line-height: 7.8vw;
    font-size: 4vw;
    background-color: white;
    color: #ffbf12;
    border-radius: 17px;
}