#LegalDiv{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 120vw;
}
#LegalTitle {
    position: absolute;
    top: 23vw;
    left: 15%;
    font-size: 5vw;
    margin: 0;
    line-height: 5vw;
}
#LegalCircleL{
    position: absolute;
    top: 43vw;
    left: 15%;
    width: 7%;
}
#TermAndCTitle {
    position: absolute;
    top: 44.5vw;
    left: 25%;
    font-size: 5vw;
    line-height: 5vw;
    margin: 0;
}
#TermsOfUseIcon{
    position: absolute;
    top: 56.5vw;
    left: 15%;
    width: 7%;
}
#TermOfUseTitle{
    position: absolute;
    top: 49vw;
    left: 25%;
    font-size: 5vw;
}
#PrivatePolicyIcon {
    position: absolute;
    top: 55vw;
    left: 15%;
    width: 7%;
}
#PrivatePTitle {
    position: absolute;
    top: 56.5vw;
    left: 25%;
    font-size: 5vw;
    line-height: 5vw;
    margin: 0;
}