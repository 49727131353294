.GoingLive_IntroMessage__Icon {
  position: relative;
  width: 27px;
  display: block;
  margin: 0 auto;
}
.GoingLive_IntroMessage h1 {
  font-size: 20px;
  margin: 2vw auto 5vw;
  position: relative;
text-align: center;}
.GoingLive_IntroMessage h2 {
  position: relative;
  text-align: center;
  font-size: 12px;
  line-height: 17px;
  margin: 2vw auto 4vw;
  font-weight: lighter;
}
.GoingLive_IntroMessage p {
    font-size: 12px;
    line-height: 17px;
  margin: 2vw auto 5vw;
  font-weight: lighter;
}
.GoingLive_IntroMessage span {
    font-size: 12px;
    line-height: 15px;
  /* margin: 5vw auto ; */
  font-weight: lighter;
}
