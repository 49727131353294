.WalletHeader{
    position: relative;
    padding: 20vw 0 0;
}
.WalletHeader__BtnsContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
}
.WalletHeader button {
  width: 35vw;
  text-align: center;
  font-size: 4vw;
  border-radius: 3vw;
  font-weight: normal;
  box-shadow: 0 0 3px 1px #90909054;
  margin:  10px;
}
.WalletHeader__DepositBtn {
    background-color: #ffbf12;
    border: 0;
    line-height: 8vw;
    color: black;

  }
  .WalletHeader__WithdrawBtn {
    line-height: 7.5vw;
    border: 0.5vw solid #ffbf12;
    box-shadow: inset 0px 0px 6px 0px #90909054, 0 0 6px #90909054;
    background: none;
    color: white;
  }
  