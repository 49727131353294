.Step1MC{
  /* min-height: 100vh !important; */
}
.Step1MC__Emoji {
  display: block;
  margin: 13vw auto 6vw;
  width: 23%;
}
.Step1MC__MoneyCHeadline {
  position: relative;
  width: fit-content;
  text-align: center;
  font-size: 9.5vw;
  margin: 0 auto;
  line-height: 12vw;
  text-transform: initial;
  z-index: 4;
}
.Step1MC__HeadlineS1 {
  margin-top: -8.5vw;
  color: black;
  text-shadow: 0.55vw 0 #505050, -0.55vw 0 #505050, 0 0.55vw #505050,
    0 -0.55vw #505050;
  z-index: 3;
  position: relative;
}
.Step1MC__HeadlineS2 {
    margin-top: -8.5vw;
    color: black;
    text-shadow: 0.25vw 0 #505050, -0.25vw 0 #505050, 0 0.25vw #505050, 0 -0.25vw #505050;
    z-index: 2;
    position: relative;
}
.Step1MC__Step1Txt {
    font-size: 8vw;
    margin: 0vw 0 1vw;
    text-transform: initial;
    line-height: 10vw;
}
.Step1MC__DiscoverTxt {
  font-size: 6vw;
  margin: 0 0 1vw;
  line-height: 7vw;
}
.Step1MC__Step1ExpTxt {
  font-size: 4vw;
  margin: 0;
  line-height: 6vw;
}
.Step1MC__YouCanMakeTxt {
  font-size: 4.75vw;
  margin: 0;
  line-height: 7vw;
}
.Step1MC__RevenueTxt {
  font-size: 13vw;
  margin: 0;
  line-height: 15vw;
  text-transform: initial;
  text-shadow: 0 0vw 3vw #ffbf12, 0.25vw 0 0 #ffbf12, -0.25vw 0 #FFBF12,0 0.25vw #ffbf12,0 -0.25vw #FFBF12;
}
/* #InstaSFollows{
    position: absolute;
    top: 145vw;
    left: 8%;
    font-size: 6vw;
    margin: 0;
    line-height: 7vw;
} */
.Step1MC__InstagramIcon {
    position: absolute;
    top: 1.5vw;
    left: 0.5vw;
    width: 9vw;
    z-index: 2;
}
/* #InstaSliderPos{
    position: absolute;
    top: 155vw;
    left: 18%;
    width: 74%;
} */
.Step1MC__InputInstaFollows {
    width: 100%;
    height: 10vw;
    line-height: 10vw;
    font-size: 5vw;
    padding: 0 28vw 0 11vw;
    margin: 0;
    border-radius: 2.25vw;
    border: none;
    color: black;
}
.Step1MC__InstaFollowsTxt {
    position: absolute;
    top: 1.5vw;
    right: 2.5vw;
    line-height: 10vw;
    font-size: 5vw;
    margin: 0;
    color: black;
    z-index: 1;
}
/* #TiktokSFollows{
    position: absolute;
    top: 165vw;
    left: 8%;
    font-size: 6vw;
    margin: 0;
    line-height: 7vw;
} */
.Step1MC__TikTokIcon {
  position: absolute;
  top: 2vw;
  left: 1.5vw;
  width: 7.5vw;
  z-index: 1;
}
/* #TiktokSliderPos{
    position: absolute;
    top: 175vw;
    left: 18%;
    width: 74%;
} */
.Step1MC__InputTikTokFollows {
    width: 100%;
    height: 10vw;
    line-height: 10vw;
    font-size: 5vw;
    padding: 0px 28vw 0 11vw;
    margin: 0;
    border-radius: 2.25vw;
    border: 0;
    color: black;
}
.Step1MC__TiktokFollowsTxt {
  position: absolute;
  top: 1.5vw;
  right: 2.5vw;
  line-height: 10vw;
  font-size: 5vw;
  margin: 0;
  color: black;
  z-index: 1;
}

/* #YoutubeSSubs{
    position: absolute;
    top: 185vw;
    left: 8%;
    font-size: 6vw;
    margin: 0;
    line-height: 7vw;
} */
.Step1MC__YoutubeIcon {
  position: absolute;
  top: 0.7vw;
  left: -0.4vw;
  width: 11vw;
  z-index: 1;
}
/* #YoutubeSliderPos{
    position: absolute;
    top: 195vw;
    left: 18%;
    width: 74%;
} */
.Step1MC__InputYoutubeSubs {
  width: 100%;
  height: 10vw;
  line-height: 10vw;
  font-size: 5vw;
  padding: 0px 33vw 0 11vw;
  margin: 0;
  border-radius: 10px;
  border: none;
  color: black;
}
.Step1MC__YoutubeSubsTxt {
  position: absolute;
  top: 1vw;
  right: 2.5vw;
  line-height: 10vw;
  font-size: 5vw;
  margin: 0;
  color: black;
  z-index: 1;
}

.Step1MC__NextBtn {
  /* float: right; */
  width: fit-content;
  font-size: 5vw;
  line-height: 9vw;
  text-align: center;
  padding: 0px 17vw;
  margin: 0 auto 14vw;
  border-radius: 15px;
  background-color: #ffbf12;
}