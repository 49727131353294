.SalesFeed {
  margin: 8vw 0 30vw;
}
.SalesFeed__Loader {
  position: relative;
  margin: 0 auto;
  width: 8vw;
  height: 8vw;
}
.SalesFeed__NoResultsTxt {
  position: relative;
  left: 5vw;
  width: fit-content;
  text-align: left;
  font-size: 4vw;
  line-height: 6vw;
  margin: 0;
}
.SalesFeed__UnableTxt {
  font-size: 4.5vw;
  margin: 0;
}