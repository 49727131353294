.Menu__AcademyIcon {
  display: block;
  margin: 0 auto;
  width: 55px;
}
.Menu__PSAcademyTxt {
  position: relative;
  margin: 0 auto 10px;
  text-align: center;
  font-weight: lighter;
  font-size: 16px;
  line-height: 15px;
}
.Menu__JoinTxt {
  font-weight: lighter;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  padding: 0 12vw;
}
.Menu__JoinBtn {
  display: block;
  padding: 0 12px;
  border: 2px solid #ffbf12;
  border-radius: 8px;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto 20px auto;
  background: 0;
}
.Menu__ToJoinTxt{
  font-size: 14px;
  line-height: 16px;
  color:#e66100;
  font-weight: lighter;
  text-align: center;
}
.Menu__LogOutBtn {
  display: block;
  width: 40vw;
  padding: 0 11vw;
  border: 2px solid #902366;
  border-radius: 10px;
  font-size: 4vw;
  line-height: 30px;
  text-align: center;
  margin: 0 auto 9vw auto;
  background: 0;
}
.Menu__SM {
  display: flex;
  justify-content: flex-end;
  margin: 0  5px 0 0;
}
.Menu__SMItem {
  display: block;
  width: 45px;
  padding: 0 4px;
}