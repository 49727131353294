.InfluencerProfile {
  position: absolute;
  top: 0vw;
  left: 0%;
  transition: left 0.8s;
}
.InfluencerProfile__Backgorund {
  width: 100%;
  height: 57vw;
  background-size: 100vw auto;
}
.InfluencerProfile__EditIcon {
  position: absolute;
  top: 2.5vw;
  right: 2vw;
  width: 8%;
}
.InfluencerProfile__StockPriceChangesTxt {
  position: absolute;
  top: 0vw;
  right: 8vw;
  background-color: #1f1f1f;
  border-radius: 15px;
  padding: 2.5vw;
  padding-bottom: 6vw;
  font-size: 3vw;
  line-height: 4vw;
  text-align: right;
  margin: 0;
}
.InfluencerProfile__CreativeCardPos {
  position: absolute;
  top: 12vw;
  left: 8%;
  width: 84%;
  height: 34vw;
}
.InfluencerProfile__ResponsesPos {
  position: absolute;
  top: 45.25vw;
  left: 8%;
  width: 85%;
}

.InfluencerProfile__InteractBtnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 84vw;
  margin: 4vw auto;
}
.InfluencerProfile__MyManagerBtn {
  padding: 0 2vw;
  font-size: 4vw;
  line-height: 8vw;
  border: 0.9vw solid #ffffff;
  border-radius: 3vw;
  margin: 0;
  width: fit-content;
}
.InfluencerProfile__MyBrandBtn {
  width: fit-content;
  font-size: 4vw;
  line-height: 8vw;
  border: 0.9vw solid #902366;
  border-radius: 8px;
  margin: 0 2vw;
  text-align: center;
  padding: 0 2vw;
}
.InfluencerProfile__SharingBtn {
  width: 10vw;
  height: 8vw;
  padding-top: 0.5vw;
}
.InfluencerProfile__BackFromShare {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
}
.InfluencerProfile__Metrics {
  width: 92vw;
  text-align: left;
  font-size: 3.5vw;
  line-height: 7vw;
  margin: 9vw 0 7vw 8vw;
}
.InfluencerProfile__Metrics span {
  color: #ffbf12;
  padding-right: 2vw;
}
.InfluencerProfile__Metrics img {
  position: relative;
  left: -2vw;
  width: 3.5vw;
}

.InfluencerProfile__RemainingStocks {
  font-size: 5vw;
  margin: 5vw 8vw;
  width: 84vw;
  line-height: 5vw;
}
.InfluencerProfile__RemainingStocks span {
  color: #ffbf12;
}

.InfluencerProfile__AgentTagSizePos {
  width: 84vw;
  height: 13vw;
  margin: 5vw 8vw;
}
.InfluencerProfile__Description {
  width: 84vw;
  text-align: left;
  margin: 9vw 8vw;
  font-size: 4vw;
  line-height: 6vw;
}
.InfluencerProfile__StatsPrdctContainer {
  position: relative;
  width: 100vw;
  margin: 0 0vw;
}
.InfluencerProfile__PrdctStatsBtns {
  width: 84vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 8vw;
}
.InfluencerProfile__StatsBtn {
  width: fit-content;
  font-size: 5vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
.InfluencerProfile__ProductsBtn {
  width: fit-content;
  font-size: 5vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
.InfluencerProfile__BasicLine {
  display: block;
  width: 84vw;
  margin: 3vw 8vw;
}
.InfluencerProfile__StatsSwitchLine {
  position: absolute;
  top: 8.8vw;
  left: 11vw;
  transition: right 0.5s;
}
.InfluencerProfile__PrdctSwitchLine {
  position: absolute;
  top: 8.8vw;
  right: 15vw;
  transition: left 0.5s;
}
.InfluencerProfile__StockInformation {
  position: relative;
  width: 100%;
  margin-top: 10vw;
}
.InfluencerProfile__GraphPos,
.InfluencerProfile__SocialMediaData {
  position: relative;
  width: 100%;
  height: 100vw;
}
.InfluencerProfile__SocialMediaData{
  margin: 18vw 8vw;
}
/* .InfluencerProfile__UpdateDeliveredStoryMentionPos{
  
} */
.InfluencerProfile__ProductsInformation {
  position: relative;
  width: 100%;
  /* height: 150vw; */
  margin-top: 10vw;
}
.InfluencerProfile__PrdctPendingList {
  position: relative;
  width: 90vw;
  padding: 0 0 30vw 0;
  margin: 0 auto;
}
