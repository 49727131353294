#ConfirmTxtV{
    position: absolute;
    top: 72vw;
    left: 8%;
    margin: 0;
    font-size: 4.2vw;
    line-height: 6vw;
}
#EmailInputV {
    position: absolute;
    top: 82vw;
    left: 8%;
    width: 80%;
    padding: 0 2vw;
    margin: 0;
    font-size: 4vw;
    line-height: 9vw;
    color: black;
    border-radius: 10px;
    border: none;
}
#EmailInputV::placeholder, #PhoneNInputV::placeholder{
    font-size: 4vw;
    color: #363636;
}
#PhoneNInputV{
    position: absolute;
    top: 94vw;
    left: 8%;
    width: 80%;
    padding: 0 2vw;
    margin: 0;
    font-size: 4vw;
    line-height: 9vw;
    color: black;
    border-radius: 10px;
    border: none;
}
#LineSMIV{
    position: absolute;
    top: 113vw;
    left: 8%;
}
#WaitingVExp{
    position: absolute;
    top: 117vw;
    left: 8%;
    margin: 0;
    font-size: 4.2vw;
    line-height: 6vw;
}
#LogSignSmilyV {
    position: absolute;
    top: 146.5vw;
    left: 24%;
    width: 8%;
}
#DoneBtnValid{
    position: absolute;
    top: 163vw;
    left: 31%;
    font-size: 4.5vw;
    margin: 0;
    padding: 0 14vw;
    line-height: 9vw;
    background-color: #ffbf12;
    border-radius: 19px;
    z-index: 888;
}
