.StarsRanking {
    width: 50vw;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.StarsRanking__Star{
    display: inline;
    width: 17%;
    padding: 0.5vw;
}