.WalletPorfolioNavBar__ManagerBtnsContainer {
  display: flex;
  justify-content: space-between;
  margin: 2vw;
}
.WalletPorfolioNavBar__ManagerMiddleBtnsContainer {
  display: flex;
  justify-content: center;
}
.WalletPorfolioNavBar__IssuerBtnsContainer {
  display: flex;
  justify-content: center;
  margin: 2vw;
}
.WalletPorfolioNavBar__IconBtn {
  display: block;
  color: #121212;
  height: 13vw;
  margin: 3vw;
}
