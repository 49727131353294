.StocksCategorySwitcher {
  width: 100vw;
  margin: 7vw 0vw 0;
  padding: 0 3vw;
  font-size: 3.35vw;
  display: flex;
  justify-content: space-between;
}
.StocksCategorySwitcher span {
  position: relative;
  display: inline-block;
}
.StocksCategorySwitcher span:nth-child(1) {
  top: 0vw;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 2vw;
  line-height: 7vw;
}
.StocksCategorySwitcher span:nth-child(2) {
  /* position: absolute; */
  top: 0vw;
  /* left: 33%; */
  width: fit-content;
  padding: 0 2vw;
  line-height: 7vw;
}
.StocksCategorySwitcher span:nth-child(3) {
  /* position: absolute; */
  top: 0vw;
  /* left: 5%; */
  width: fit-content;
  padding: 0 2vw;
  line-height: 7vw;
}
.StocksCategorySwitcher span:nth-child(4) {
  /* position: absolute; */
  top: 0vw;
  /* left: 75%; */
  width: fit-content;
  padding: 0 2vw;
  line-height: 7vw;
}
.StocksCategorySwitcher__SelectedCategory {
  border: 2px solid #ffbf12;
  border-radius: 20px;
}
