.BrandUnitsRange {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: lighter;
  margin: 2vw 0;
}
.BrandUnitsRange__Block {
  display: flex;
  margin: 0;
  font-weight: lighter;
}
