.AuthBtns {
    position: relative;
    box-shadow: 0px -8vw 10vw 1vw #121212;
    z-index: 15;
    top: -15vw;
    background: #121212;
}
.AuthBtns__SignUpBtn {
    font-size: 1.1em;
    margin: 0 0 0.4em;
    color: #ffbf12;
    text-align: center;
}
.AuthBtns__LoginTextAndBtn {
    font-size: 0.6em;
    font-weight: normal;
    margin: 0 0 3em;
    text-align: center;
}
.AuthBtns__LoginTextAndBtn span {
  color: #ffbf12;
  font-weight:bold;

}
