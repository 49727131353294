.MyBrandManagement__BtnsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.MyBrandManagement__BtnsContainer p {
  font-size: 16px;
  line-height: 30px;
  font-weight: lighter;
  width: 100%;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background: 0;
  text-align: left;
  margin-top: -1px;
  padding-left: 8px;
}
