.navbar {
    width: 100%;
    /* padding: 15vw; */
    position: fixed;
    top: 15vw;
    transition: top 0.6s;
  }
  
  .navbar--hidden {
    top: 0;
  }

#AgencyViewStatsDiv{
    position: fixed;
    /* top: 15vw; */
    left: 0%;
    width: 100%;
    height: 10vw;
    z-index: 51;
   
}
#AgencyViewStatsDivUpper{
    position: fixed;
    top: 0vw;
    left: 0%;
    width: 100%;
    height: 10vw;
    z-index: 51;
   
}
#HideAgencyStats{
    display: none;
}
#BackPageAV {
    position: absolute;
    top: 2vw;
    left: 0%;
    width: 13vw;
    z-index: 55;
}
#AgencyViewDivYYY {
    position: absolute;
    top: 0vw;
    left: 3.5%;
    width: 70%;
    height: 12vw;
    background-color: #FFBF12;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 0.1px solid gray;
    z-index: 50;
    padding: 0 11vw;
}
#AgencyVTxtYYY{
    position: absolute;
    top:4vw;
    left:11%;
    font-size: 3.5vw;
    line-height: 5vw;
    margin: 0;
}
#SMINameAVTxtYYY{
    position: absolute;
    top:4vw;
    right:5%;
    font-size: 3.5vw;
    line-height: 5vw;
    margin: 0;
}