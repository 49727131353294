#HowDoesIWorkDiv{
    position: absolute;
    top: 15vw;
    left: 0;
    width: 100%;
    height: 1023vw;
    scroll-behavior: smooth;
}

#OneBtnHIW{
    position: fixed;
    bottom: 34vw;
    right: 2vw;
    padding: 3.55vw 3.3vw;
    background: none;
    border: 1.5px solid #ffbf12;
    border-radius: 80px;
    font-size: 3.5vw;
    line-height: 1.5vw;
    margin: 0;
    z-index: 20;
}
#TwoBtnHIW{
    position: fixed;
    bottom: 24vw;
    right: 2vw;
    padding: 3vw;
    background: none;
    border: 1.5px solid #ffbf12;
    border-radius: 80px;
    font-size: 3.5vw;
    line-height: 1.5vw;
    margin: 0;
    z-index: 20;
}
#ThreeBtnHIW{
    position: fixed;
    bottom: 14vw;
    right: 2vw;
    padding: 3vw;
    background: none;
    border: 1.5px solid #ffbf12;
    border-radius: 80px;
    font-size: 3.5vw;
    line-height: 1.5vw;
    margin: 0;
    z-index: 20;
}
#ArrowUpBtnHIW{
    position: fixed;
    bottom: 4vw;
    right: 2vw;
    width: 8.5vw;
    z-index: 3;
}

#HelloHowItWork{
    position: absolute;
    top: 11.5vw;
    left: 37.5%;
    width: 25vw;
}
#WelcomeTxtHIW{
    position: absolute;
    top: 43vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 7vw;
    line-height: 8.5vw;
    margin: 0;
}

#LetsTxtHIW{
    position: absolute;
    top: 81vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 3vw;
    line-height: 4vw;
    font-weight: normal;
    margin: 0;
}
#LetsTxtHIW span{
    font-weight:700;
}

#ArrowScrollHIW1{
    position: absolute;
    top: 139vw;
    left: 45%;
    width: 7%;
}
#ScrollDownTxtHIW{
    position: absolute;
    top: 151vw;
    left:0%;
    width: 100%;
    text-align: center;
    font-size: 4vw;
    line-height: 6vw;
    margin: 0;
    color: #ffbf12;
}

#OneHIW{
    position: absolute;
    top: 177vw;
    left: 40%;
    background-color: #ffbf12;
    border-radius: 60px;
    font-size: 12vw;
    line-height: 4vw;
    margin: 0;
    padding: 6vw;
}
#GetYouTxtHIW{
    position: absolute;
    top: 201vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 7vw;
    line-height: 8.5vw;
    margin: 0;
}

#SmilyMoneyHIW {
    position: absolute;
    top: 239vw;
    left: 37%;
    width: 26%;
}
#DepositTxtHIW{
    position: absolute;
    top: 270vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 4vw;
    line-height: 5vw;
    margin: 0;
}

#ArrowScrollHIW2{
    position: absolute;
    top: 308vw;
    left: 45%;
    width: 7%;
}

#TwoHIW{
    position: absolute;
    top: 342vw;
    left: 40%;
    background-color: #ffbf12;
    border-radius: 60px;
    font-size: 12vw;
    line-height: 4vw;
    margin: 0;
    padding: 6vw 5vw;
}
#PurchaseTxtHIW{
    position: absolute;
    top: 367vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 7vw;
    line-height: 8.5vw;
    margin: 0;
}

#SimleyGlasses {
    position: absolute;
    top: 418vw;
    left: 35%;
    width: 28%;
}
#HeadTxtHIW{
    position: absolute;
    top: 453vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 4vw;
    line-height: 5vw;
    margin: 0;
}

#WhatExpectHLHIW{
    position: absolute;
    top: 492vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 5vw;
    line-height: 5vw;
    margin: 0;
    color: #ffbf12;
}
#ExpectExpHIW{
    position: absolute;
    top: 507vw;
    left: 12%;
    width: 76%;
    text-align: left;
    font-size: 3.5vw;
    line-height: 4vw;
    font-weight: normal;
    margin: 0;
}
#ExpectExpHIW i{
    font-style: normal;
    font-weight: bolder;
    color: #ffbf12;
}
#ExpectExpHIW span{
    color: #34c100;
}
#ExpectExpHIW k{
    color: #e66100;
}
#CelebrateSmileyHIW {
    position: absolute;
    top: 607vw;
    left: 35%;
    width: 28%;
}
#CongHdlnHIW{
    position: absolute;
    top: 642vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 5vw;
    line-height: 5vw;
    margin: 0;
    color: #ffbf12;
}
#YouNowTxtHIW{
    position: absolute;
    top: 655vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 3.5vw;
    line-height: 4vw;
    margin: 0;
}

#ArrowScrollHIW3{
    position: absolute;
    top: 696vw;
    left: 45%;
    width: 7%;
}

#ThreeHIW{
    position: absolute;
    top: 725vw;
    left: 40%;
    background-color: #ffbf12;
    border-radius: 60px;
    font-size: 12vw;
    line-height: 4vw;
    margin: 0;
    padding: 6vw 5vw;
}
#TradeForTxtHIW{
    position: absolute;
    top: 750vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 7vw;
    line-height: 7vw;
    margin: 0;
}
#TradeHIW {
    position: absolute;
    top: 796vw;
    left: 32%;
    width: 35%;
}

#WhatDoWithPSHIW{
    position: absolute;
    top: 836vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 5vw;
    line-height: 5vw;
    margin: 0;
}

#TradeItPassionCoinBtnHIW{
    position: absolute;
    top: 869vw;
    left: 7%;
    width: 40%;
    text-align: center;
    font-size: 4vw;
    line-height: 4.5vw;
    margin: 0;
    color: #ffbf12;
}
#PCSubLine{
    position: absolute;
    top: 881vw;
    left: 7%;
}
#TradeItPassionProduct{
    position: absolute;
    top: 869vw;
    left: 48%;
    width: 40%;
    text-align: center;
    font-size: 4vw;
    line-height: 4.5vw;
    margin: 0;
    color: #ffbf12;
}
#PPSubLine{
    position: absolute;
    top: 881vw;
    left: 48%;
}

#TradePassionCoinForm{
    position: absolute;
    top: 885vw;
    left:0%;
    width: 100%;
    height: 80vw;
}
#TradeGetHdlnPCHIW{
    position: absolute;
    top: 27vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 6vw;
    line-height: 6vw;
    margin: 0;
}
#TradeGetHdlnPCHIW span{
    color: #ffbf12;
}
#SimleyEyeStarsHIW {
    position: absolute;
    top: 54vw;
    left: 35%;
    width: 30%;
}
#MakeTxtPCHIW {
    position: absolute;
    top: 98vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 3.5vw;
    font-weight: normal;
    line-height: 4vw;
    margin: 0;
    padding-bottom: 13vh;
}

#Hide{
    display: none;
}

#TradePassionProductForm{
    position: absolute;
    top: 885vw;
    left: 0%;
    width: 100%;
    height: 80vw;
}
#TradeGetHdlnPPHIW{
    position: absolute;
    top: 27vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 6vw;
    line-height: 6vw;
    margin: 0;
}
#TradeGetHdlnPPHIW span{
    color: #ffbf12;
}
#SimleyHurtHIW {
    position: absolute;
    top: 53vw;
    left: 35%;
    width: 30%;
}
#InYourWalletTxtPCHIW {
    position: absolute;
    top: 98vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 3.5vw;
    font-weight: normal;
    line-height: 4vw;
    margin: 0;
    padding-bottom: 13vh;
}


