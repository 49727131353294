.ShowTopMenu {
  display: block;
  background-color: #1f1f1f;
  position: fixed;
  top: 0%;
  left: 0;
  z-index: 200;
  width: 100vw;
  height: 15vw;
}
.DontShow {
  display: none;
}
#TopLogo {
  position: absolute;
  top: 3.5vw;
  left: 3%;
  width: 44%;
}
#Menu {
  position: absolute;
  top: 5.25vw;
  left: 90%;
  width: 6%;
}
#NotificationPS {
  position: absolute;
  top: 4.75vw;
  left: 82%;
  width: 4.5%;
}
.count {
  position: absolute;
  top: 4vw;
  left: 80.5vw;
  width: 3vw;
  padding: 0 0.2%;
  border-radius: 50%;
  background-color: #ffbf12;
  font-size: 1.8vw;
  text-align: center;
  line-height: 3.35vw;
}
