.Exchange__SearchSMI {
  margin: 37vw 12.5vw 0;
  padding: 0 10vw 0 5vw;
  width: 75vw;
  border: 0;
  border-radius: 20vw;
  color: black;
  font-size: 3.5vw;
  line-height: 6.5vw;
  font-weight: normal;
  text-align: center;
  background-repeat: no-repeat;
  background-position: 68vw 1.35vw;
  background-size: 4.5vw 4.5vw;
}
.ExchangeSearch__Loader {
  position: relative;
  margin: 0 auto;
  width: 8vw;
  height: 8vw;
}
.ExchangeSearch__ResultsFeed {
  margin: 7vw 0 50vw;
}
.ExchangeSearch__UnableTxt {
  font-size: 4.5vw;
  margin: 0;
}
