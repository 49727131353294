/* #ResetPasswordDiv{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
} */
.ResetPassword__Emoji {
  display: block;
  width: 18vw;
  margin: 25vw auto 19vw;
}
.ResetPassword__ResetTxt {
  width: 100%;
  text-align: center;
  font-size: 6.35vw;
  line-height: 8vw;
  margin: 0 0 2vw;
}
.ResetPassword__EmailAddressTxt {
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 5.35vw;
  line-height: 8vw;
  margin: 0 0 17vw;
  color: #ffbf12;
}

.ResetPassword__PasswordInput {
  display: inline;
  width: 84vw;
  height: 10vw;
  /* border: none;
  border-radius: 25px;
  padding: 0 4vw;
  line-height: 10.5vw;
  font-size: 4.5vw;
  color: black; */
}
.ResetPassword__EyeImgPassword {
  position: absolute;
  top: 7vw;
  right: 3.5vw;
  width: 6vw;
}
/* #EnterEmailFP::placeholder {
  font-size: 4.5vw;
  color: #202020;
  font-family: sans-serif;
} */
.ResetPassword__ConfirmPasswordInput {
  display: inline;
  width: 84vw;
  height: 10vw;
  /* border: none;
  border-radius: 25px;
  padding: 0 4vw;
  line-height: 10.5vw;
  font-size: 4.5vw;
  color: black; */
}
/* #EnterConfirmRP::placeholder {
  font-size: 4.5vw;
  color: #202020;
  font-family: sans-serif;
} */
/* .input-wrong {
  border: 2px solid red !important;
} */

/* #LoaderPosRP {
  position: absolute;
  top: 128vw;
  left: 47.5vw;
  width: 5vw;
  height: 5vw;
} */

/* #UpdateBtnRP {
  position: absolute;
  bottom: 10vw;
  left: 9vw;
  width: 82vw;
  line-height: 10vw;
  font-size: 4.3vw;
  text-align: center;
  color: #ffbf12;
  border-radius: 25px;
  background-color: white;
  margin: 0;
} */

#MessageRP {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #202020;
  width: 100%;
  padding: 9vw 0px;
}
#MessageTextRP {
  position: relative;
  font-size: 5vw;
  line-height: 7vw;
  width: 100%;
  text-align: center;
  margin: 4vw 0;
}
#YesBtnRP {
  position: relative;
  font-size: 5vw;
  line-height: 7vw;
  background-color: #ffbf12;
  border-radius: 22px;
  padding: 1vw 4vw;
  margin: 0 0 0 32vw;
}
#NoBtnRP {
  position: relative;
  display: inline;
  padding: 0vw 3vw;
  font-size: 5vw;
  line-height: 7vw;
  border: 1vw solid white;
  border-radius: 20px;
  margin: 0 4vw;
}

#ErrorMessageRP {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #202020;
  width: 100%;
}
#ErrorMessageTextRP {
  position: relative;
  font-size: 5vw;
  line-height: 7vw;
  width: 100%;
  text-align: center;
  margin: 0;
}
