#PSVSSMDiv{
    position: absolute;
    top:15vw;
    left:0;
    width: 100%;
    /* height: 100vh; */
}
#PSVSSMTitle {
    position: absolute;
    top: 8vw;
    left: 15vw;
    font-size: 4.1vw;
    line-height: 5vw;
    margin: 0;
}
#PSVSSMBckgPic {
    position: absolute;
    top: 32vw;
    left: 0;
    width: 100%;
}
#WhereTitlePSVSSM {
    position: absolute;
    top: 18vw;
    left: 10vw;
    font-size: 14vw;
    line-height: 14vw;
    margin: 0;
    text-shadow: 0 0 4px #24beff, 0 0 10px #24beff;
}
#StockMarketTxtPSVSSM {
    position: absolute;
    width: 34%;
    height: auto;
    top: 109vw;
    left: 58%;
    z-index: 2;
    font-size: 5vw;
    line-height: 5.5vw;
    text-align: left;
    margin: 0;
    text-transform:none !important;
}
#VSPicPSVSSM {
    position: absolute;
    width: 10%;
    height: auto;
    top: 111vw;
    left: 44%;
    z-index: 2;
}
#ShortLogoPSVSSM {
    position: absolute;
    width: 35%;
    height: auto;
    top: 108vw;
    left: 6%;
    z-index: 4;
}
#VSSliderPosPSVSSM {
    position: absolute;
    top: 125vw;
    left: 0vw;
    width: 100%;
}