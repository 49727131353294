
#DepositSuccesedDiv{
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100vh;
}
#DepositSTxt{
    position: absolute;
    top: 45vw;
    left: 8%;
    font-size: 10vw;
    line-height: 11vw;
    margin: 0;
}
#HappyTTxt{
    position: absolute;
    top: 99vw;
    left: 8%;
    font-size: 8vw;
    line-height: 10vw;
    color: #707070;
    margin: 0;
}
#LineDepositSuccesed{
    position: absolute;
    top: 133vw;
    left:34%;
}
#DoneDS{
    position: absolute;
    top: 149vw;
    left: 44%;
    width: 14vw;
}