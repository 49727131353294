.SelectCurrency__WhatTxt {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 10vw;
    margin: 0 auto;
    padding-bottom:3vw;
    text-shadow: 0.35vw 0.35vw 0px black, 0.5vw 0.5vw 0 white;
}
.SelectCurrency__SelectTxt {
    font-size: 4.25vw;
    font-weight: lighter;
    line-height: 6vw;
    margin: 5vh 0 0;
    padding: 0 8vw 5vw;
}
.select-currency {
  position: relative;
  left: 8vw;
}
.select-currency > * {
  font-family: sans-serif !important;
}
.SelectCurrency__DoneBtn{
    width: 84vw;
    margin: 0 auto 3vh;
}
