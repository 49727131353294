.Report__HelpTitle{
    position: absolute;
    top: 16.5vw;
    left: 15vw;
    font-size: 4.5vw;
}
.Report__BugPic{
    position: absolute;
    top: 0vw;
    left: 0;
    width: 7vw;
}
.Report__ReportTxt{
    position: absolute;
    top: 0vw;
    left: 11vw;
    font-size: 4.5vw;
    margin: 0;
}
.Report__ReportExp{
    font-size: 4vw;
    line-height: 5vw;
    font-weight: normal;
}
.Report__SelectServicesFilter {
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 3vw;
    font-size: 4vw;
    height: 7vw;
    color: white;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-weight: normal;
    background: black;
    margin: 8vw 0 4vw;
}
.Report__Input{
    width: 100%;
    height: 49vw;
    font-weight: bold;
    color: black;
    font-size: 4vw;
    line-height: 6vw;
    padding: 2.5vw 3.5vw;
    border-radius: 3vw;
    background: #FFFFFF;
    border: none;
}
.Report__Input::placeholder{
    font-size: 4vw;
}
.Report__SendBtn {
    width: fit-content;
    padding: 0vw 9vw;
    padding-right: 19vw;
    background-color: #902366;
    border-radius: 30px;
    font-size: 4vw;
    line-height: 10vw;
    margin: 0;
}
.Report__SendArrow{
    position: absolute;
    top: 3.25vw;
    right: 5vw;
    width: 2.5vw;
}
#LoaderPosSizeRAB {
    position: absolute;
    top: 147vw;
    left: 49%;
    width: 4vw;
    height: 4vw;
}