.NavBottom {
    position: fixed;
    display: flex;
    width: 100vw;
    height: 19vw;
    left: 0;
    bottom: 0vw;
    background-color: #1F1F1F;
    z-index: 900;
    justify-content: space-around;
}


.NavBottom__Btn{
    width: 20vw;
    margin: auto 0;
    cursor: pointer;

}
.NavBottom__BtnIcon{
    display: block;
    width: auto;
    height: 6vw;
    margin: 0 auto 1vw;
    cursor: pointer;

}
.NavBottom__BtnTxt{
    margin: 0 auto;
    width: fit-content;
    font-size: 3vw;
    line-height: 4vw;
}
/* #Home{
    position: absolute;
    width: 7%;
    left: 8.5%;
    top: 3.5vw;
} */

/* #HomeTxt {
    position: absolute;
    left: 7.5%;
    top: 12vw;
    font-size: 3vw;
    margin: 0;
    line-height: 3vw;
} */

#Wallet{
    position: absolute;
    width: 7%;
    left: 25%;
    top: 4vw;
}
#WalletTxt {
    position: absolute;
    left: 23.7%;
    top: 12vw;
    font-size: 3vw;
    margin: 0;
    line-height: 3vw;
}
#Exchange{
    position: absolute;
    width: 12%;
    left: 42.5%;
    top: 4vw;
}
#ExchangeTxt {
    position: absolute;
    left: 41.3%;
    top: 12vw;
    font-size: 3vw;
    margin: 0;
    line-height: 3vw;
}
#Rewards{
    position: absolute;
    width: 7%;
    left: 65%;
    top: 4.25vw;
}
#RewardsTxt{
    position: absolute;
    left: 62%;
    top: 12vw;
    font-size: 3vw;
    margin: 0;
    line-height: 3vw;
}
#Profile{
    position: absolute;
    width: 6%;
    left: 84%;
    top: 22%;
}
#ProfileTxt{
    position: absolute;
    left: 82%;
    top: 12vw;
    font-size: 3vw;
    margin: 0;
    line-height: 3vw;
}