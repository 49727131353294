.DeleteAccount__Btn {
  font-size: 15px;
  line-height: 34px;

  color: #121212;
width: 190;  border-radius: 10px;
  margin: 0 auto;
  position: relative;
  display: block;
}
