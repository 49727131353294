.Exchange {
  position: absolute;
  overflow-y: scroll;
  height: 110vh;
  width: 100%;
  top: 0;
  left: 0;
  transition: left 0.8s;
  padding-top:19vw;
}
/* #ExchangeDiv::-webkit-scrollbar {
    display: none;
} */
/* Hide scrollbar for IE and Edge */
/* #ExchangeDiv {
    -ms-overflow-style: none;
} */

/* *::-webkit-scrollbar {
    display: none;
} */
/* Hide scrollbar for IE and Edge */
/* * {
    -ms-overflow-style: none;
} */
.navbare {
  width: 100%;
  /* padding: 15vw; */
  position: fixed;
  top: 15vw;
  transition: top 2s;
}

.navbar--hiddene {
  top: -40vw !important;
}
.navbareAV {
  width: 100%;
  /* padding: 15vw; */
  position: fixed;
  top: 27vw;
  transition: top 2s;
}
.navbarb {
  width: 100%;
  /* padding: 15vw; */
  position: fixed;
  top: 21vw;
  transition: top 2s;
  margin: 0;
}

.navbar--hiddenb {
  top: -40vw !important;
}
.navbarbAV {
  width: 100%;
  /* padding: 15vw; */
  position: fixed;
  top: 32vw;
  transition: top 2s;
  margin: 0;
}
#BalanceEllipse {
  position: fixed;
  /* top: 1vw;
    left:0vw; */
  font-size: 10vw;
  width: 100%;
  text-align: center;
  z-index: 23;
}
#EllipseBalance {
  position: fixed;
  /* top: 14vw;
    left:0vw; */
  z-index: 22;
}
#InfoIcon {
  position: absolute;
  top: 52.5vw;
  left: 12%;
  width: 6%;
}

#LoaderPosSizeSSMI {
  position: absolute;
  top: 112.75vw;
  left: 48%;
  width: 4vw;
  height: 4vw;
  z-index: 999;
}
#LoaderPosSizeSalesEx {
  position: absolute;
  top: 8.75vw;
  left: 48%;
  width: 7vw;
  height: 7vw;
  z-index: 999;
}




/* Chart */
.apexcharts-active {
  color: #121212;
}

#ChartTrade {
  position: relative;
 height: 22vw;
  width: 100%;
}
#ChartTrade > p {
  position: absolute;
  top: 1vw;
  font-size: 7vw;
  left: 8%;
  z-index: 2;
}
#Hide {
  display: none;
}
.SwitchDescribeTitle {
  position: absolute;
  top: 18vw;
  left: 13vw;
}
#HighLine {
  position: absolute;
  top: 18vw;
  left: 13vw;
}
#LowLine {
  position: absolute;
  top: 18vw;
  left: 32vw;
}
#Chart {
  width: 90vw;
}

#HideNF {
  display: none;
}
#SearchResultsDiv {
  position: absolute;
  /*  */
  /* display: none; */
  /*  */
  top: 70vw;
  left: 0%;
  width: 100%;
  overflow-y: scroll;
}


#NotFoundDiv {
  position: relative;
  width: 100%;
  height: 77vh;
  margin: 0 0 15vw 0;
}
#NotFoundSmiley {
  position: absolute;
  top: 5vw;
  left: 42.5%;
  width: 17vw;
}
#NotFoundTxtNF {
  position: absolute;
  top: 28vw;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 7vw;
  line-height: 7vw;
  margin: 0;
}
#TheyAreTxtNF {
  position: absolute;
  top: 44vw;
  left: 10vw;
  width: 80%;
  text-align: center;
  font-size: 4vw;
  line-height: 6vw;
  margin: 0;
}
#SMIInstagramHandleNF {
  position: absolute;
  top: 69vw;
  left: 10%;
  width: 80%;
  text-align: center;
  line-height: 9vw;
  font-size: 5vw;
  border-radius: 20px;
  border: none;
}
#SMIInstagramHandleNF::placeholder {
  font-size: 5vw;
  color: #121212;
}
#SMIInstagramInputNF {
  position: absolute;
  top: 69.8vw;
  left: 12%;
  width: 8vw;
}
#DoneSMIInsHandle {
  position: absolute;
  top: 95vw;
  left: 43%;
  width: 14vw;
}
#AskForSMIOutPlatformMsg {
  position: fixed;
  width: 100%;
  bottom: 19vw;
  background: #363636;
  font-size: 6vw;
  text-align: center;
  padding: 13vw 0;
  line-height: 8vw;
  color: #ffbf12;
  margin: 0;
}
#SearchDemoTxt {
  position: absolute;
  top: 30vw;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 6.8vw;
  margin: 0;
  line-height: 9vw;
}
#SignUpSDemoBtn {
  position: absolute;
  top: 55vw;
  left: 29vw;
  font-size: 5vw;
  line-height: 9vw;
  padding: 0 12vw;
  margin: 0;
  color: #ffbf12;
  background-color: white;
  border-radius: 25px;
}
#SignInSDemoBtn {
  position: absolute;
  top: 69vw;
  left: 29vw;
  font-size: 5vw;
  line-height: 9vw;
  padding: 0 12.7vw;
  margin: 0;
  color: #ffbf12;
  border: 1px solid white;
  border-radius: 25px;
}

#StockPriceBoard {
  position: absolute;
  top: 173vw;
  left: 0%;
  width: 100%;
  height: 90vh;
  /* overflow-y: scroll; */
}
/* #StockPriceBoard::-webkit-scrollbar {
    display: none; */
/* } */
/* Hide scrollbar for IE and Edge */
/* #StockPriceBoard {
    -ms-overflow-style: none;
} */
.StockCardCommentE {
  position: relative;
  /* height: 82vw; */
  margin-bottom: 12vw;
}

#ExtraHeight {
  height: 20vw;
}


.Exchange__TradeBtn {
  position: fixed;
  bottom: 25vw;
  right: 0%;
  line-height: 12vw;
  padding: 0 5vw 0 6vw;
  font-size: 5vw;
  background-color: #ffbf12;
  border-top-left-radius: 20vw;
  border-bottom-left-radius: 20vw;
  margin: 0;
  z-index: 20;
}


#ErrorServerEDiv {
  position: absolute;
  top: 164vw;
  left: 0%;
  width: 100%;
  height: 54vw;
}
#ErrorServerEDiv p {
  position: absolute;
  top: 0vw;
  left: 5%;
  width: 95%;
  font-size: 4.2vw;
  line-height: 6vw;
  margin: 0;
}

#PageLoaderExchange {
  position: relative;
  width: 10vw;
  height: 10vw;
  margin: auto;
}
