.animate-to-left {
  left: 100vw !important;
  top: 0 !important;
  margin-left: 100vw;
}

.animate-to-right {
  right: 100vw !important;
  top: 0 !important;
  margin-right: 100vw !important;
}

.SignUpDemoForm {
  /* position: relative; */
  display: flex;
  width: 100vw;
  /* max-height: max-content; */
  transition: right 0.5s;
  padding: 0 8vw;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.SignUpForm__HiTxt {
  margin: 2vw auto 5vw auto;
  font-size: 6.8vw;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}

.Login__Emoji {
  position: relative;
  top: 4vw;
  width: 17vw;
  padding-left: 3vw;
}
.SignUpDemoForm__BtnsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 39vw;
  align-items: center;
}
.SignUpDemoForm__SampleAcoountBtn {
  width: 83vw;
  font-size: 5vw;
  line-height: 0;
  height: 10vw;
  border: 0;
  border-radius: 30px;
  cursor: pointer;
}

.SignUpForm__indicate {
  min-height: 12vw;
  padding-top: 5vw;
}
.SignUpForm__LoaderPos {
  margin: 0 auto;
  width: 5vw;
  height: 5vw;
}
.SignUpForm__ErrorMassage {
  width: 100%;
  font-size: 4vw;
  margin: 0;
  line-height: 4vw;
  text-align: center;
  color: red;
  font-weight: normal;
}
.SignUpForm__SignUpButton {
  /* position: absolute;
    top: 102vw;
    left: 10%;
    width: 80%; */
  color: #ffbf12;
  text-align: center;
  font-size: 4vw;
  line-height: 10vw;
  background-color: white;
  border-radius: 25px;
}

#SignUpByFacebbok {
  position: absolute;
  top: 113vw;
  left: 13%;
  width: 37%;
  font-size: 4vw;
  text-align: center;
  height: 9vw;
  background-color: #4167b2;
  border-radius: 11px;
}
