.SaleOfferCard {
    position: relative;
    left: 5%;
    width: 90%;
    height: 32vw;
}
/* .SaleOfferCard *{
    margin: 0;
} */
.StockCardSOC{
    position: relative;
    height: 31vw;
    background-color: #1F1F1F;
    margin-bottom: 5vw;
    box-shadow: 0px 14px 9px -6px #121212;
    z-index: 5;
}
.SMIPicBigSOC {
    position: absolute;
    left: 6%;
    top: 13%;
    width: 21vw;
    height: 21vw;
    border: 4.5px solid #24beff;
    background-position: 0px 0px;
    background-size: 19vw 19vw;
    border-radius: 157vw;
}
.SMIPicSmallSOC {
    position: absolute;
    left: 13.5%;
    top: 26%;
    width: 15vw;
    height: 15vw;
    /* border: 4px solid #24beff; */
    border-radius: 50%;
    background-position: 0px 0px;
    background-size: 15vw 13vw;
    z-index: 2;
}
.blueRC{
    border: 4px solid #24beff !important;
}
.yellowRC{
    border: 4px solid #ffbf12 !important;
}
.greenRC{
    border: 4px solid #34c100 !important;
}
.purpleRC{
    border: 4px solid #902366 !important;
}
.whiteRC{
    border: 4px solid #ffffff !important;
}
.SMIMokePicBigSOC {
    position: absolute;
    left: 6%;
    top: 13%;
    width: 24vw;
    height: 74%;
    /* border: 4.5px solid #ffbf12; */
    background-position: 0px 0px;
    background-size: 23vw auto;
    border-radius: 50%;
}
.SMIMokePicSmallSOC {
    position: absolute;
    left: 13.5%;
    top: 21%;
    width: 18vw;
    height: 54%;
    /* border: 4px solid #ffbf12; */
    border-radius: 50%;
    background-position: 0px 0px;
    background-size: 17vw auto;
    z-index: 2;
}
.InvPicSOC{
    position: absolute;
    left: 2%;
    top: 16%;
    width: 21vw;
    height: 67%;
    border-radius: 50%;
    background-position: 0px 0px;
    background-size: 21vw auto;
    z-index: 1;
}
.SMIRankingSOC {
    position: absolute;
    top: 12.5vw;
    left: 22.8vw;
    background-color: #24beff;
    border-radius: 50px;
    font-size: 3.5vw;
    line-height: 6vw;
    padding: 0 2vw;
    margin: 0;
    box-shadow: 0px 4px 19px 0px #121212;
    z-index: 4;
}
.SMIIPORankingSOC {
    position: absolute;
    top: 42.7%;
    left: 26.8%;
    background-color: #24beff;
    border-radius: 50px;
    font-size: 3vw;
    line-height: 5.3vw;
    padding: 0 1.8vw;
    margin: 0;
    box-shadow: 0px 8px 20px 2px #121212;
    z-index: 4;
}
.blueRN{
    background-color: #24beff !important;
}
.yellowRN{
    background-color: #ffbf12 !important;
}
.greenRN{
    background-color: #34c100 !important;
}
.purpleRN{
    background-color: #902366 !important;
}
.whiteRN{
    background-color: #ffffff !important;
    color: #121212 !important;
}
.SMIMokeRankingSOC {
    position: absolute;
    top: 12vw;
    left: 24.8vw;
    background-color: #ffbf12;
    border-radius: 50px;
    font-size: 4vw;
    line-height: 7vw;
    padding: 0 2.3vw;
    margin: 0;
    box-shadow: 0px 8px 20px 2px #121212;
    z-index: 4;
}
.SMINameSOC{
    position: absolute;
    top: 20%;
    left: 37%;
    margin: 0;
    font-size: 4vw;
    line-height: 4vw;
}
.StocksForSaleSOC{
    position: absolute;
    top: 29%;
    left: 37%;
    font-size: 4vw;
}
.TextYellow{
    color: #FFBF12;
    font-weight: bold;
}
.StockPreviousPriceSOC{
    position: absolute;
    top: 56%;
    left: 37%;
    font-size: 3vw;
}
.CurrentPriceArrow {
    position: relative;
    top: 0.1vw;
    left: 4%;
    width: 2.7vw;
}
.SMILocationSOC{
    position: absolute;
    top: 56%;
    left: 53%;
    font-size: 3vw;
    width: 20%;
    text-align: right;
}
.LocationBckg{
    background-color: #353535;
}
.StockStatusSOC{
    position: absolute;
    top: -12%;
    left: 79%;
    font-size: 4vw;
    width: 21%;
    text-align: right;
}
.IPOBckg{
    padding: 2%;
    background-color: #FFBF12;
    border-radius: 1vw;
}
.NewBckg{
    padding: 2%;
    background-color: #DE5523;
    border-radius: 1vw;
}
.HotStockBckg{
    padding: 2%;
    background-color: #34C100;
    border-radius: 1vw;

}
.PCChangeFromLastTrnsctnSOC{
    position: absolute;
    top: 11%;
    right: 1%;
    font-size: 2.5vw;
    width: 21%;
    text-align: right;
}
.StockPercentChangeFromLastTrnsctnSOC{
    position: absolute;
    top: 25%;
    right: 1%;
    font-size: 2.5vw;
    /* width: 21%; */
    text-align: right;
    overflow: hidden;
}
.SMHighStats {
    position: absolute;
    top: 0%;
    right: 0%;
    height: 6.2vw;
    background-color: #363636;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.SMHighFollows {
    display: inline;
    position: absolute;
    top: 0vw;
    right: 1vw;
    line-height: 6.3vw;
    margin: 0;
    font-size: 2.5vw;
    text-align: right;
    z-index: 1;
}
.SMHighFollowsSpace {
    display: inline;
    position: relative;
    top: -3.5vw;
    right: 0vw;
    line-height: 9vw;
    margin: 0;
    padding: 0 0.2vw;
    font-size: 2.5vw;
    text-align: right;
    color: #363636;
    z-index: 0;
}
.YouTubeHIcon {
    position: relative;
    width: 9.2vw;
    top: -0.9vw;
    right: 1.3vw;
}
.InstagramHIcon {
    position: relative;
    width: 7.8vw;
    top: -1.7vw;
    right: 1vw;
}
.TikTokHIcon {
    position: relative;
    width: 6.3vw;
    top: -2.3vw;
    right: 2vw;
}