#PassionCoinDiv{
    position: absolute;
    top:15vw;
    left:0;
    width: 100%;
    height: 350vw;
}
#PCTitle {
    position: absolute;
    top: 25vw;
    left: 8vw;
    width: 84vw;
    padding-left: 9vw;
    line-height: 18vw;
    margin: 0;
    font-size: 5.5vw;
    background-color: #292929;
    border-radius: 14px;
    text-align: center;
}
#PCPic1 {
    position: absolute;
    top: 27vw;
    left: 10vw;
    width: 13.5vw;
}
#WhatQstTitlePC {
    position: absolute;
    top: 52vw;
    left: 10vw;
    line-height: 5vw;
    margin: 0;
    font-size: 4vw;
    font-weight: normal;
}
#WhatQstTitlePC span,#HowQstTitlePC span,#WhatDoQstTPC span{
    font-size: 5vw;
    font-weight: bold;
    color: #ffbf12;
}
#PCPic2 {
    position: absolute;
    top: 94vw;
    left: 23vw;
    width: 54vw;
}
#HowQstTitlePC {
    position: absolute;
    top: 129vw;
    left: 10vw;
    font-size: 4vw;
    line-height: 5vw;
    font-weight: normal;
    margin: 0;
}
#PCPic3 {
    position: absolute;
    top: 161vw;
    left: 25vw;
    width: 50vw;
}
#WhatDoQstTPC {
    position: absolute;
    top: 224vw;
    left: 10vw;
    font-size: 4vw;
    font-weight: normal;
    line-height: 5vw;
    margin: 0;
}
#PCPic4 {
    position: absolute;
    top: 257vw;
    left: 23vw;
    width: 53vw;
}
#WhatNextPC {
    position: absolute;
    top: 303vw;
    left: 10vw;
    color: #ffbf12;
    font-size: 5vw;
    line-height: 5vw;
    margin: 0;
}
#NextArrowPC {
    position: absolute;
    top: 313vw;
    left: 19vw;
    width: 10vw;
}
#GoBackPC {
    position: absolute;
    top: 318vw;
    left: 64vw;
    color: #ffbf12;
    font-size: 5vw;
    line-height: 5vw;
    margin: 0;
}
#GoBackArrowPC {
    position: absolute;
    top: 303vw;
    left: 69vw;
    width: 10vw;
}