.AgentIPOed3rdProfile__Background {
  position: relative;
  top: 15vw;
  left: 0%;
  width: 100%;
  height: 57vw;
  background-size: 100vw auto;
}
#StockPriceChangesTxtA3P {
  position: absolute;
  top: 45vw;
  left: 75.65%;
  background-color: #1f1f1f;
  color: #34c100;
  border-radius: 15px;
  padding: 2.5vw;
  padding-bottom: 6vw;
  font-size: 3vw;
  line-height: 4vw;
  text-align: right;
}
.AgentIPOed3rdProfile__CreativeCardPosition {
  margin: 0vw auto;
  width: 84vw;
  height: 34vw;
}
.AgentIPOed3rdProfile__ResponsesPos {
  position: relative;
  width: 84vw;
  margin: -1vw auto 16vw;
}
.AgentIPOed3rdProfile__InteractBtnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 84vw;
  margin: 0 auto;
}
.AgentIPOed3rdProfile__InvestBtn {
  padding: 0 auto;
  font-size: 4vw;
  line-height: 8vw;
  background-color: #ffbf12;
  border: 0.9vw solid #ffbf12;
  border-radius: 3vw;
  /* height: 8vw; */
  width: 33vw;
  margin: 0;
  text-align: center;
}
.AgentIPOed3rdProfile__JoinBtn {
  width: 33vw;
  font-size: 4vw;
  line-height: 8vw;
  border: 0.9vw solid #902366;
  border-radius: 3vw;
  /* height: 8vw; */
  margin: 0 2vw;
  text-align: center;
}

.AgentIPOed3rdProfile__SharingBtn {
  width: 10vw;
  height: 8vw;
  padding-top: 0.5vw;
}
.AgentIPOed3rdProfile__BackFromShare {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
}

.AgentIPOed3rdProfile__StarsContainer {
  width: 45vw;
  margin: 8vw 8vw 4vw;
}
.AgentIPOed3rdProfile__Metrics {
  width: 92vw;
  text-align: left;
  font-size: 3.5vw;
  line-height: 7vw;
  margin: 4vw 0 7vw 8vw;
}
.AgentIPOed3rdProfile__Metrics span {
  color: #ffbf12;
  padding-right: 2vw;
}
.AgentIPOed3rdProfile__Metrics img {
  position: relative;
  left: -2vw;
  width: 3.5vw;
}

.AgentIPOed3rdProfile__Describe {
  width: 84vw;
  text-align: left;
  margin: 9vw 8vw;
  font-size: 4vw;
  line-height: 6vw;
}
.AgentIPOed3rdProfile__RemainingStocks {
  font-size: 5vw;
  margin: 5vw 8vw;
  width: 84vw;
  line-height: 5vw;
}
.AgentIPOed3rdProfile__RemainingStocks span {
  color: #ffbf12;
}
.AgentIPOed3rdProfile__SocialMPos {
  position: relative;
  width: 84vw;
  height: 60vw;
  margin: 12vw 8vw;
}
.AgentIPOed3rdProfile__StatsPrdctContainer {
  position: relative;
  width: 84vw;
  margin: 0 8vw 30vw;
}
.AgentIPOed3rdProfile__PrdctStatsBtns {
  width: 84vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.AgentIPOed3rdProfile__StatsBtn {
  width: fit-content;
  font-size: 5vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
.AgentIPOed3rdProfile__ProductsBtn {
  width: fit-content;
  font-size: 5vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
.AgentIPOed3rdProfile__BasicLine {
  display: block;
  width: 84vw;
  margin: 3vw 0;
}
.AgentIPOed3rdProfile__StatsSwitchLine {
  position: absolute;
  top: 8.8vw;
  left: 4vw;
  transition: right 0.5s;
}
.AgentIPOed3rdProfile__PrdctSwitchLine {
  position: absolute;
  top: 8.8vw;
  right: 7vw;
  transition: left 0.5s;
}
.AgentIPOed3rdProfile__StockInformation {
  position: relative;
  width: 100%;
  margin-top: 10vw;
}
.AgentIPOed3rdProfile__SelectStatsFilter {
    display: block;
  width: fit-content;
  padding: 0 3vw;
  font-size: 4vw;
  height: 7vw;
  color: white;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-weight: normal;
  background: black;
  margin: 0 auto 8vw;
}
.AgentIPOed3rdProfile__FlexContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.AgentIPOed3rdProfile__SquareData {
    width: 100%;
    margin: 0 1vw;
    height: 37vw;
    background-color: #363636;
    border-radius: 10px;
}
.AgentIPOed3rdProfile__SquareData p:nth-child(1) {
  width: fit-content;
  padding: 0;
  margin: 30% auto 0;
  color: #ffbf12;
  font-size: 7vw;
}

.AgentIPOed3rdProfile__SquareData p:nth-child(2) {
  padding: 0 1vw;
  margin: 3vw auto 15%;
  width: fit-content;
  font-size: 3.3vw;
  text-align: center;
  line-height: 4vw;
}

#TopMemberTxtNIPONIPO {
  position: absolute;
  top: 7vw;
  left: 7.5%;
  font-size: 4vw;
  line-height: 5vw;
  color: #909090;
  font-weight: normal;
  margin: 0;
}

#AgencyOPProductsInformationDivA3P {
  position: absolute;
  top: 15vw;
  left: 0vw;
  width: 100%;
  height: 150vw;
}
#AgencyOPUDSMPosA3P {
  position: absolute;
  top: 13vw;
  left: 0%;
  width: 100%;
}
#DefaultDivA3P {
  position: absolute;
  top: 13vw;
  left: 0%;
  width: 100%;
}
#SMBuyBtnA3P {
  position: absolute;
  top: 58vw;
  left: 32%;
  padding: 0 14.5vw;
  font-size: 4vw;
  line-height: 8vw;
  background-color: #902366;
  border: 3.3px solid #902366;
  border-radius: 8px;
  margin: 0;
}

#DidplayReqiuerdBeSMIMsg {
  position: fixed;
  bottom: 19vw;
  left: 0;
  width: 92vw;
  text-align: left;
  color: #ffbf12;
  font-size: 4.4vw;
  line-height: 5vw;
  padding: 10vw 4vw;
  padding-bottom: 14vw;
  margin: 0;
  background-color: #363636;
  z-index: 9;
}
#DidplayReqiuerdBeSMIMsg span:nth-child(1) {
  background-color: #ffffff;
  /* line-height: 8vw; */
  padding: 1vw 3vw;
  color: #363636;
  border-radius: 10px;
  position: relative;
  top: 6vw;
}


.AgentIPOed3rdProfile__Message{
  position: fixed;
  bottom: 24vw;
  left: 8vw;
  padding: 5vw;
  background: #363636;
  border-radius: 4.5vw;
  margin: 0;
  width: 84vw;
  box-shadow: 0 0vw 2.5vw 0 #fff;
  z-index: 660;
}
.AgentIPOed3rdProfile__Message p{
  font-size: 4.25vw;
  text-align: center;
  line-height: 5.5vw;
  margin: 0 auto;
  color: white;
}
.AgentIPOed3rdProfile__Message span{
  padding: 0 2vw;
  font-size: 4vw;
  line-height: 8vw;
  border: 0.9vw solid #ffbf12;
  border-radius: 3vw;
  margin: 0;
  width: fit-content;
}
#LaterBtnA3P {
  position: relative;
  top: 6vw;
  left: 4vw;
  color: #ffffff;
  padding: 0.7vw 6vw;
  border: 2px solid #902366;
  border-radius: 10px;
}
#HideA3P {
  display: none;
}
