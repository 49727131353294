#LoaderPosNoti {
  position: relative;
  width: 8vw;
  height: 8vw;
  margin: 21vw auto;
  display: block;
}
.notifi_card {
  padding: 2vh 0;
  display: flex;
  min-height: 10vh;
  border-bottom: 1px solid white;
  color: #dadada;
  font-size: max(15px, 4vw);
}

.notifi_image {
  /* width: 25vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
}

.notifi_image img {
  width: 4rem;
  height: 4rem;
}

.notifi_text {
  /* width: 50vw; */
  min-width: 150px;
  text-align: center;
  line-height: normal;
  padding: 0 2vw;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

/* .notifi_title {
} */

.notifi_body {
  font-size: max(11px, 2vw);
  padding: 1vh 0 0 0;
  color: gray;
}

.notifi_action {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 25vw; */
  min-width: 100px;
}

.notifi_action button {
  color: white;
  background: #ffbf12;
  padding: 10px 13px;
  border-radius: 12px;
  border: none;
  font-family: inherit;
  font-weight: inherit;
}

.notifi_action button img {
  width: 5rem;
  height: 5rem;
}

.notifi_active {
  background-color: rgba(165, 157, 157, 0.2);
}
