.YourPassions {
  justify-content: space-between;
}
.YourPassions__WhatTxt {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 10vw;
  margin: 0 auto;
  padding: 9vh 0vw 0 3vw;
  text-shadow: 0.35vw 0.35vw 0px black, 0.5vw 0.5vw 0 white;
}
.YourPassions__SelectTxt {
    font-size: 4.25vw;
    font-weight: lighter;
    line-height: 6vw;
    margin: 0;
    padding: 0 8vw 5vw;
}
.YourPassions__HashTagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    font-size: 5vw;
    width: 86%;
    height: -moz-fit-content;
    height: 27vh;
    margin: 0 8vw;
    overflow: scroll;
    padding-bottom: 4vh;
}
/* .HashTagBtn{
    font-size: 5vw;
    text-align: center;
}
.HashTagCheck{
    font-size: 5vw;
    text-align: center;
    background-color: #FFBF12;
    line-height: 5vw;
    border-radius: 20px;
    padding: 0 2vw;
} */
.YourPassions__UnCheck {
  color: white;
  padding: 0 2vw;
  line-height: 9vw;
  margin: 0;
  font-size: 4vw;
}
.YourPassions__Check {
  background-color: #ffbf12;
  color: white;
  border-radius: 20px;
  padding: 0 2vw;
  line-height: 9vw;
  margin: 1vw 1.5vw;
  font-size: 4vw;
}
.YourPassions__ErrorMessageContainer{
    height: 13vw;
}
.YourPassions__HashtagInput {
    width: 86%;
    font-size: 4.25vw;
    line-height: 10vw;
    height: 13vw;
    border-radius: 10vw;
    color: black;
    padding: 0 5vw;
    margin-top: 0 !important;
}

.YourPassions__DoneBtn {
    float: right;
    background-color: white;
    border-top-left-radius: 10vw;
    border-bottom-left-radius: 10vw;
    color: #ffbf12;
    font-size: 4.25vw;
    padding: 0 4vw 0 6vw;
    margin: 0;
}
