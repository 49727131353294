.Deposit__Balance {
  font-size: 15vw;
  margin: 12vh 0 0;
  line-height: 12vw;
  width: 100%;
  text-align: center;
  text-shadow: 0 0 6vw #ffbf12, -0.25vw 0.25vw #ffbf12, 0.25vw -0.25vw #ffbf12,
    0.25vw 0.25vw #ffbf12, -0.25vw -0.25vw 0 #ffbf12;
}
.Deposit__DepositTxt {
  font-size: 4.5vw;
  margin: 0;
  line-height: 5vw;
  width: 100%;
  text-align: center;
  color: #b7b7b7;
}
.Deposit__AmountInput {
  width: 84vw;
  text-align: center;
  font-size: 6vw;
  height: 9vw;
  border-radius: 10px;
  border: none;
  margin: 3vw auto;
  padding-left: 7vw !important;
}
.Deposit__AmountInput::placeholder {
  text-align: center;
  color: #b7b7b7;
  font-weight: normal;
}
.Deposit__CurrenceyCodeTxt {
  position: absolute;
  top: 2vw;
  left: 10vw;
  font-size: 5.5vw;
  margin: 0;
  line-height: 5vw;
  color: black;
  z-index: 4;
}
.Deposit__AmountInPC {
  width: 100%;
  text-align: center;
  font-size: 5.5vw;
  color: #ffbf12;
  z-index: 1;
  margin: 0;
  line-height: 6vw;
}
.Deposit__Line {
  display: block;
  margin: 0 auto 8vh;
}
.Deposit__ChooseTxt {
  font-size: 8vw;
  margin: 0 8vw 8vw;
  line-height: 9vw;
  color: #707070;
}
.Deposit__btns-conatainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#PayPalInputSubmit {
  display: none;
}
.Deposit__PayPalBtn {
  width: 40vw;
  height: 11vw;
  font-size: 5vw;
  margin: 0 4vw 0 0;
  text-align: center;
  font-style: normal;
  color: white;
  font-weight: normal;
  line-height: 11vw;
  /* padding: 0px 13vw; */
  border: none;
  border-radius: 29px;
  background-color: #4167b2;
}
.Deposit__PayPalLogo {
  position: absolute;
  top: 2vw;
  left: 4vw;
  width: 6.5vw;
}
.Deposit__OzowBtn {
  font-size: 5vw;
  margin: 0 0 0 4vw;
  line-height: 11vw;
  padding: 0 12.5vw;
  color: black;
  font-weight: normal;
  border-radius: 30px;
  background-color: rgb(255, 255, 255, 0.9);
}
.Deposit__OzowLogo {
  position: absolute;
  top: 1vw;
  left: 5.5vw;
  width: 9vw;
}
#HideFormDeposit {
  display: none;
}
