.TwoSteps {
  padding-top: 9vw;
}
.TwoSteps__TheProcessStagesLine {
  position: absolute;
  top: 0vw;
  left: 0vw;
  border: 3px solid #ffbf12;
  border-radius: 10vw;
  background: white;
}
.TwoSteps__ProcessNumber {
  position: absolute;
  background: #121212;
  height: 40px;
  width: 40px;
  border-radius: 10vw;
  border: 3px solid #ffbf12;
  /* opacity: 0.5; */
  font-size: 26px;
  margin-left: 1.5px;
  line-height: 32px;
}

.OpacityButtons {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}
.OpacityButtons__Background {
  background: white;
  border: 0.65vw solid #ffbf12;
  border-radius: 10vw;
  width: 155px;
  height: 43px;
  margin: 3.5vw auto;
  box-shadow: 0vw 0vw 3vw #ffbf12;
  opacity: 0.9;
}
.OpacityButtons__Button {
  position: absolute;
  background: #121212;
  height: 43px;
  width: 43px;
  border-radius: 10vw;
  border: 0.65vw solid #ffbf12;
  /* opacity: 0.5; */
  font-size: 6.5vw;
}
.OpacityButtons__Ring {
  position: absolute;
  height: 43px;
  width: 43px;
  border-radius: 10vw;
  border: 0.65vw solid #ffbf12;
  box-shadow: 0vw 0vw 3vw #ffbf12;
}

.TwoSteps__Paragraph {
  margin: 0 0 60px 57px;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  font-weight: lighter;
}
.TwoSteps h2 {
  font-size: 16px;
  line-height: 20px;
  margin: 5px 0;
  padding-top: 10px;
}
