.EditProfile__BackBtn {
  top: 5vw !important;
}
.EditProfile__Background {
  width: 100%;
  height: 57vw;
  background-size: 100vw auto;
}
.EditProfile__AvatarContainer {
  width: 100%;
  height: 33vw;
}
.EditProfile__AvatarPositionSize {
  position: relative;
  width: 33vw;
  height: 33vw;
  margin: 0 auto;
}
/* .EditProfile__hide{
    display: none;
} */

.EditProfile__EditCoverImgBtn {
  position: absolute;
  bottom: 2vw;
  right: 3vw;
  width: 7vw;
  z-index: 4;
}
.EditProfile__EditAvatarIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 7vw;
  z-index: 4;
}
.EditProfile__Input {
  display: block;
  font-family: sans-serif;
  width: 80vw;
  background: none;
  border: none;
  padding: 1.5vw 0vw;
  font-size: 4.25vw;
  border-bottom: 1px solid #707070;
  color: white;
  margin: 0 auto 2vw;
  border-radius: 0;
}
.EditProfile__container-phone-number {
    margin: 0 auto 2vw;
    width: 80vw !important;
}
.EditProfile__PhoneNumber-button {
    height: 7vw !important;
    width: 10.7vw !important;
    top: 0.5vw !important;
    /* border-top-left-radius: 3vw !important; */
    /* border-bottom-left-radius: 3vw !important; */
    background: none !important;
    border: none !important;
}
.EditProfile__PhoneNumber-input {
    padding-left: 12vw !important;
    width: 80vw !important;
    height: 8vw !important;
    font-size: 4.25vw !important;
    font-family: "open-sans" !important;
    font-weight: normal !important;
    line-height: 5vw !important;
    border-radius: 0vw !important;
    border: 0 !important;
    background: none !important;
    color: white;
    border: none !important;
    border-bottom: 1px solid #707070 !important;
margin: 0 auto  !important;    
}
.EditProfile__PhoneNumber-input::placeholder{
    font-size: 4.25vw !important;

}
/* #BtnEditBirthday{
    position: absolute;
    top: 96vw;
    left: 10%;
    width: 49%;
    color: white;
    text-align: center;
    line-height: 10vw;
    font-size: 4vw;
    background-color: #363636;
    border-radius: 8px;
}
#BtnEditCity{
    position: absolute;
    top: 96vw;
    left: 61%;
    width: 27%;
    padding-left: 2vw;
    background-color: #707070;
    border-radius: 15px;
    color: white;
    text-align: left;
    line-height: 10vw;
    font-size: 4vw;
    background-color: #363636;
    border-radius: 8px;
} */

.EditProfile__SMINiches,
.EditProfile__MyPassion {
  position: relative;
  width: 80vw;
  background-color: #363636;
  color: white;
  border-radius: 4vw;
  margin: 8vw auto 0;
}
.EditProfile__SMINiches p,
.EditProfile__MyPassion p {
  width: 100%;
  font-size: 4vw;
  font-weight: normal;
  line-height: 4vw;
  padding: 4vw 4vw 0;
}
.EditProfile__SMINichesContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 2vw;
  width: 74vw;
  /* paddin: 0 2vw; */
}
.EditProfile__SMINicheInput {
  display: block;
  margin: 0 1vw;
  width: 100%;
  border-radius: 4vw !important;
  margin-bottom: 2.5vw;
  font-weight: normal;
}
.EditProfile__SMINicheInput::placeholder {
  color: #b8b8b8;
  font-weight: normal;
}
.EditProfile__InputMyPassion {
  width: 76vw;
  height: 24.5vw;
  font-size: 4.25vw;
  line-height: 5.5vw;
  font-weight: normal;
  color: black;
  padding: 2vw 3vw;
  border-radius: 4vw;
  margin: 0 2vw 2vw;
}
.EditProfile__LoaderPosition {
  margin: 4vw auto 8vw;
  width: 5vw;
  height: 5vw;
}
.EditProfile__Done {
  display: block;
  width: 14vw;
  margin: 0 auto 5vh;
}
