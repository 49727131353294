.IssuerReg_NonEligible__Emoji {
  width: 90px;
  margin: 0 10px 15px;
}
.IssuerReg_NonEligible__ItsTxt {
  font-size: 30px;
  line-height: 40px;
  margin: 0 2vw 3vw;
  text-shadow: 0 0 #902366;
}
.IssuerReg_NonEligible__AtTxtSorry {
  font-size: 16px;
  line-height: 22px;
  font-weight: lighter;
  margin: 0 2vw 40px;
}
.IssuerReg_NonEligible__EmailInput {
  width: 100%;
  padding: 0 4vw;
  font-size: 15px;
  line-height: 30px;
  color: black;
  border-radius: 25px;
  border: none;
}
.IssuerReg_NonEligible__indicate-container {
  height: 12vw;
  padding-bottom: 6vh;
}
.IssuerReg_NonEligible__JoinWaitlistBtn {
  width: 48%;
  padding: 0 3vw;
  font-size: 16px;
  line-height: 8vw;
  background-color: #ffbf12;
  color: white;
  border-radius: 25px;
  border: none;
}
.IssuerReg_NonEligible__SuccessMsg {
  position: fixed;
  bottom: 8vw;
  padding: 5vw;
  background: #363636;
  border-radius: 4.5vw;
  margin: 0;
  width: 84vw;
  box-shadow: 0 0vw 2.5vw 0 #fff;
  z-index: 60;
}
.IssuerReg_NonEligible__SuccessMsg p {
  font-size: 4.25vw;
  text-align: center;
  line-height: 6vw;
  margin: 0 auto 7vw;
  color: white;
}
.IssuerReg_NonEligible__SuccessMsgDoneBtn {
  padding: 0px 4vw;
  width: fit-content;
  margin: 0 auto;
  display: block;
}
