.WithdrawRequestReceived__RequestReceivedTxt {
    font-size: 10vw;
    line-height: 12vw;
    margin: 0 8vw 8vw;
    text-shadow: 0.5vw 0.5vw black, 0.75vw 0.75vw white;
}
.WithdrawRequestReceived__TheFundsTxt{
    font-size: 7vw;
    line-height: 8vw;
    margin: 0 8vw 8vh;
    color: #b7b7b7;
}
.WithdrawRequestReceived__Line{
   display: block;
   margin: 0 auto;
}
.WithdrawRequestReceived__Done{
    display: block;
    margin: 0 auto;
    width: 14vw;
}