#CookiesPolicyDiv{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}
#BackPageCP {
    position: absolute;
    top: 2vh;
    left: 0%;
    width: 13vw;
    z-index: 55;
}
#CookiesScrollDiv {
    position: absolute;
    /* top: 10vh; */
    left: 5vw;
    width: 90vw;
    /* height: 80vh; */
    padding: 5vw;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 3.3vw;
    line-height: 4.5vw;
    border-radius: 10px;
    background-color: #ffffff;
    color: black;
    font-weight: normal;
}
#CookiesScrollDiv span{
    font-weight: bolder;
}
#CTable1,#CTable2,#CTable3{
    border:1px solid black;
    width: 100%;
    overflow-y: scroll;
    padding: 8px;

}