.SaleLine {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #3c3c3c;
  /* border-bottom: 0.1px solid #707070; */
  border-radius: 29px;
  font-weight: lighter;
  color: #dadada;
  height: 48px;
}
.SaleLine p {
  margin: 0;
}
.SaleLine__Data {
  display: inline;
  margin: auto 0;
  font-size: 4vw;
}
.SaleLine i {
  font-size: 15px;
  font-style: normal;
  text-align: left;
  margin: auto 4vw;
  font-weight: lighter;
}
.SaleLine span {
  color: #ffbf12;
  font-size: 16px;
  margin: 0 5px;
}
.SaleLine__BtnsContainer {
  display: flex;
  align-items: center;
  height: 6vw;
  margin: auto 3vw;
}
.SaleLine__UpdateBtn {
  width: fit-content;
  padding: 0 8px;
  font-size: 10px;
  line-height: 6vw;
  text-align: center;
  font-weight: bold;
  border-radius: 7px;
  margin: 0 ;
  border: 1px solid #ffbf12;
  background: 0;
}

