.Unvailable__Img {
  width: 50vw;
  margin: 0 8vw 15vw;
}
.Unvailable__Txt {
  width: 100vw;
  margin: 0 auto;
  text-align: center;
  font-size: 1.7em;
  line-height: 1.5em;
  margin: 0;
}
