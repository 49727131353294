.Portfolio{
    padding-top:15vw;
}
/* #TradingBoard {
    position: absolute;
    top: 0vw;
    left: 0%;
    width: 100%;
    height: 100vh;
} */

/* #UseToHideNavBottom{
    position: fixed;
    width: 100%;
    height: 20vw;
    bottom: 0vw;
    z-index: 30;
    background-color: #121212;
} */
.Portfolio__BackBtn {
    position: absolute;
    top: 20vw;
    left: 0%;
    width: 13vw;
    z-index: 20;
}

#StocksHolding {
    /* position: absolute; */
    /* top: 25vw; */
    /* left: 5%; */
    width: 90%;
    /* height: 120vw; */
    margin: 0 5vw;
    padding-bottom: 30vw;
}

#NoStocksHoldingDiv {
    position: absolute;
    top: 15vw;
    width: 100%;
    left: 0%;
}

#EyesNoStcksT {
    position: relative;
    width: 47%;
    left: 33%;
}

#NoStocksHoldingDiv p:nth-child(1) {
    width: 100%;
    text-align: center;
    font-size: 9vw;
    line-height: 10vw;
    margin: 8vw 0;
}

#NoStocksHoldingDiv p:nth-child(2) {
    width: 100%;
    text-align: center;
    font-size: 8.5vw;
    line-height: 10vw;
    margin: 11vw 0;
    text-shadow: -0.65vw 0 0vw black, -0.75vw 0vw 0vw white;
}
#HowTxtNST {
    position: relative;
    width: 100%;
    font-size: 3.5vw;
    line-height: 2vw;
    text-align: center;
    display: inline-block;
}
#DareYouSmileyT {
    width: 6%;
    position: relative;
    top: 2vw;
    left: 1.5vw;
}

.StockHDetails {
    position: relative;
    top: 13vw;
    left: 0%;
    width: 100%;
    /* height: 76vw; */
    margin: 0;
    padding: 0;
}

.StockCardTrading {
    position: relative;
    width: 100%;
    height: 34vw;
    background-color: #1F1F1F;
    box-shadow: 0px 13px 9px -6px #121212;
    z-index: 4;
}

.SMIPicTrading {
    position: absolute;
    left: 5%;
    top: 16%;
    width: 26%;
    height: 23vw;
    border: 2px solid #FFBF12;
    border-radius: 50%;
}

.SMINameTrading {
    position: absolute;
    left: 35%;
    top: -4vw;
    font-size: 6vw;
}

.StockPriceTrading {
    position: absolute;
    left: 35%;
    top: 5vw;
    font-size: 5vw;
}

.ArrowTrading {
    position: absolute;
    left: 60%;
    top: 14vw;
    width: 3.5%;
}

.SMISMediaPassionTrading {
    position: absolute;
    left: 35%;
    top: 12.5vw;
    font-size: 3vw;
    font-weight: normal;
}

.SMILocationTrading {
    position: absolute;
    left: 35%;
    top: 17.7vw;
    font-size: 3vw;
    text-align: left;
}

.TradeBtn {
    position: absolute;
    top: 28vw;
    left: 73.5%;
    background-color: #FFBF12;
    color: white;
    border-radius: 8px;
    padding: 0 5vw;
    line-height: 9vw;
    width: fit-content;
    margin: 1vw 0;
    font-size: 4vw;
    box-shadow: 0px 11px 9px -6px #121212;
    z-index: 30;
}

.StockHoldingNumDiv {
    position: relative;
    top: -7vw;
    width: 100%;
    height: 42vw;
    background-color: #2E2E2E;
    border-radius: 20px;
    z-index: 3;
}

.StocksNum {
    position: absolute;
    left: 0%;
    top: 13.7vw;
    width: 38vw;
    font-size: 20vw;
    text-align: right;
    color: #FFBF12;
    margin: 0;
    line-height: 21vw;
}

.StockHoldingTxt {
    position: absolute;
    left: 47%;
    top: 15.7vw;
    margin: 0;
    padding: 0;
    width: 30%;
    font-size: 8vw;
    line-height: 9vw;
    text-align: left;
}

.StkHldnPCChangeFromLastTrnsctn {
    position: absolute;
    top: 17vw;
    left: 76%;
    font-size: 2.5vw;
    width: 21%;
    text-align: right;
    margin: 0;
    line-height: 3vw;
}

.StkHldnStockPercentChangeFromLastTrnsctn {
    position: absolute;
    top: 21vw;
    left: 76%;
    font-size: 2.5vw;
    width: 21%;
    text-align: right;
    margin: 0;
    line-height: 3vw;
}

.OnSaleList {
    position: relative;
    top: 8vw;
    width: 100%;
}

#yyy {
    position: relative;
    top: -9vw;
    left: 0%;
    width: 100;
    height: 25vw;
}

#doubleSpace {
    position: relative;
    top: 0vw;
    left: 0%;
    width: 100;
    height: 25vw;
}

.ForSale {
    position: relative;
    top: -9vw;
    left: 0%;
    width: 100;
    height: 25vw;
    background-color: #363636;
    border-radius: 20px;
    border-bottom: 0.1px solid #707070;
}

.ForSale22 {
    position: relative;
    top: -9vw;
    left: 0%;
    width: 100;
    height: 18vw;
    background-color: #363636;
    border-radius: 20px;
    border-bottom: 0.1px solid #707070;
    visibility: hidden;

}

.ForSaleTxt {
    position: absolute;
    top: 14vw;
    left: 20%;
    font-size: 6vw;
    text-align: left;
    opacity: 1;
    margin: 0;
    line-height: 7vw;
}

.ForSaleTxt i {
    position: absolute;
    top: 0vw;
    left: -21%;
    font-size: 9vw;
    font-style: normal;
    text-align: left;
}

.ForSaleTxt spam {
    color: #FFBF12;
}

.none {
    position: relative;
    top: -15vw;
    left: 0%;
    width: 100%;
    height: 1vw;
}

.Wrap {
    position: relative;
    top: 16vw;
    left: 0%;
    width: 100%;
    height: 1vw;
}

.RemoveBtn {
    position: absolute;
    top: 15vw;
    right: 4vw;
    width: 18vw;
    padding: 0 1vw;
    font-size: 2.4vw;
    line-height: 6vw;
    text-align: center;
    font-weight: normal;
    background-color: #902366;
    border-radius: 7px;
    margin: 0;
}