.BreakLine {
  position: relative;
  margin: 0 auto;
  display: block;
}
.BreakLine--GlowBlue {
  border-radius: 25px !important;
  box-shadow: 0 0 2vw 0.5vw #24beff !important;
  height: 8px !important;
  z-index: 5 !important;
  stroke: white !important;
}
.BreakLine--GlowBlue line{
    stroke: white !important;
}