.Step2MC__Step2Txt {
  font-size: 8vw;
  margin: 0;
  line-height: 11vw;
  text-transform: initial;
}
.Step2MC__LinkItTxt {
    font-size: 6vw;
    margin: 0;
    line-height: 6vw;
    padding-bottom: 1.15vw;
}
.Step2MC__Step2ExpTxt {
  font-size: 3.5vw;
  margin: 0;
  line-height: 6vw;
}
.Step2MC__Input {
  border-radius: 2vw;
  padding-left: 12vw;
  height: 10vw;
}
/* #LinkInsta{
    position: absolute;
    top: 109vw;
    left: 8%;
    padding: 0px 24vw;
    font-size: 4vw;
    line-height: 9vw;
    color: black;
    border-radius: 7px;
    background-color: #ffffff;
    text-align: center;
} */
.Step2MC__InstaIcon {
  position: absolute;
  top: 1.25vw;
  left: 1vw;
  width: 8.25vw;
}
.Step2MC__LinkTiktokInput {
  padding: 0px 17.9vw;
  font-size: 4vw;
  line-height: 9vw;
  color: black;
  border-radius: 7px;
  background-color: #ffffff;
  text-align: center;
  margin: 0;
  border: none;
}
/* #LinkTiktok{
    position: absolute;
    top: 125vw;
    left: 8%;
    padding: 0px 26.55vw;
    font-size: 4vw;
    line-height: 9vw;
    color: black;
    border-radius: 7px;
    background-color: #ffffff;
    text-align: center;
} */
.Step2MC__TikTokIcon {
    position: absolute;
    top: 1.25vw;
    left: 1.25vw;
    width: 7.2vw;
}
.Step2MC__LinkYoutubeInput {
  padding: 0px 17.9vw;
  font-size: 4vw;
  line-height: 9vw;
  color: black;
  border-radius: 7px;
  background-color: #ffffff;
  text-align: center;
  margin: 0;
  border: none;
}
/* #LinkYoutube{
    position: absolute;
    top: 142vw;
    left: 8%;
    padding: 0px 25.1vw;
    font-size: 4vw;
    line-height: 9vw;
    color: black;
    border-radius: 7px;
    background-color: #ffffff;
    text-align: center;
} */
.Step2MC__YoutubeIcon {
    position: absolute;
    top: 0vw;
    left: 0vw;
    width: 10.5vw;
}
