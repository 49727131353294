.InvestorProfile{
    position: absolute;
    top: 0vw;
    left: 0%;
    transition: left .8s;
    padding-bottom: 19vw;
}
.InvestorProfile__Data{
    position: relative;
    margin: 20vw 5vw 0;
    padding: 5vw 0;
    width: 90vw;
    height: fit-content;
    background-color: #363636;
}

.InvestorProfile__EditIcon{
    position: absolute;
    top: 3vw;
    right: 4vw;
    width: 9vw;
}
.InvestorProfile__AvatarPosition {
    width: 27vw;
    height: 27vw;
    margin: 5vw auto;
}
.InvestorProfile__StarsLine {
    width: 50vw;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.RateStars{
    display: inline;
    width: 17%;
    padding: 0.5vw;
}
.InvestorProfile__Name{
    width: 100%;
    text-align: center;
    font-size: 7vw;
    line-height: 7vw;
    margin: 5vw 0 0;
}
.InvestorProfile__Username{
    width: 100%;
    text-align: center;
    font-size: 7vw;
    line-height: 7vw;
    margin: 0;
}
#MyBrokerBtn{
    position: absolute;
    top: 83vw;
    left: 1.8%;
    width: 24.5%;
    text-align: center;
    font-size: 3.8vw;
    border: 3px solid #902366;
    border-radius: 10px;
    line-height: 7vw;
    padding: 0px 6vw;
}
.InvestorProfile__DiscoverYP{
    width: fit-content;
    text-align: center;
    font-size: 3.8vw;
    border: 2px solid #FFBF12;
    border-radius: 10px;
    line-height: 7vw;
    padding: 0px 3vw;
    margin: 8vw auto 0;
}

.InvestorProfile__PrdctPendingList {
    width: 90vw;
    height: fit-content;
    margin: 10vw auto 0;
}
#PrdctsPendingTxt {
    position: relative;
    text-align: left;
    font-size: 4vw;
    line-height: 4.5vw;
    margin: 0vw 0 4vw 0;
}