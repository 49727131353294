.CreateTradeConfirmation__BackBtn {
  position: absolute;
  top: 5vw;
  left: 0%;
  width: 13vw;
  z-index: 35;
}
.CreateTradeConfirmation__TradeTxt {
  font-size: 10vw;
  margin: 0vw 5vw 12vw;
  text-shadow: 0.45vw 0.55vw 0 black, 0.5vw 0.75vw 0 white;
}
.CreateTradeConfirmation__DoneBtn {

    display: block;
    margin: 5vw auto;
  width: 15vw;
}
