#cookies-message {
  position: fixed;
  left: 0;
  bottom: 19vw;
  width: 100%;
  height: 35vw;
  background-color: #ffffff;
  z-index: 120;
}
#CloseCookiesTxt {
  display: none;
}
#CookiesTxt {
  position: absolute;
  top: 7vw;
  left: 4.5%;
  font-size: 3vw;
  text-align: left;
  line-height: 4vw;
  letter-spacing: -0.04vw;
  color: black;
  margin: 0;
}
#CookiesTxt span {
  color: #505050;
}
#AcceptBtn {
  position: absolute;
  top: 14vw;
  left: 76.5%;
  font-size: 3.5vw;
  text-align: center;
  color: #ffbf12;
  background-color: white;
  box-shadow: 0px 0px 19px -7px black;
  border-radius: 25px;
  padding: 0 4vw;
  line-height: 9vw;
}
#XBtn {
  position: absolute;
  top: 4vw;
  left: 92.5%;
  width: 4vw;
}
