.Home__Copy {
  font-size: 0.45em;
  line-height: 1.7em;
  text-align: center;
  padding: 0px 9vw;
  margin-top: -8vw;
  position: relative;
}
.Home__Line {
  display: block;
  margin: 1.25em auto 0.5em;
}
.Home__SloganLogo {
  width: 75%;
  display: block;
  margin: 0 auto;
}
.Home__SloganTxt {
  font-size: 0.6em;
  line-height: 1.45em;
  text-align: center;
}
/* company inform section */
.Home__CompanyInform {
  width: 100%;
  margin: 0 0 0.75em;
  display: flex;
  justify-content: space-evenly;
}
.Home__CompanyInform p {
  font-size: 4vw;
  line-height: 4vw;
}
.Home__SM {
  display: flex;
  justify-content: center;
  margin: 5vh 0;
}
.Home__SMItem{
  display: block;
  width: 16vw;
  padding:  0 2vw;
}
#WhatsappLogoBWH {
  width: 10%;
}
#FacebookLogoBWH {
  width: 10%;
}
#InstagramLogoBWH {
  width: 10%;
}
#YoutubeLogoBWH {
  width: 10%;
}
#TsCsApplyTxtH {
  width: 100%;
  font-size: 3vw;
  text-align: center;
  line-height: 3vw;
  margin: 0;
}
#TsCsApplyTxtH a {
  text-decoration: underline;
}

#CookiesPolicyH {
  position: absolute;
  top: 48vw;
  right: 55vw;
  font-size: 3vw;
  line-height: 3vw;
  margin: 0;
  text-decoration: underline;
}
#PrivacyPolicyH {
  position: absolute;
  top: 48vw;
  left: 55vw;
  font-size: 3vw;
  line-height: 3vw;
  margin: 0;
  text-decoration: underline;
}
#LineEndH {
  position: absolute;
  top: 50vw;
  left: 28.5%;
}
