.Demo__EmojiWinking {
    display: block;
    margin: 8vh auto 5vw;
    width: 19vw;
}
.Demo__SignUpTxt {
  width: 100%;
  text-align: center;
  font-size: 7.8vw;
  margin: 0;
  line-height: 11vw;
}

.Demo__SignUpBtn {
    font-size: 5vw;
    line-height: 9vw;
    padding: 0 12vw;
    margin: 0 auto 9vw;
    color: #ffbf12;
    background-color: white;
    border-radius: 25vw;
    width: fit-content;
}
.Demo__SignInBtn {
  font-size: 5vw;
  line-height: 9vw;
  padding: 0 12.7vw;
  margin: 0 auto;
  color: #ffbf12;
  border: 1px solid white;
  border-radius: 20vw;
  width: fit-content;
}
