#SearchElement {
  position: relative;
  top: 0vw;
  left: 5%;
  width: 90%;
  height: 60vw;
  margin: 4vw 0;
}
.ResultList__SearchElementsBtnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 5vw;
}
#SearchResultInvestBtn {
  /* position: absolute;
    top: 40vw;
    left: 8%; */
  padding: 0 10.5vw;
  font-size: 4vw;
  line-height: 8vw;
  background-color: #ffbf12;
  border: 3px solid #ffbf12;
  border-radius: 8px;
  margin: 0;
}
#ViewProfileBtnSR {
  /* position: absolute;
  top: 36vw;
  left: 51%; */
  background-color: #121212;
  border: 2px solid #902366;
  border-radius: 7px;
  font-size: 4vw;
  width: 35vw;
  text-align: center;
  padding: 0 3vw;
  line-height: 9vw;
  margin: 0;
}
