.AgentNotIPOedProfile {
  position: absolute;
  top: 0vw;
  left: 0%;
  width: 100%;
  transition: left 0.8s;
}
.AgentNotIPOedProfile__Background {
  position: relative;
  top: 15vw;
  left: 0%;
  width: 100%;
  height: 57vw;
  background-size: 100vw auto;
}
.AgentNotIPOedProfile__EditIcon {
  position: absolute;
  top: 17vw;
  right: 2vw;
  width: 8vw;
}
.AgentNotIPOedProfile__CreativeCardPosition {
  margin: 0vw auto;
  width: 84vw;
  height: 34vw;
}

.AgentNotIPOedProfile__BtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 84vw;
  margin: 10vw auto 0;
}
.AgentNotIPOedProfile__DashboardBtn {
  padding: 0 auto;
  font-size: 4vw;
  line-height: 8vw;
  /* background-color: #ffbf12; */
  border: 0.9vw solid #ffbf12;
  border-radius: 3vw;
  /* height: 8vw; */
  width: 33vw;
  margin: 0;
  text-align: center;
}
.AgentNotIPOedProfile__EditBtn {
  width: 33vw;
  font-size: 4vw;
  line-height: 8vw;
  border: 0.9vw solid #902366;
  border-radius: 3vw;
  /* height: 8vw; */
  margin: 0 2vw;
  text-align: center;
}
.AgentNotIPOedProfile__SharingBtn {
  width: 10vw;
  height: 8vw;
  padding-top: 0.5vw;
}
.AgentNotIPOedProfile__BackFromShare {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
}
.AgentNotIPOedProfile__Describe {
  width: 84vw;
  text-align: left;
  margin: 9vw 8vw;
  font-size: 4vw;
  line-height: 6vw;
}
.AgentNotIPOedProfile__SocialMPos {
  position: relative;
  width: 84vw;
  height: 60vw;
  margin: 12vw 8vw;
}
.AgentNotIPOedProfile__StatsPrdctContainer {
  position: relative;
  width: 84vw;
  margin: 0 8vw 30vw;
}
.AgentNotIPOedProfile__PrdctStatsBtns {
  width: 84vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.AgentNotIPOedProfile__StatsBtn {
  width: fit-content;
  font-size: 5vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
.AgentNotIPOedProfile__MembersBtn {
  width: fit-content;
  font-size: 5vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
.AgentNotIPOedProfile__BasicLine {
  display: block;
  width: 84vw;
  margin: 3vw 0;
}
.AgentNotIPOedProfile__StatsSwitchLine {
  position: absolute;
  top: 8.8vw;
  left: 4vw;
  transition: right 0.5s;
}
.AgentNotIPOedProfile__PrdctSwitchLine {
  position: absolute;
  top: 8.8vw;
  right: 7vw;
  transition: left 0.5s;
}
.AgentNotIPOedProfile__StockInformation {
  position: relative;
  width: 100%;
  margin-top: 10vw;
}
.AgentNotIPOedProfile__SelectStatsFilter {
    display: block;
  width: fit-content;
  padding: 0 3vw;
  font-size: 4vw;
  height: 7vw;
  color: white;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-weight: normal;
  background: black;
  margin: 0 auto 8vw;
}
.AgentNotIPOedProfile__FlexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.AgentNotIPOedProfile__SquareData {
  width: 100%;
  margin: 0 1vw;
  height: 37vw;
  background-color: #363636;
  border-radius: 10px;
}
.AgentNotIPOedProfile__SquareData p:nth-child(1) {
width: fit-content;
padding: 0;
margin: 30% auto 0;
color: #ffbf12;
font-size: 7vw;
}

.AgentNotIPOedProfile__SquareData p:nth-child(2) {
padding: 0 1vw;
margin: 3vw auto 15%;
width: fit-content;
font-size: 3.3vw;
text-align: center;
line-height: 4vw;
}

#PosRelativeAP {
  position: absolute;
  top: 145vw;
  left: 0%;
  width: 100%;
}
#AgencyDescribe {
  position: relative;
  top: 0vw;
  left: 8%;
  width: 86%;
  font-size: 4.5vw;
  margin: 0;
  line-height: 5vw;
}
#SocialMPos {
  position: relative;
  top: 0vw;
  width: 100%;
  height: 60vw;
  margin: 3vw 0;
  margin-top: 14vw;
}
#StatsPrdctDiv {
  position: relative;
  top: 0vw;
  width: 100%;
  height: 150vw;
  margin: 6vw 0;
}
#SwitchDivStatsBtn {
  position: absolute;
  top: 13vw;
  left: 8%;
  width: 42%;
  color: #b1b1b1;
  font-size: 4.2vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
#SwitchDivPassionProductsBtn {
  position: absolute;
  top: 13vw;
  left: 50%;
  width: 42%;
  color: #b1b1b1;
  font-size: 4.2vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
#StatsDiv {
  position: absolute;
  top: 21vw;
  left: 0vw;
  width: 100%;
  height: 150vw;
}
#NoneDisplay {
  display: none;
}
#AgencyStatsBtn {
  position: absolute;
  top: 13vw;
  left: 20%;
  width: 15%;
  padding: 0 6vw;
  font-size: 4vw;
  line-height: 7vw;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-weight: normal;
  margin: 0;
}
#MembersStatsBtn {
  position: absolute;
  top: 13vw;
  left: 52%;
  width: 24%;
  padding: 0 3vw;
  font-size: 4vw;
  line-height: 7vw;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-weight: normal;
  margin: 0;
}

#AgencyLineStatsPrdctBckg {
  position: absolute;
  top: 20vw;
  left: 8%;
}
#AgencyLineStatsSwitch {
  position: absolute;
  top: 19.8vw;
  left: 12%;
}
#AgencyLinePrdctSwitch {
  position: absolute;
  top: 19.8vw;
  left: 53%;
}
#PassionProductsDivAP {
  position: absolute;
  top: 21vw;
  left: 0vw;
  width: 100%;
  height: 150vw;
}

.BckgBtnWhiteAM {
  background-color: #ffffff;
  color: black;
}
#AgencyStatsDiv {
  position: absolute;
  top: 20vw;
  left: 0%;
  width: 100%;
  height: 165vw;
}
#AgencyGraphPos {
  position: absolute;
  top: 8vw;
  left: 0;
  width: 100%;
  height: 100vw;
}
#ADaysDiv {
  position: absolute;
  top: 100vw;
  left: 8%;
  width: 25%;
  height: 37vw;
  background-color: #363636;
  border-radius: 10px;
}
#ADaysDiv p:nth-child(1),
#MadeTWeekDiv p:nth-child(1),
#GrowthRateDiv p:nth-child(1) {
  position: absolute;
  top: 8vw;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #ffbf12;
  font-size: 7vw;
}
#ADaysDiv p:nth-child(2),
#MadeTWeekDiv p:nth-child(2),
#GrowthRateDiv p:nth-child(2) {
  position: absolute;
  top: 25vw;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: 3.3vw;
}
#MadeTWeekDiv {
  position: absolute;
  top: 100vw;
  left: 35.5%;
  width: 28%;
  height: 37vw;
  background-color: #363636;
  border-radius: 10px;
}
#GrowthRateDiv {
  position: absolute;
  top: 100vw;
  left: 66%;
  width: 25%;
  height: 37vw;
  background-color: #363636;
  border-radius: 10px;
}
#PassionProductsDiv {
  position: absolute;
  top: 15vw;
  left: 0vw;
  width: 100%;
  height: 150vw;
}
#UDSMPos {
  position: relative;
  left: 0%;
  width: 100%;
  margin: 10vw 0 0 0;
}
#PrdctPendingListDivAP {
  position: relative;
  left: 5vw;
  width: 90%;
  padding: 0 0 30vw 0;
  margin: 0;
}
#ClientsStatsDiv {
  position: absolute;
  top: 20vw;
  left: 0%;
  width: 100%;
  height: 242vw;
}
#AddSMIBtn {
  position: relative;
  top: 8vw;
  left: 20.5%;
  width: 24%;
  padding: 0 19vw;
  font-size: 5.5vw;
  line-height: 14vw;
  text-align: center;
  background-color: #ffffff;
  color: black;
  border-radius: 29px;
  font-weight: normal;
  margin: 0;
}
