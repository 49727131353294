#CommentDiv {
    position: relative;
    width: 100%;
    padding: 5vw 0vw 0 4vw;
    /* display: flex; */
    flex-direction: column;
    justify-content: flex-end;
    /* background-color: #212121; */
    align-content: flex-end;
    align-items: flex-start;
    overflow: hidden;
}

.CommentFrameC {
    position: relative;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-behavior: smooth;
}

.CommentFrameC::-webkit-scrollbar {
    display: none;
}

#CommentScrollContainerC {
    position: relative;
    display: inline-flex;
    flex-direction: row-reverse;
    top: 0;
    left: 0;
    width: 150%;
    overflow: hidden;
}

#ReplyCommentTxtScroll {
    position: relative;
    top: 0;
    left: 0;
    width: 33%;
    background: #24beff;
}

#ReplyCommentTxtScroll p {
    font-size: 4vw;
    position: relative;
    top: 27%;
    height: 40%;
    margin: 0;
    text-align: left;
    line-height: 9vw;
    padding: 0vw 0 0 8vw;
    width: 11vw;
}

#CommentElementC {
    position: relative;
    /* display: inline; */
    width: 67%;
    overflow: scroll;
}