.SaleComments {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.SaleComments__SaleDataContainer {
  position: relative;
  width: 100%;
  height: 51vw;
  margin-top: 23vw;
}

.SaleComments__Line1 {
  position: relative;
  left: 5vw;
  width: 90%;
  z-index: 9;
}

.SaleComments__CommentsFeed {
  position: relative;
  left: 5vw;
  width: 90%;
  padding: 1vw 2vw 6vw 0;
  background-color: #1f1f1f;
  z-index: 5;
  overflow-y: scroll;
  overflow-x: hidden;
}

.SaleComments__CommentsFeed::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.SaleComments__CommentsFeed {
  -ms-overflow-style: none;
}

#PaddingComment {
  margin: 2vw 0;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.CommentElement {
  position: relative;
  padding: 5vw 2vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background-color: #212121; */
  border-radius: 11px;
  align-content: flex-end;
  align-items: flex-start;
}

.replyComments {
  position: relative;
  padding: 5vw 2vw 5vw 8vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background-color: #212121; */
  border-radius: 11px;
  align-content: flex-end;
  align-items: flex-start;
}

#OwnCommentElement {
  position: relative;
  left: 3%;
  width: fit-content;
  padding-left: 2vw;
  padding-right: 2vw;
  /* margin: 2vw 0; */
  border-radius: 11px;
}

#UserNameOwnComment {
  position: relative;
  /* top: -2vw; */
  left: 2vw;
  text-align: end;
  display: inline;
  font-size: 3.3vw;
  line-height: 0;
  color: #ffbf12;
  font-weight: normal;
  margin: 0;
}

#UserNameComment {
  position: relative;
  /* top: 4vw; */
  /* right: 11vw; */
  /* text-align: end; */
  /* display: inline; */
  font-size: 3.3vw;
  line-height: 0;
  color: white;
  font-weight: bold;
  padding-right: 2vw;
  margin: 0;
}

#CommentTextElementC {
  position: relative;
  word-break: break-word;
  width: 85%;
  padding: 2vw 0vw 0 0vw;
  font-size: 3.3vw;
  text-overflow: ellipsis;
  font-weight: normal;
  line-height: 5vw;
  margin: 0 3vw 0 0;
}

#replyCommentsTextElement {
  position: relative;
  display: inline;
  width: 80%;
  /* background-color: #a19a9a; */
  /* padding: 2vw 3vw; */
  font-size: 3.3vw;
  /* text-align: end; */
  font-weight: normal;
  /* border-radius: 13px; */
  line-height: 0vw;
  margin: 0;
}

#CommentLikesAC {
  position: absolute;
  top: 1vw;
  right: 0;
  display: inline;
  padding: 0vw 2vw;
  margin: 0;
  /* float: right; */
}

#CommentLikesAC span {
  position: relative;
  top: -0.5vw;
  color: white;
  font-size: 3vw;
}

#LikeHurtAC {
  width: 3.5vw;
}

#UpdatedAtAC {
  color: white;
  font-size: 2.5vw;
  font-weight: normal;
  margin: 0;
}

#ViewReplyLineAC {
  position: relative;
  top: -1vw;
  padding-right: 1vw;
}

#ViewReplyBtnAC {
  color: white;
  font-size: 3.3vw;
  font-weight: normal;
  line-height: 0;
  margin: 0;
  padding: 2vw 0;
}

#ElementProfilePicAllCOwn {
  position: relative;
  display: inline-grid;
  top: 0vw;
  right: 3%;
  width: 8vw;
  height: 8vw;
  border-radius: 50px;
  background-position: 0px 0px;
  background-size: 8vw auto;
}

#ElementProfilePicAllC {
  position: relative;
  display: inline-grid;
  top: 0vw;
  right: 0%;
  width: 8vw;
  height: 8vw;
  border-radius: 50px;
  background-position: 0px 0px;
  background-size: 8vw auto;
}

/* #AddCommentDivAC{
    position: absolute;
    bottom: 4vw;
    left: 5vw;
    width: 90%;
    height: 30vw;
    background-color: #707070;
    border-radius: 20px;
} */
/* #ProfileAvatarAC{
    position: absolute;
    top: 25vw;
    left: 2%;
    width: 8vw;
    height: 8vw;
    border-radius: 50px;
    background-position: 0px 0px;
    background-size: 8vw auto;
} */
.SaleComments__InputContainer {
  position: fixed;
  bottom: 4.5vh;
  left: 5vw;
  width: 90vw;
  min-height: 12vw;
  z-index: 6666;
}

#CommentRepliedAC {
  position: absolute;
  bottom: 15vw;
  left: 0;
  width: 90vw;
  /* background: #363636; */
  z-index: 4;
}

#CommentRepliedAC p {
  margin: 0;
  font-size: 4vw;
  border: 0.45vw solid white;
  border-radius: 10vw;
  background: #363636;
  padding: 0 4vw;
}

.SaleComments__Input--small {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  border-top: 1px solid #707070;
  border-radius: 0;
  height: 12vw;
  font-size: 4vw;
  font-weight: normal;
  line-height: 9vw;
  padding: 2vw 13.5vw 0;
  padding-left: 14vw;
  background-color: #1f1f1f;
}

.SaleComments__Input--small::placeholder,
.SaleComments__Input--large::placeholder {
  color: white;
  font-weight: normal;
  font-size: 4vw;
}

.SaleComments__Input--large {
  position: fixed;
  bottom: 4vw;
  left: 5vw;
  width: 1000%;
  background-color: #707070;
  border-radius: 20px;
}

#SendBtnAllC {
  position: absolute;
  bottom: 2vh;
  right: -14vw;
  width: 7vw;
}

.SaleComments__ProfilePic {
  position: absolute;
  /* display: inline; */
  bottom: 1vh;
  left: 3vw;
  width: 8vw;
  height: 8vw;
  /* margin-bottom: 0vw; */
  border-radius: 50px;
  background-position: 0px 0px;
  background-size: 8vw auto;
  z-index: 44;
}
