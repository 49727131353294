.AddingBankBen__BackBtn {
  position: absolute;
  top: 20vw;
  left: 0%;
  width: 13vw;
  z-index: 35;
}
.AddingBankBen__PleaseTxt {
  margin: 9vh 0 0;
  font-size: 6vw;
  color: #707070;
  line-height: 8vw;
}

.AddingBankBen__BankInput {
  width: 84vw;
  height: 10vw;
  border: none;
  line-height: 10vw;
  background-color: white;
  border: none;
  display: block;
  font-size: 4.25vw;
  font-family: "open-sans";
  font-weight: bold;
  line-height: 10vw;
  padding: 0vw 3vw;
  border-radius: 10vw;
  border: 0;
  margin: 4vw auto 0 auto;
}
select.AddingBankBen__BankInput {
  color: black ;
  font-size: 4.25vw;
}
select.AddingBankBen__BankInput option[disabled]:first-child {
  display: none;
  color: #b7b7b7 !important;
}


.AddingBankBen__AccountNameInput {
  width: 84vw;
  font-size: 4.5vw;
  line-height: 10vw;
  background-color: white;
  border: none;
}
.AddingBankBen__AccountNumberInput {
  width: 84vw;
  font-size: 4.5vw;
  line-height: 10vw;
  background-color: white;
  border: none;
}
.AddingBankBen__BranchCodeInput {
  width: 84vw;
  font-size: 4.5vw;
  line-height: 10vw;
  background-color: white;
  border: none;
}
.AddingBankBen__AccountNameInput::placeholder,.AddingBankBen__BranchCodeInput::placeholder,.AddingBankBen__AccountNumberInput::placeholder{
    color: black;
}
.AddingBankBen__NextBtn {
  width: fit-content;
  font-size: 4vw;
  line-height: 10vw;
  margin: 0 auto;
  padding: 0 18vw;
  background-color: #ffbf12;
  border-radius: 10vw;
}
