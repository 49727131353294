.MyBrand {
  top: 15vw;
}
.MyBrand * {
  margin: 0 auto;
  text-align: center;
}
.MyBrand__Headline {
  font-size: 10.5vw;
  text-align: center;
  margin: 4vw auto 8vw;
}

.PositiveNum {
  color: #ffbf12;
}

.NegativeNum {
  color: #e66100;
}

.PositiveGreenNum {
  color: #34c100;
}