.WalletTransactions {
    position: relative;
    width: 90vw;
    color: #fff;
    margin: 0 auto;
    transition: all 0.5s;
  }
  .WalletTransactions__HeaderContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
  .WalletTransactions__Title {
    font-size: 7vw;
    margin: 2vw 0;
    line-height: 0;
  }
  
  .WalletTransactions__TitleLine {
  }
  
  #MonthSelectListStS {
    position: absolute;
    top: 0vw;
    right: 0vw;
    -webkit-appearance: none;
    appearance: none;
    background-color: black;
    text-align: center;
    color: white;
    padding: 0 3vw;
    font-size: 3.35vw;
    line-height: 6vw;
    border: 0.15vw solid white;
    border-radius: 10vw;
  }
  .WalletTransactions__Table {
    width: 90vw;
    font-size: 3.25vw;
    line-height: 5vw;
}
.WalletTransactions__Table td{
    padding: 2vw 1vw;
}
.WalletTransactions__Table th{
    text-align: left;
    vertical-align: top;
}
.WalletTransactions__Table tr{
    vertical-align: top;
}

  #NoTrnsStS {
    font-size: 4.5vw;
    line-height: 6vw;
    margin: 8vw 0 0 5vw;
    text-align: left;
    font-weight: normal;
    color: #ffbf12;
  }
  
  #ColumnsW {
    position: absolute;
    top: 16vw;
    left: 0%;
    width: 100%;
    text-transform: none;
    color: #9c9c9c;
  }
  
  #ColumnsW span:nth-child(1) {
    position: absolute;
    top: 0vw;
    left: 0%;
    font-size: 3.5vw;
  }
  
  #ColumnsW span:nth-child(2) {
    position: absolute;
    top: 0vw;
    left: 12.5%;
    font-size: 3.5vw;
  }
  
  #ColumnsW span:nth-child(3) {
    position: absolute;
    top: 0vw;
    left: 39.5%;
    font-size: 3.5vw;
  }
  
  #ColumnsW span:nth-child(4) {
    position: absolute;
    top: 0vw;
    right: 2%;
    font-size: 3.5vw;
  }
  
  #ColumnsW span:nth-child(5) {
    position: absolute;
    top: 0vw;
    left: 80%;
    font-size: 3.5vw;
  }
  
  #TransListLine1 {
    position: absolute;
    top: 24.5vw;
    left: 0%;
  }
  
  #TransListLine2 {
    position: absolute;
    top: 111.5vw;
    left: 0%;
    box-shadow: 0 0 14vw 11vw black;
  }
  
  #TransactionsListW {
    position: relative;
    top: 16vw;
    left: 0;
    width: 100%;
    /* height: 100vw; */
    margin: 0 0 20vw 0;
  }
  
  #TransactionsListW>ul {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    font-size: 3.8vw;
    padding: 0;
    height: 77vw;
    overflow: scroll;
  }
  
  #TransactionsListW>ul::-webkit-scrollbar {
    width: 0 !important;
  }
  
  #TransactionsListW li {
    list-style-type: none;
    width: 100%;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    padding: 4vw 0;
    white-space: normal !important;
    text-align: left !important;
  }
  
  #TransactionsListW span:nth-child(1) {
    position: relative;
    left: 0vw;
    top: -0.9vw;
    font-size: 3.8vw;
    line-height: 5vw;
    padding: 2vw 0;
  }
  
  #TransactionsListW span:nth-child(2) {
    position: absolute;
    left: 10.2vw;
    width: 3vw;
    line-height: 5vw;
    margin: auto;
    padding: 2vw 0;
  }
  
  #TransactionsListW span:nth-child(3) {
    position: absolute;
    width: 43%;
    text-align: left;
    left: 35vw;
    line-height: 5vw;
    padding: 2vw 0;
  }
  
  #TransactionsListW span:nth-child(4) {
    position: absolute;
    right: 0;
    width: 25%;
    text-align: right;
    font-size: 3.8vw;
    line-height: 5vw;
    padding: 2vw 0;
  }
  
  /* #TransactionsListW span:nth-child(5){
      position: absolute;
      left: 6vw;
      width: 25%;
      text-align: right;
  } */
  #TransactionsListW i {
    font-weight: normal;
    font-style: normal;
    font-size: 2.5vw;
  }
  
  .PositiveNum {
    color: #ffbf12;
  }
  
  .NegativeNum {
    color: #e66100;
  }
  
  .PositiveGreenNum {
    color: #34c100;
  }