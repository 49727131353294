.ConfirmRegister{
  justify-content:space-between;
  /* height: fit-content!important; */
}
.ConfirmRegister__ConfirmTxt {
  width: 100%;
  text-align: center;
  font-size: 4vw;
  line-height: 6vw;
  margin: 0 0 6vw;
}
.ConfirmRegister__Line {
  display: block;
  margin: 0 auto;
}
.ConfirmRegister__input-container {
  width: fit-content;
  margin: 17vw auto 0;
}
/* #InputCodeDivSC {
  position: absolute;
  top: 78vw;
  left: 7.5%;
  width: 85%;
} */
.ConfirmRegister__InputDigit {
  width: 19vw;
  height: 26vw;
  margin: 0 1.5vw;
  border: none;
  font-size: 14.5vw;
  text-align: center;
  line-height: 23vw;
  border-radius: 2.5vw;
  color: #ffbf12;
  padding: 0;
}

#dig3SC {
  /* position: absolute;
    top: 78vw;
    left:28.3%; */
  width: 20%;
  height: 25vw;
  font-size: 15vw;
  line-height: 17vw;
  border-radius: 15px;
  border: none;
  text-align: center;
}
#dig2SC {
  /* position: absolute;
    top: 78vw;
    left:51.6%; */
  width: 20%;
  height: 25vw;
  font-size: 15vw;
  line-height: 17vw;
  border-radius: 15px;
  border: none;
  text-align: center;
}
#dig1SC {
  /* position: absolute;
    top: 78vw;
    left:74.9%; */
  width: 20%;
  height: 25vw;
  font-size: 15vw;
  line-height: 17vw;
  border-radius: 15px;
  border: none;
  text-align: center;
}

.ConfirmRegister__ResendBtn {
    font-size: 3.5vw;
    line-height: 4vw;
    margin: 12vw auto 0;
    width: fit-content;
}

.ConfirmRegister__NextBtn {
    position: absolute;
    top: 7vh;
    right: 0;
    background-color: white;
    border-bottom-left-radius: 10vw;
    border-top-left-radius: 10vw;
    border: 0;
    color: #ffbf12;
    font-size: 4vw;
    line-height: 12vw;
    margin: 0;
    padding: 0 4vw 0 7vw;
}
#ResendMessageSC {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #1f1f1f;
}
#ResendMessageTextSC {
  color: #ffbf12;
  font-size: 5vw;
  line-height: 5vw;
  padding: 7vw 0;
  width: 100%;
  text-align: center;
}
