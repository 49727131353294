.StockDataMetrix {
    width: 76vw;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5vh auto;
}
.StockDataMetrix__Item {
    display: flex;
    width: 20vw;
    height: 20vw;
    background: #2e2e2e;
    border-radius: 3vw;
    margin-bottom: 4vw;
    flex-direction: column;
    justify-content: center;
}
.StockDataMetrix__Item p {
  font-size: 3.5vw;
  line-height: 5vw;
  margin: 0;
  text-align: center;
}
.StockDataMetrix__Item span {
    font-size: 4.25vw;
    color: #ffbf12;
    margin: 0;
    line-height: 5vw;
    text-align: center;
}
