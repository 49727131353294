#GraphUserDataDiv {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 90vw; */
}
#GraphUserData3rdPDiv {
  position: relative;
  top: 0vw;
  left: 0;
  width: 100%;
  height: 87vw;
}
#HideGUD {
  display: none;
}
#UserGBalance {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 8vw;
  line-height: 8vw;
  margin: 0;
}
#UserGHour {
  position: absolute;
  top: 10vw;
  right: 55%;
  font-size: 5vw;
  line-height: 5vw;
  margin: 0;
  font-weight: normal;
}
#UserGChangePrft {
  position: absolute;
  top: 10vw;
  left: 52%;
  font-size: 5vw;
  line-height: 5vw;
  margin: 0;
  font-weight: normal;
}
#GraphUserAxis {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vw;
}
.SelectedGU {
  border: 0.45vw solid white;
  border-radius: 15px;
  padding: 0 3vw;
}
.RangeBtns {
  
    display:flex;
    justify-content: space-around;
    width: 70vw;
    margin: 0 auto;
}
.RangeBtn{
  font-size: 3.5vw;
  line-height: 6.5vw;
  padding: 0 3vw;
  margin: 0;
  z-index: 2;
  font-weight: normal;
  color: #b8b8b8;
}
#OneDayBtn {
  position: absolute;
  left: 20vw;
  font-size: 3.5vw;
  line-height: 6.5vw;
  padding: 0 3vw;
  margin: 0;
  z-index: 2;
  font-weight: normal;
  color: #b8b8b8;
}
.ChoosenBtnChart {
  border: 1px solid #ffffff;
  border-radius: 10px;
}
#OneMonthBtn {
  position: absolute;
  left: 36vw;
  font-size: 3.5vw;
  line-height: 6.5vw;
  padding: 0 3vw;
  margin: 0;
  z-index: 2;
  font-weight: normal;
  color: #b8b8b8;
}
#SixMonthBtn {
  position: absolute;
  left: 53vw;
  font-size: 3.5vw;
  line-height: 6.5vw;
  padding: 0 3vw;
  margin: 0;
  z-index: 2;
  font-weight: normal;
  color: #b8b8b8;
}
#MaxBtn {
  position: absolute;
  left: 71vw;
  font-size: 3.5vw;
  line-height: 6.5vw;
  padding: 0 3vw;
  margin: 0;
  z-index: 2;
  font-weight: normal;
  color: #b8b8b8;
}
#ChartDiv {
  position: absolute;
  left: 0vw;
  width: 100%;
  height: 65vw;
}
