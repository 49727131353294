#SMIValidationDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}
#SMIValidationPic {
    position: absolute;
    top: 8vw;
    left: 32%;
    width: 36%;
}
#SMIValidationTxt{
    position: absolute;
    top: 47vw;
    left: 0%;
    width: 100%;
    margin: 0;
    font-size: 10vw;
    line-height: 12vw;
    text-align: center;
    text-transform: initial;
}
#SMIValidationExpTxt{
    position: absolute;
    top: 73vw;
    left: 8%;
    margin: 0;
    font-size: 4.2vw;
    line-height: 6vw;
}
#CalendlyEmbeded {
    position: absolute!important;
    top: 121vw;
    left: 8vw;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    width: 84vw;
    height: 84vw;
}
* *>.YDdDY_j6avih9XaXQYtw.__fvysRsoB25K1_TIMEq:not(.a81ONUS_TPZ7lryTAzMM).aZ0j3tjAsYOwrtiz99dC {
    min-width: 84vw !important;
    max-width: 84vw !important;
}
body>.YDdDY_j6avih9XaXQYtw.aZ0j3tjAsYOwrtiz99dC {
   
    max-width: 84vw !important;
   
}

#BookMeetingBtn {
    position: absolute;
    top: 139vw;
    left: 8%;
    width: 84vw;
    line-height: 9vw;
    font-size: 4.5vw;
    border: 3px solid #902366;
    border-radius: 12px;
    color: black;
    background-color: white;
    text-align: center;
    z-index: 90;
}