.ShowMeTheMoney{
    padding-top: 9vw;
}
.ShowMeTheMoney__StatsShowMoney {
    width: 100%;
    font-size: 15vw;
    line-height: 9vw;
    text-align: center;
    margin: 0;
  }
  .ShowMeTheMoney__StatsShowMoney span {
    color: #ffbf12;
  }
.ShowMeTheMoney__YouTxt {
    font-size: 3.55vw;
    line-height: 6vw;
    font-weight: lighter;
    text-align: center;
    margin: 13vw 5vw 1vw;
}
.ShowMeTheMoney__ToTxt {
    font-size: 3.25vw;
    line-height: 4vw;
    margin: 0 auto;
    text-align: center;
    color: #909090;
}
