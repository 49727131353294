.About__Img {
  width: 100%;
}
.About__Headline1 {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 13vw;
  line-height: 23vw;
  margin: -19vw 0 0;
  z-index: 2;
  color: #fff;
}
.About__Headline2 {
  position: relative;
  line-height: 45px;
  text-align: center;
  font-size: 32px;
  margin: 0 0 19vw;
  text-shadow: 0 0 0.1vw #fff, 0 0 0.1vw #fff, 0 0 3.1vw #ffbf12,
    0 0 3.1vw #ffbf12;
  z-index: 2;
}
.About__Shadow {
  position: relative;
  margin-top: -48vw;
  width: 100%;
  height: 38vw;
  background-color: #121212;
  box-shadow: 0 -7vw 8vw 8vw #121212;
  z-index: 1;
}
.About__Line {
  display: block;
  margin: 10vw auto 0;
  border-radius: 25px;
  box-shadow: 0 0 2vw 0.5vw #24beff;
}

.About__Circle1 {
  position: absolute;
  top: 8px;
  left: 0%;
  width: 36%;
}
.About__Paragraph1 {
  position: relative;
  font-weight: lighter;
  font-size: 15px;
  line-height: 20px;
  margin: 30px 5vw 0;
  text-align: center;
  z-index: 2;
}
.About__Rect {
  position: absolute;
  top: 350px;
  right: -25px;
  width: 36%;
}
.About__Paragraph2 {
  position: relative;
  font-weight: lighter;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  margin: 13vw 5vw 10vw;
  z-index: 2;
}
.About__Circle2 {
  position: absolute;
  bottom: -80px;
  right: -5%;
  width: 38%;
}

.About__Paragraph3 {
  text-align: center;
  font-weight: lighter;
  font-size: 15px;
  line-height: 20px;
  margin: 0 5vw;
  z-index: 2;
}
.About__LearnBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin: 10vw 5vw;
}
.About__LearnBtn {
  font-size: 4.5vw;
  line-height: 5vw;
  color: #cecaca;
  margin: auto 3vw;
}
.About__LearnBtnArrow {
  width: 8.5vw;
}

.About__WeBelieve {
  margin: 0 2vw 10vw;
  width: 96vw;
}

#FullnameInputBAA {
  position: absolute;
  top: 184vw;
  left: 8%;
  width: 76vw;
  line-height: 9vw;
  font-size: 4vw;
  border: none;
  border-radius: 9px;
  padding: 0 4vw;
  color: #363636;
}
#EmailInputBAA {
  position: absolute;
  top: 195vw;
  left: 8%;
  width: 76vw;
  line-height: 9vw;
  font-size: 4vw;
  border: none;
  border-radius: 9px;
  padding: 0 4vw;
  color: #363636;
}
#PhoneNumberInputBAA {
  position: absolute;
  top: 206vw;
  left: 8%;
  width: 76vw;
  line-height: 9vw;
  font-size: 4vw;
  border: none;
  border-radius: 9px;
  padding: 0 4vw;
  color: #363636;
}
#FullnameInputBAA::placeholder,
#EmailInputBAA::placeholder,
#PhoneNumberInputBAA::placeholder {
  font-size: 3.5vw;
  color: #363636;
}

#SpeakSoonTxtBAA {
  position: absolute;
  top: 233vw;
  left: 0%;
  width: 100%;
  text-align: center;
  line-height: 9vw;
  font-size: 8vw;
  margin: 0;
}

#RegisterBtnBAA {
  position: absolute;
  top: 253vw;
  left: 24%;
  width: 53.5vw;
  line-height: 9vw;
  font-size: 4.5vw;
  margin: 0;
  background-color: #ffbf12;
  border-radius: 11px;
  border: none;
}
#LoaderPosSizeBAA {
  position: absolute;
  top: 268vw;
  left: 48%;
  width: 4vw;
  height: 4vw;
}

#SuccesedMessageBAA {
  position: fixed;
  bottom: 5vw;
  left: 5vw;
  width: 90vw;
  height: 56vw;
  background-color: #363636;
  border-radius: 25px;
  z-index: 3;
}
#SuccesedMessageBAA p {
  position: absolute;
  top: 7vw;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 4.5vw;
  line-height: 6.5vw;
  margin: 0;
}
#SuccesedMessageBAA span {
  color: #ffbf12;
  font-size: 5vw;
}
#SuccesedMessageBAA button {
  position: absolute;
  top: 40vw;
  left: 25vw;
  width: 40vw;
  line-height: 9vw;
  font-size: 4.5vw;
  background-color: #ffbf12;
  border: none;
  border-radius: 25px;
}
