.BeneficiarySelection__BackBtn {
  position: absolute;
  top: 20vw;
  left: 0%;
  width: 13vw;
  z-index: 35;
}
.BeneficiarySelection__WithdrawToTxt {
  font-size: 8vw;
  margin: 34vw 0 19vw;
  line-height: 8vw;
  color: #707070;
}
.BeneficiarySelection__BanksList {
  width: 84vw;
  margin-bottom: 25vh;
}
.BeneficiarySelection__BanksListElements {
  position: relative;
  top: 0vw;
  left: 0%;
  width: 100%;
}
.BeneficiarySelection__BankBenDiv {
  position: relative;
  margin: 4vw 0;
  width: 100%;
}
.BeneficiarySelection__BankItem {
  position: relative;
  margin: 0vw 0;
  width: 84vw;
  background-color: white;
  border-radius: 25px;
  line-height: 12vw;
  font-size: 4vw;
  color: #2e2e2e;
  text-align: center;
}
.BeneficiarySelection__DeleteBankBen {
  position: absolute;
  top: 2.5vw;
  right: 5vw;
  color: #363636;
  display: inline;
  z-index: 5;
  margin: 0;
  font-weight: normal;
  font-size: 6vw;
  line-height: 6vw;
}
.BeneficiarySelection__AddBBBtn {
  position: relative;
  top: 0;
  left: 0%;
  width: 100%;
  font-size: 4.5vw;
  margin: 7vw 0px;
  line-height: 12vw;
  color: #707070;
  background-color: #ffffff;
  border-radius: 25px;
  text-align: center;
}
.BeneficiarySelection__AddIcon {
  position: absolute;
  top: 2.2vw;
  left: 3%;
  width: 9%;
}
