.FPCheckEmail__Emoji {
  display: block;
  width: 18vw;
  margin: 24vw auto 0 auto;
}
.FPCheckEmail__ClickTxt {
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  font-size: 6.35vw;
  line-height: 8vw;
  margin: 18vw auto 2vw auto;
}
/* #EnterEmailCE {
    position: absolute;
    top: 103vw;
    left: 9vw;
    width: 74vw;
    border: none;
    border-radius: 25px;
    padding: 0 4vw;
    line-height: 10.5vw;
    font-size: 4.5vw;
}
#EnterEmailCE::placeholder{
    font-size: 4.5vw;
    color: grey;
    font-family: sans-serif;
} */
.FPCheckEmail__ResendBtn {
  width: fit-content;
  margin: 0 auto;
  font-size: 4vw;
  color: #ffbf12;
}
.FPCheckEmail__ResendBtn--gray {
  color: #707070;
}
.FPCheckEmail__indicate-container {
  height: 12vw;
}
.FPCheckEmail__Line {
  display: block;
  margin: 0 auto;
}
.FPCheckEmail__ConfirnBtn {
  display: block;
  margin: 9vw auto 0 auto;
  width: 13vw;
}
