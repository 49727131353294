#AgencyMemberTagContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#AgencyAvatarImage {
    position: absolute;
    top: 0%;
    left: 2%;
    width: 13vw;
    height: 13vw;
    border-radius: 50px;
    background-position: 0px 0px;
    background-size: 13vw auto;
}
#AgencyNameTxt {
    position: absolute;
    top: 60%;
    left: 7vw;
    width: -moz-fit-content;
    /* width: fit-content; */
    font-size: 46%;
    line-height: 151%;
    /* padding: 0 4%; */
    padding-left: 9vw;
    padding-right: 4vw;
    text-align: left;
    color: black;
    background-color: white;
    border-radius: 9px;
    margin: 0;
}
#StarsPosition {
    display: inline;
    position: absolute;
    top: -16%;
    left: 18%;
    width: 26vw;
    height: 5vw;
}
.AgencyTagRateStars{
    display: inline;
    width: 15%;
    padding: 2%;
}
