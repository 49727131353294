#root::-webkit-scrollbar {
  width: 0 !important;
}
*::-webkit-scrollbar{
  width: 0;
}

.WalletSlider__Line {
  position: relative;
  display: block;
  left: 0%;
  margin:0 auto ;
}

#ImgGalleryPos {
  position: relative;
  top: 0vw;
  left: 0;
  width: 100%;
  z-index: 15;
}

#ImgGalleryPos::-webkit-scrollbar {
  width: 0 !important;
}

.image-gallery *::-webkit-scrollbar {
  width: 0 !important;
}

#MainSlide {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

#MainSlide::-webkit-scrollbar {
  width: 0 !important;
}

#MainSlideBrekDOpen {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

#MainSlideBrekDOpen::-webkit-scrollbar {
  width: 0 !important;
}

#ChartGraphPos {
  position: relative;
  margin: 2vw 0 0 0;
  left: 0;
  width: 100%;
  color: black;
  line-height: 2vw;
  font-size: 2vw;
}

#ChartGraphPosS {
  width: 100%;
  color: black;
  line-height: 2vw;
  font-size: 2vw;
}

/* .apexcharts-tooltip * {
    font-family: inherit;
    margin: 0;
    line-height: 2vw;
} */

.StockInfoAllSlide {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 440vw;
  background: black;
}

#StockInfoCommon {
  position: relative;
  margin: 0 5vw;
  width: 90%;
  height: 70vw;
  background-color: #2c2c2c;
  border-radius: 26px;
  z-index: 4;
}



#StocksRIPOTxt {
  position: absolute;
  top: 46.3vw;
  left: 47%;
  font-size: 6vw;
  line-height: 8vw;
  margin: 0;
  text-align: left;
}

#StocksRTxt {
  position: relative;
  top: 0vw;
  left: 48%;
  font-size: 6vw;
  line-height: 8vw;
  margin: 0;
  text-align: left;
}

#StockArrowDown {
  position: absolute;
  top: 65vw;
  left: 45%;
  width: 8vw;
  z-index: 7;
}

#DrowpdownStockData {
  margin: 8vw 5vw;
  width: 90%;
  z-index: 5;
}

#SDDHide {
  display: none;
}


#StockStatsData {
  position: relative;
  width: 100%;
  height: 55vw;
  margin: -1.5vw 0 6vw 0;
}

#StockSectorDiv {
  position: absolute;
  top: 0vw;
  left: 1%;
  background-color: #363636;
  border-radius: 10px;
  width: 28vw;
  height: 51.5vw;
}

#SSectorPercent {
  position: absolute;
  top: 4vw;
  left: 5%;
  font-size: 8vw;
  color: #ffbf12;
  line-height: 20vw;
  border: 2.5vw solid #ffbf12;
  border-radius: 50px;
  width: 20vw;
  margin: 0;
}

#SSectorHashtag {
  position: absolute;
  top: 37vw;
  left: 0%;
  width: 100%;
  text-align: center;
  font-size: 4vw;
  line-height: 4vw;
  margin: 0;
}

#StockAverageROIDiv {
  position: absolute;
  top: 0vw;
  left: 34.5%;
  background-color: #363636;
  border-radius: 10px;
  width: 28vw;
  height: 25vw;
}

#SAverageTxt {
  position: absolute;
  top: 0.5vw;
  left: 6%;
  width: 100%;
  text-align: left;
  font-size: 4vw;
  line-height: 7.8vw;
  margin: 0;
}

#SAPercent {
  position: absolute;
  top: 8vw;
  left: 4%;
  font-size: 5vw;
  color: #ffbf12;
  line-height: 8vw;
  border-radius: 50px;
  margin: 0;
}

#StockHighestPDiv {
  position: absolute;
  top: 26.75vw;
  left: 34.5%;
  background-color: #363636;
  border-radius: 10px;
  width: 28vw;
  height: 25vw;
}

#HighestStkPriceTxt {
  position: absolute;
  top: 2vw;
  left: 5%;
  width: 100%;
  text-align: left;
  font-size: 4vw;
  line-height: 5vw;
  margin: 0;
}

#HSPrcnt {
  position: absolute;
  top: 14vw;
  left: 5%;
  font-size: 5vw;
  color: #ffbf12;
  line-height: 8vw;
  border-radius: 50px;
  margin: 0;
}

#GreenRedData {
  position: absolute;
  top: 4vw;
  right: 1.5%;
  font-size: 3.25vw;
  color: #34c100;
  line-height: 2vw;
  border-radius: 50px;
  margin: 0;
  font-weight: normal;
}

#SGrowthDiv {
  position: absolute;
  top: 9vw;
  right: 1%;
  background-color: #363636;
  border-radius: 10px;
  width: 28vw;
  height: 43vw;
}

#SGrowthTxt {
  position: absolute;
  top: 0vw;
  left: 8%;
  width: 100%;
  text-align: left;
  font-size: 4vw;
  line-height: 14.25vw;
  margin: 0;
}

#GrowthPrcnt {
  position: absolute;
  top: 17vw;
  left: 7%;
  font-size: 6.5vw;
  color: #ffbf12;
  line-height: 8vw;
  border-radius: 50px;
  margin: 0;
}

#TradeInPPBtn {
  position: relative;
  width: fit-content;
  font-size: 4.3vw;
  color: #ffbf12;
  line-height: 10vw;
  border-radius: 50px;
  background-color: white;
  padding: 0 4vw;
  margin: 10vw auto;
}

#TradeHistoryTitle {
  position: relative;
  font-size: 9.5vw;
  line-height: 10vw;
  text-align: left;
  margin: 11vw 0 0 0;
}

#TradeHTitleLine {
  position: relative;
  display: block;
  left: 0%;
  margin: 5vw 0;
}

#THColumns {
  position: relative;
  display: block;
  width: 100%;
  height: 10vw;
  font-size: 4vw;
  margin: 2vw 0;
  text-transform: none;
  color: #707070;
}

#THColumns span:nth-child(1) {
  position: absolute;
  top: 0vw;
  left: 0%;
}

#THColumns span:nth-child(2) {
  position: absolute;
  top: 0vw;
  left: 18%;
}

#THColumns span:nth-child(3) {
  position: absolute;
  top: 2vw;
  left: 42%;
  line-height: 4vw;
  text-align: left;
}

#THColumns span:nth-child(4) {
  position: absolute;
  top: 0vw;
  left: 67%;
}

#THColumns span:nth-child(5) {
  position: absolute;
  top: 0vw;
  left: 86.5%;
}

#TradeHListLine {
  position: relative;
  display: block;
  left: 0%;
}

#TradeHistoryList {
  position: relative;
  left: 0vw;
  width: 100%;
  height: 60vw;
}

#TradeHistoryList>ul {
  position: relative;
  top: 0vw;
  left: 0%;
  width: 100%;
  font-size: 4vw;
  padding: 0;
  margin: 0;
  height: 60vw;
  overflow: scroll;
}

#TradeHistoryList li {
  position: relative;
  list-style-type: none;
  width: 100%;
}

#TradeHistoryList span:nth-child(1) {
  position: relative;
  left: -45%;
}

#TradeHistoryList span:nth-child(2) {
  position: absolute;
  left: 15vw;
}

#TradeHistoryList span:nth-child(3) {
  position: absolute;
  width: 24%;
  text-align: left;
  left: 36vw;
}

#TradeHistoryList span:nth-child(4) {
  position: absolute;
  left: 58vw;
  width: 23%;
  text-align: left;
}

#TradeHistoryList span:nth-child(5) {
  position: absolute;
  left: 77vw;
  width: 8%;
  text-align: left;
}

#TotalTradeH {
  position: relative;
  left: 0;
  width: 100%;
  padding-bottom: 2vh;
}

#DaysOwnedDiv {
  position: absolute;
  top: 0;
  left: -0.5vw;
  width: 15%;
  height: 22vw;
}

#StockHoldingDiv {
  position: absolute;
  top: 0;
  left: 17%;
  width: 19%;
  height: 22vw;
}

#CurrentPriceDiv {
  position: absolute;
  top: 0;
  left: 38%;
  width: 21.85%;
  height: 22vw;
}

#TotalSpendDiv {
  position: absolute;
  top: 0;
  left: 61.5%;
  width: 21%;
  height: 22vw;
}

#TotalROIMadeDiv {
  position: absolute;
  top: 0;
  left: 84%;
  width: 17%;
  height: 22vw;
}

.STTotal p:nth-child(1) {
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  font-size: 4vw;
  line-height: 7vw;
  /* padding: 0 2vw; */
  border: 1px solid white;
  border-radius: 10px;
  width: 100%;
}

.STTotal p:nth-child(2) {
  position: relative;
  top: 2vw;
  margin: 0 auto;
  padding: 0 0 0 1vw;
  font-size: 3.5vw;
  line-height: 4vw;
  text-align: left;
  color: #707070;
  font-weight: 800;
}

#WalletStatisticsMS {
  position: relative;
  margin: 2vw 0;
  left: 0%;
  width: 100%;
  height: 123vw;
}

#WalletStatisticsMS * {
  margin: 0;
  color: #121212;
}

#WalletStatisticsMS h2 {
  position: absolute;
  left: 3vw;
  top: 5vw;
  font-size: 4.8vw;
  line-height: 5vw;
}

#WalletStatisticsMS h3 {
  position: absolute;
  left: 3vw;
  top: 12vw;
  font-size: 4vw;
  line-height: 5vw;
  font-weight: normal;
}

#WalletStatisticsMS p {
  position: absolute;
  left: 3vw;
  top: 26vw;
  font-size: 12vw;
  line-height: 5vw;
}

#WalletStatisticsMS i {
  position: absolute;
  left: 2vw;
  top: 44vw;
  font-size: 4.5vw;
  line-height: 5vw;
}

* {
  -webkit-font-smoothing: antialiased;
}

#StockHolding {
  position: absolute;
  top: 5vw;
  left: 8%;
  width: 40%;
  height: 42vw;
  text-align: left;
  background-color: #e8e8e8;
  border-radius: 3.5vw;
}

#Sector {
  position: absolute;
  top: 51vw;
  left: 8%;
  width: 40%;
  height: 63vw;
  text-align: left;
  background-color: #e8e8e8;
  border-radius: 15px;
}

#blurCurrentlySector {
  position: absolute;
  top: 11.8vw;
  left: 0%;
  width: 100%;
  height: 51vw;
  opacity: 0.92;
  z-index: 4;
}

#CurrentlySectorTxt {
  position: absolute;
  top: 15.8vw;
  left: 8%;
  color: black;
  font-size: 5.5vw;
  line-height: 7.5vw;
  margin: 0;
  z-index: 5;
}

#SectorC {
  position: absolute !important;
  top: 19vw !important;
  left: 5vw !important;
  border: 9px solid #707070;
  border-radius: 66px;
  font-size: 10vw !important;
  line-height: 25vw !important;
  padding: 0 2vw;
}

#HalfC {
  position: absolute;
  top: 19.5vw;
  left: 39%;
  width: 21vw;
  height: 32.3vw;
}

#OfYourTxt {
  position: absolute;
  left: 2vw;
  top: 54vw;
  font-size: 4.5vw;
  line-height: 5vw;
}

#Return {
  position: absolute;
  top: 5vw;
  left: 52%;
  width: 40%;
  height: 52.5vw;
  text-align: left;
  background-color: #e8e8e8;
  border-radius: 15px;
}

#ReturnArUpStS {
  position: relative;
  width: 4vw;
}

#ReturnArDownStS {
  position: relative;
  width: 4vw;
}

#RePer {
  position: absolute !important;
  left: 3vw !important;
  top: 31vw !important;
  font-size: 12vw;
  line-height: 5vw !important;
}

#Dividends {
  position: absolute;
  top: 62vw;
  left: 51.5%;
  width: 40%;
  height: 52.5vw;
  text-align: left;
  background-color: #e8e8e8;
  border-radius: 15px;
}

#blurCurrentlyDividends {
  position: absolute;
  top: 12.3vw;
  left: 0%;
  width: 100%;
  height: 40vw;
  z-index: 4;
}

#CurrentlyDividendsTxt {
  position: absolute;
  top: 10.8vw;
  left: 8%;
  color: black;
  font-size: 5.5vw;
  line-height: 7.5vw;
  margin: 0;
  z-index: 5;
}

#WalletBWrapMS {
  position: relative;
  left: 5%;
  width: 90%;
  margin: 0;
}

#WalletBWrapMS p {
  margin: 0;
  line-height: 5vw;
}

#WalletBTitle {
  position: relative;
  top: 0vw;
  left: 0%;
  width: 100%;
  height: 33vw;
  background-color: #1f1f1f;
  border-radius: 28px;
  text-align: left;
  box-shadow: 0px 14px 9px -6px #121212;
  z-index: 4;
}

#WalletBText {
  position: absolute;
  top: 16.5vw;
  left: 5%;
  z-index: 5;
  font-size: 6.8vw;
  color: white;
  margin: 0;
  line-height: 5vw;
}

#ArrowDropDown {
  position: absolute;
  top: 18vw;
  left: 87%;
  width: 7.7%;
  z-index: 10;
}

.WalletBreakdownE {
  top: -18vw;
  left: 0%;
  width: 100%;
  max-height: 250vw;
  background-color: #1f1f1f;
  border-radius: 28px;
  color: white;
  transition: max-height 2s;
  overflow: hidden;
  padding: 17vw 0 2vw 0;
  position: relative;
  -webkit-transition: max-height 2s;
  -moz-transition: max-height 2s;
  -ms-transition: max-height 2s;
  -o-transition: max-height 2s;
}

.HideWalletB {
  max-height: 0;
}

#blurCurrentlyBreakdown {
  position: absolute;
  top: 9.8vw;
  left: 0%;
  width: 100%;
  height: 81%;
  opacity: 0.92;
  z-index: 4;
}

#CurrentlyBreakdownTxt {
  position: absolute;
  top: 10.8vw;
  left: 8%;
  color: black;
  font-size: 5.5vw;
  line-height: 7.5vw;
  margin: 0;
  z-index: 5;
}

#RatioWalletB {
  position: absolute;
  top: 30.8vw;
  left: 5%;
  width: 90%;
  height: 10vw;
  z-index: 80;
}

#PositiveLineRWB {
  position: absolute;
  top: -2vw;
  left: 0%;
  height: 6vw;
  border-radius: 2.5vw;
  background-color: #ffbf12;
  font-size: 3.85vw;
  line-height: 6vw !important;
  color: black;
  z-index: 1;
}

#NegativeLineRWB {
  position: absolute;
  top: -2vw;
  right: 0%;
  height: 6vw;
  border-radius: 2.5vw;
  background-color: #e66100;
  font-size: 3.85vw;
  line-height: 6vw !important;
  color: black;
}

#EmptyRatioRWB {
  position: absolute;
  top: -2vw;
  right: 0%;
  height: 5vw;
  width: 100%;
  border: 0.5vw solid white;
  border-radius: 2.5vw;
  font-size: 4.5vw;
  line-height: 5vw !important;
  color: #ffbf12;
  font-weight: normal;
}

#AssetsTitle {
  position: relative;
  /* display: inline; */
  left: 5%;
  z-index: 5;
  font-size: 4.8vw;
  text-align: left;
  text-transform: none;
  width: 90%;
  padding: 6vw 0 0 0;
}

#AssetsValue {
  font-size: 4.8vw;
  text-align: right;
  color: #ffbf12;
  line-height: 5vw;
  margin: 0;
}

#AssetsListE {
  position: relative;
  display: block;
  left: 0%;
  width: 90vw;
  background-color: #434343;
  border-radius: 6vw;
  text-align: left;
  box-shadow: -1px 9px 23px 3px #121212;
  padding: 3vw 0px;
  margin: 2.5vw 0;
}

/* .WBList p:nth-child(1) {
    left: 5%;
    z-index: 5;
    width: 90%;
    font-weight: lighter;
    font-size: 4.3vw;
    text-align: left;
    padding: 1vw 0;
    position: relative;
} */

.WBList p {
  left: 4vw;
  z-index: 5;
  width: 100%;
  font-size: 4.3vw;
  text-align: left;
  font-weight: lighter;
  width: 90%;
  padding: 1vw 0;
  position: relative;
}

/* .WBList p:nth-child(3) {
    left: 5%;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: left;
}

.WBList p:nth-child(4) {
    right: 4vw;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: right;
}

.WBList p:nth-child(5) {
    left: 5%;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: left;
} */

.WBList p:nth-child(6) {
  right: 4vw;
  z-index: 5;
  width: 100%;
  font-size: 5vw;
  text-align: right;
}

#MoneyTransfersTitleE {
  left: 5%;
  z-index: 5;
  position: relative;
  text-align: left;
  font-size: 4.8vw;
  text-transform: none;
  padding: 3vw 0 0 0;
  width: 90%;
}

#MoneyTransfersListE {
  position: relative;
  display: block;
  left: 0%;
  width: 100%;
  background-color: #434343;
  border-radius: 6vw;
  text-align: left;
  box-shadow: -1px 9px 23px 3px #121212;
  padding: 3vw 0px;
  margin: 2.5vw 0;
}

#ProfitsTitleE {
  left: 5%;
  z-index: 5;
  position: relative;
  text-align: left;
  font-size: 4.8vw;
  text-transform: none;
  padding: 3vw 0 0 0;
  width: 90%;
}

#ProfitsValueE {
  float: right;
  text-align: right;
  font-size: 4.8vw;
  line-height: 5vw;
  margin: 0;
}

#ProfitsListE {
  position: relative;
  display: block;
  left: 0%;
  width: 100%;
  background-color: #434343;
  border-radius: 6vw;
  text-align: left;
  box-shadow: -1px 9px 23px 3px #121212;
  padding: 3vw 0px;
  margin: 2.5vw 0;
}

#AngelDustTitleE {
  position: relative;
  display: block;
  left: 5%;
  z-index: 5;
  font-size: 4.8vw;
  text-align: left;
  text-transform: none;
  padding: 3vw 0 0 0;
  width: 90%;
}

#AngelDustValueE {
  float: right;
  text-align: right;
  font-size: 4.8vw;
  line-height: 5vw;
  margin: 0;
}

#AngelDustListE {
  position: relative;
  display: block;
  left: 0%;
  width: 90vw;
  background-color: #434343;
  border-radius: 6vw;
  text-align: left;
  box-shadow: -1px 9px 23px 3px #121212;
  padding: 3vw 0px;
  margin: 2.5vw 0;
}

#TotalTitleE {
  position: relative;
  display: block;
  left: 5%;
  z-index: 5;
  font-size: 4vw;
  text-align: left;
  text-transform: none;
  padding: 3vw 0;
  width: 90%;
  color: #b8b8b8;
}

#TotalValueE {
  float: right;
  text-align: right;
  font-size: 4vw;
  line-height: 5vw;
  margin: 0;
}

