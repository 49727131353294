.StoryMentionProduct {
    width: 100%;
  }
  .StoryMentionProduct__Card {
    position: relative;
    top: 0;
    width: 84vw;
    height: 51vw;
    background-color: #363636;
    border-radius: 10px;
    z-index: 10;
}
.StoryMentionProduct__DefaultTxt {
    position: absolute;
    top: 0vw;
    padding: 0;
    margin: 0;
    left: 5%;
    line-height: 10vw;
    font-size: 3.5vw;
}
.StoryMentionProduct__InstagramIcon {
    position: absolute;
    top: 9vw;
    left: 1%;
    width: 42vw;
}
.StoryMentionProduct__InstaTxt {
    position: absolute;
    top: 11vw;
    left: 52%;
    padding: 0;
    margin: 0;
    font-size: 4.8vw;
    line-height: 6vw;
}
.StoryMentionProduct__OneTxt {
    position: absolute;
    top: 30vw;
    left: 52%;
    margin: 0;
    font-size: 16vw;
    color: #FFBF12;
}
.StoryMentionProduct__PSTxt {
    position: absolute;
    top: 32vw;
    left: 65%;
    margin: 0;
    font-size: 5vw;
    line-height: 5vw;
    color: #FFBF12;
}
  .StoryMentionProduct__BuyBtn {
    padding: 0 14.5vw;
    font-size: 4vw;
    line-height: 8vw;
    background-color: #902366;
    border: 3.3px solid #902366;
    border-radius: 8px;
    margin: 4vw auto;
    width: fit-content;
}