.ExSeNoMatch {
  position: relative;
  width: 100%;
  height: 77vh;
  margin: 0 0 15vw 0;
}
.ExSeNoMatch__Emoji {
  position: absolute;
  top: 5vw;
  left: 42.5%;
  width: 17vw;
}
.ExSeNoMatch__NotFoundTxt {
  position: absolute;
  top: 28vw;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 7vw;
  line-height: 7vw;
  margin: 0;
}
.ExSeNoMatch__TheyAreTxt {
  position: absolute;
  top: 44vw;
  left: 10vw;
  width: 80%;
  text-align: center;
  font-size: 4vw;
  line-height: 6vw;
  margin: 0;
}
.ExSeNoMatch__SMIInstagramHandle {
  position: absolute;
  top: 69vw;
  left: 10%;
  width: 80%;
  text-align: center;
  line-height: 9vw;
  height: 9.5vw;
  font-size: 5vw;
  border-radius: 20px;
  border: none;
}
.ExSeNoMatch__SMIInstagramHandle::placeholder {
  font-size: 5vw;
  color: #121212;
}
.ExSeNoMatch__SMIInstagramIcon {
  position: absolute;
  top: 69.8vw;
  left: 12%;
  width: 8vw;
}
.ExSeNoMatch__Done {
  position: absolute;
  top: 95vw;
  left: 43%;
  width: 14vw;
}
.ExSeNoMatch__UserRequestIndicatorMsg {
  position: fixed;
  width: 100%;
  bottom: 19vw;
  background: #363636;
  font-size: 6vw;
  text-align: center;
  padding: 13vw 0;
  line-height: 8vw;
  color: #ffbf12;
  margin: 0;
  z-index: 90;
}
