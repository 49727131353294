.animate-to-left {
    left: 100vw !important;
    top: 0 !important;
    margin-left: 100vw;
  }
  
  .animate-to-right {
    right: 100vw !important;
    top: 0 !important;
    margin-right: 100vw !important;
  }
  
  .SignUpForm {
    position: relative;
    display: flex;
    width: 100vw;
    /* max-height: max-content; */
    transition: right 0.5s;
    padding: 0 8vw;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
  }
  /* #sign-up-form {
      position: absolute;
      top: 42vw;
      right: 0;
      width: 100%;
      height: 100vw;
      transition: right 0.5s;
  } */
  .SignUpForm__HiTxt {
    margin: 2vw auto 5vw auto;
    font-size: 6.8vw;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .Login__Emoji {
    position: relative;
    top: 4vw;
    width: 17vw;
    padding-left: 3vw;
  }
  
  .SignUpForm__Emoji {
    position: relative;
    top: 4vw;
    width: 17vw;
    padding-left: 3vw;
  }
  /* .SignUpForm__inputs{
    } */
  .input-signup {
    width: inherit;
    height: 9vw;
    font-size: 4.25vw;
    font-family: "open-sans";
    font-weight: bold;
    line-height: 9vw;
    padding: 0vw 3vw;
    border-radius: 3vw;
    border: 0;
    margin-top: 3vw;
  }
  .SignUpForm__InputFirstName {
    margin-right: 2vw;
  }
  
  .SignUpForm__container-phone-number{
    margin-top: 3vw;
  }
  .SignUpForm__PhoneNumber-button{
    height: 9vw !important;
    width: 10.7vw !important;
    top:0.5vw !important;
    border-top-left-radius: 3vw !important;
    border-bottom-left-radius: 3vw !important;
  }
  .SignUpForm__PhoneNumber-button.open{
    border-radius: 3vw !important;
  }
  .SignUpForm__PhoneNumber-input {
    padding-left: 12vw !important;
    width: 100% !important;
    height: 9vw !important;
    font-size: 4.25vw !important;
    font-family: "open-sans" !important;
    font-weight: bold !important;
    line-height: 9vw !important;
    border-radius: 3vw !important;
    border: 0 !important;
  }
  .SignUpForm__PhoneNumber-dropdown{
    color: black;
    width: 79.5vw !important;
    border-radius: 3vw !important;
    margin-top: 4vw !important;
  }
  .SignUpForm__InputPhoneNumber {
    width: 100%;
    padding: 0vw 3vw 0 18.5vw;
  }
  .SignUpForm__PhoneCountryCode {
    position: absolute;
    font-size: 4vw;
    line-height: 9vw;
    text-align: right;
    top: 3vw;
    right: 63vw;
    color: black;
    margin: 0;
    z-index: 9;
  }
  .SignUpForm__PhoneNumCountriesMenu {
    position: absolute;
    height: 9vw;
    width: 15vw;
    top: 3.1vw;
    right: 64vw;
    font-family: "open-sans";
    font-weight: bold;
    font-size: 0vw;
    line-height: 9vw;
    text-align: right;
    z-index: 9;
    appearance: none;
    background: none;
    border: none;
    padding-left: 1vw;
    z-index: 5;
  }
  
  .SignUpForm__PhoneNumCountriesMenu:focus {
    border: none;
    color: none;
    outline: none;
  }
  .SignUpForm__BirthdayInput{
    width: 79.4vw !important;
    color: black;
  }
  .SignUpForm__InputPassword {
    width: 100%;
  }
  #EyePasswordSU {
    position: absolute;
    top: 76.5vw;
    left: 79%;
    width: 6vw;
  }
  
  .SignUpForm__InputConfirmPassword {
    /* position: absolute;
    top: 86vw;
    left: 12%;*/
    width: 100%;
    /* font-size: 4vw; */
    /* line-height: 9vw; */
    font-family: "open-sans";
    font-weight: bold;
    padding: 0vw 3vw;
    /* border-radius: 11px; */
  }
  .SignUpForm__EyeImgPassword {
    position: absolute;
    top: 5.7vw;
    right: 3.5vw;
    width: 6vw;
  }
  
  .SignUpForm__indicate {
    min-height: 12vw;
    padding-top: 5vw;
  }
  .SignUpForm__LoaderPos {
    margin: 0 auto;
    width: 5vw;
    height: 5vw;
  }
  .SignUpForm__ErrorMassage {
    width: 100%;
    font-size: 4vw;
    margin: 0;
    line-height: 4vw;
    text-align: center;
    color: red;
    font-weight: normal;
  }
  .SignUpForm__SignUpButton {
    /* position: absolute;
    top: 102vw;
    left: 10%;
    width: 80%; */
    color: #ffbf12;
    text-align: center;
    font-size: 4vw;
    line-height: 10vw;
    background-color: white;
    border-radius: 25px;
  }
  
  #SignUpByFacebbok {
    position: absolute;
    top: 113vw;
    left: 13%;
    width: 37%;
    font-size: 4vw;
    text-align: center;
    height: 9vw;
    background-color: #4167b2;
    border-radius: 11px;
  }
  