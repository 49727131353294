.CreativeCard {
  position: relative;
  width: 100%;
  height: 34vw;
  background-color: #1f1f1f;
  box-shadow: 0px 13px 9px -6px #121212;
  z-index: 12;
}
.CreativeCard__opacityBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.87;
}
.CreativeCard__StarsPosition{
  position: absolute;
  top: 2px;
  right: 2px;
}
/* convert to creative-card component */
.CreativeCard__TotalHoldingContainer {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 5px;
  z-index: 30;
}
.CreativeCard__TotalHoldingContainer p {
  margin: 0;
  line-height: 15px;
}
/* */

.CreativeCard__FlexContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.CreativeCard__BrandPic {
  width: 83px;
  height: 83px;
  border: 4.5px solid #24beff;
  border-radius: 50%;
  background-size: 83px 83px;
  margin: 0 20px 0 30px;
}
.blue {
  border: 4.5px solid #24beff;
}
.purple {
  border: 4.5px solid #902366;
}
.yellow {
  border: 4.5px solid #ffbf12;
}

.CreativeCard__Rating {
    position: absolute;
    top: 31px;
    left: 99px;
    background-color: #24beff;
    border-radius: 54px;
    font-size: 10px;
    line-height: 23px;
    padding: 0 9px;
    margin: 0;
    color: #ffffff !important;
    box-shadow: 0px 8px 20px 2px #121212;
    z-index: 4;
}
.CreativeCard__BrandName {
  line-height: 27px;
  font-size: 20px;
  margin: 0;
  text-align: left;
}
.CreativeCard__Arrow {
  position: relative;
  top: 0vw;
  width: 5.5vw;
  padding-right: 1.5vw;
}
.CreativeCard__Price {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}
.ArrowCreative {
  position: absolute;
  display: inline;
  left: 60%;
  top: 14vw;
  width: 3.5%;
}
.CreativeCard__Niches {
  font-size: 11px;
  line-height: 15px;
  font-weight: normal;
  margin: 0;
  text-align: left;
}
.CreativeCard__Location {
  font-size: 10px;
  margin: 0;
  text-align: left;
  line-height: 16px;
  font-weight: lighter;
}
