.MoneyCalcTAndCs {
  opacity: 0.94;
  top: 0;
  left: 0;
  background-image: url("../../../assets/images/money-calculator/step-3-background.png"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  background-clip: border-box;
  width: 100%;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.MoneyCalcTAndCs__ShortLogo {
  margin: 13vw 5vw 8vw;
  border-radius: 10vw;
  box-shadow: 0 0 8vw 20vw inset #0000000a, 0 0 4vw 2vw #0000000d;
  width: 37vw;
}

.MoneyCalcTAndCs__TermTxtContainer {
  position: relative;
  margin: 0 5vw;
  width: 90%;
  background-color: rgb(255, 255, 255, 0.8);
  border-radius: 8px;
  overflow-y: scroll;
}
.MoneyCalcTAndCs__TermTxtContainer p {
  position: absolute;
  top: 2vh;
  left: 5%;
  width: 90%;
  margin: 0;
  padding: 0;
  font-size: 2vw;
  line-height: 4vw;
  color: black;
}
.MoneyCalcTAndCs__TermTxtContainer span {
  font-size: 3.5vw;
  line-height: 4vw;
}
.MoneyCalcTAndCs__TermTxtContainer i {
  font-size: 2vw;
  line-height: 3vw;
  text-decoration: underline;
}
.MoneyCalcTAndCs__BtnsContainer {
    margin: 0 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.MoneyCalcTAndCs__DeclineBtn {
  width: fit-content;
  color: #707070;
  margin: 0;
  padding: 0 5vw;
  border: 1px solid #ffbf12;
  border-radius: 10px;
  font-size: 4vw;
  line-height: 9vw;
  background-color: rgb(0, 0, 0, 0.8);
}
.MoneyCalcTAndCs__AcceptBtn {
  width: fit-content;
  margin: 0;
  padding: 0px 6vw;
  background-color: #ffbf12;
  border-radius: 10px;
  font-size: 4vw;
  line-height: 9.5vw;
}
