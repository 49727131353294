.arrow_right {
  width: 10vw;
  height: 8.5vw;
  position: absolute;
  top: 30vw;
  right: 0;
  transition: right 0.6s;
  z-index: 25;
  border-bottom-left-radius: 5vw;
  border-top-left-radius: 5vw;
  box-shadow: 0 0 2vw 0px black;
}
.arrow_right--hidden {
  right: -10vw;
}

.arrow_left {
  width: 10vw;
  height: 8.5vw;
  position: absolute;
  left: 0vw;
  top: 30vw;
  transition: left 0.6s;
  z-index: 25;
  border-bottom-right-radius: 5vw;
  border-top-right-radius: 5vw;
  box-shadow: 0 0 2vw 0px black;
}
.arrow_left--hidden {
  left: -10vw;
}
