.WhatsTheVibe{
    padding-top: 5vw;
}
.WhatsTheVibe__HeadlinesContainer{
    text-align: center;
}
.WhatsTheVibe__HeadlinesContainer p{
    margin: 0;
}
.WhatsTheVibe__EveryTxt{
    text-align: center;
    font-size: 3.75vw;
    line-height: 4.75vw;
    font-weight: lighter;
    width: 70vw;
    margin: 5vw auto;
}