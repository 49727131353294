.Mission__Img {
  width: 100%;
}
.Mission__Headline {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 7vw;
  line-height: 8vw;
  margin: -12vw 0 0;
  z-index: 2;
}
.Mission__Headline span {
  line-height: 30vw;
  font-size: 15vw;
  text-shadow: 0 0 0.1vw #fff, 0 0 0.1vw #fff, 0 0 3.1vw #ffbf12,
    0 0 3.1vw #ffbf12;
}
.Mission__Shadow {
  position: relative;
  margin-top: -35vw;
  width: 100%;
  height: 30vw;
  background-color: #121212;
  box-shadow: 0 -7vw 8vw 8vw #121212;
  z-index: 1;
}
.Mission__Line {
  display: block;
  margin: 10vw auto 0;
  border-radius: 25px;
  box-shadow: 0 0 2vw 0.5vw #24beff;
}

.Mission__Circle {
  position: absolute;
  top: -50px;
  left: 0;
  width: 130px;
}
.Mission__Paragraph1 {
  position: relative;
  font-weight: lighter;
  font-size: 15px;
  line-height: 20px;
  margin: 25vw 5vw 0;
  z-index: 2;
}
.Mission__Rect {
  position: absolute;
  top: 0;
  right: -45px;
  width: 145px;
}
.Mission__Paragraph2 {
  position: relative;
  text-align: center;
  font-weight: lighter;
  font-size: 15px;
  line-height: 20px;
  margin: 13vw 5vw 10vw;
  z-index: 2;
}
.Mission__Chart {
  position: relative;
  display: block;
  margin: 0 auto 10vw;
  width: 90vw;
}
.Mission__Paragraph3 {
  text-align: left;
  font-weight: lighter;
  font-size: 0.5em;
  line-height: 1.2em;
  margin: 0 5vw;
  z-index: 2;
}
.Mission__LearnBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin: 10vw 5vw;
}
.Mission__LearnBtn {
  font-size: 4.5vw;
  line-height: 5vw;
  color: #cecaca;
  margin: auto 3vw;
}
.Mission__LearnBtnArrow {
 
  width: 8.5vw;
}

.Mission__WeBelieve {
  margin: 0 2vw 10vw;
  width: 96vw;
}

#FullnameInputBAA {
  position: absolute;
  top: 184vw;
  left: 8%;
  width: 76vw;
  line-height: 9vw;
  font-size: 4vw;
  border: none;
  border-radius: 9px;
  padding: 0 4vw;
  color: #363636;
}
#EmailInputBAA {
  position: absolute;
  top: 195vw;
  left: 8%;
  width: 76vw;
  line-height: 9vw;
  font-size: 4vw;
  border: none;
  border-radius: 9px;
  padding: 0 4vw;
  color: #363636;
}
#PhoneNumberInputBAA {
  position: absolute;
  top: 206vw;
  left: 8%;
  width: 76vw;
  line-height: 9vw;
  font-size: 4vw;
  border: none;
  border-radius: 9px;
  padding: 0 4vw;
  color: #363636;
}
#FullnameInputBAA::placeholder,
#EmailInputBAA::placeholder,
#PhoneNumberInputBAA::placeholder {
  font-size: 3.5vw;
  color: #363636;
}

#SpeakSoonTxtBAA {
  position: absolute;
  top: 233vw;
  left: 0%;
  width: 100%;
  text-align: center;
  line-height: 9vw;
  font-size: 8vw;
  margin: 0;
}

#RegisterBtnBAA {
  position: absolute;
  top: 253vw;
  left: 24%;
  width: 53.5vw;
  line-height: 9vw;
  font-size: 4.5vw;
  margin: 0;
  background-color: #ffbf12;
  border-radius: 11px;
  border: none;
}
#LoaderPosSizeBAA {
  position: absolute;
  top: 268vw;
  left: 48%;
  width: 4vw;
  height: 4vw;
}

#SuccesedMessageBAA {
  position: fixed;
  bottom: 5vw;
  left: 5vw;
  width: 90vw;
  height: 56vw;
  background-color: #363636;
  border-radius: 25px;
  z-index: 3;
}
#SuccesedMessageBAA p {
  position: absolute;
  top: 7vw;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 4.5vw;
  line-height: 6.5vw;
  margin: 0;
}
#SuccesedMessageBAA span {
  color: #ffbf12;
  font-size: 5vw;
}
#SuccesedMessageBAA button {
  position: absolute;
  top: 40vw;
  left: 25vw;
  width: 40vw;
  line-height: 9vw;
  font-size: 4.5vw;
  background-color: #ffbf12;
  border: none;
  border-radius: 25px;
}
