#Loader {
    border: 1vw solid #f3f3f3;
    border-radius: 50%;
    border-top: 1vw solid #ffbf12;
    /* box-shadow: 2px 0 0 #ffbf12, 5px 0 0 #ffbf12; */
    width: 100%;
    height: 100%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }