.NetWorth {
  width: 90%;
  text-align: left;
  background-color: #e8e8e8;
  box-shadow: 0 0 2vw #ffbf12;
  border-radius: 5vw;
  margin: 15px auto 0;
  padding: 5vw 4vw;
}
.NetWorth p {
  text-align: right;
  font-size: 39px;
  color: #34c100;
  font-weight: lighter;
  margin: 0 0 ;
  line-height: 50px;
}
.NetWorth span {
  text-align: left;
  display: block;
  font-size: 15px;
  line-height: 20px;
  font-weight: lighter;
  color: #353535 !important;
}

.NetWorth__ArrowNetWorth {
  position: relative;
  width: 23px;
  height: 26px;
}
.NetWorth__ArrowAvaBalance {
  position: relative;
  width: 30px;
  height: 38px;
}