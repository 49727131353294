.RegisterTAndCs {
  justify-content: space-between;
}
.RegisterTAndCs__TxtContainer {
  position: relative;
  width: 90vw;
  /* height: 60vh; */
  overflow: scroll;
  background-color: white;
  border-radius: 3vw;
  margin: 20vw auto 0;
}
.RegisterTAndCs__TxtContainer p {
  position: absolute;
  left: 5%;
  color: black;
  font-size: 4vw;
  line-height: 5vw;
  width: 90%;
  font-weight: normal;
  overflow-x: hidden;
}
.RegisterTAndCs__TxtContainer span {
  font-weight: bolder;
}
.RegisterTAndCs__TxtContainer i {
  color: red;
  font-style: normal;
}
.RegisterTAndCs__AcceptBtn {
  position: relative;
  display: inline;
  /* top: 150vw; */
  float: right;
  margin: 0;
  font-size: 4vw;
  line-height: 12vw;
  background-color: white;
  color: #ffbf12;
  padding-left: 6vw;
  padding-right: 3vw;
  border-bottom-left-radius: 10vw;
  border-top-left-radius: 10vw;
}

.RegisterTAndCs__DeclineBtn {
  display: inline;
  float: left;
  width: 24%;
  height: 12vw;
  padding-left: 1vw;
  padding-right: 3vw;
  margin: 0;
  font-size: 4vw;
  line-height: 12vw;
  border: 0.75vw solid #ffffff;
  border-left: 0;
  /* border-radius: 25px; */
  border-bottom-right-radius: 10vw;
  border-top-right-radius: 10vw;
  text-align: center;
  line-height: 10vw;
  color: #707070;
}

#TBlt1 {
  position: absolute;
  top: 152vw;
  left: 41%;
}
#TBlt2 {
  position: absolute;
  top: 152vw;
  left: 47%;
}
#TBlt3 {
  position: absolute;
  top: 152vw;
  left: 53%;
}
