.AuthNav {
  position: relative;
  height: 43vw;
  margin: 0;
}
.AuthNav__options {
  display: flex;
  height: 61vw;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.AuthNav__LogInTxt {
  display: inline-block;
  /* position: absolute;
    top: 16vw;
    left: 3%; */
  /* width: 47.5%;
    height: 25vw; */
  font-size: 7.5vw;
  /* line-height: 26vw; */
  text-shadow: 0.35vw 0.35vw 0px black, 0.5vw 0.5vw 0 white;
  text-align: center;
  margin: 0;
  padding: 0;
}

.AuthNav__SignUpTxt {
  display: inline-block;
  /* position: absolute;
    top: 16vw;
    left: 49.5%; */
  /* width: 45.5%;
    height: 25vw; */
  font-size: 7.5vw;
  /* line-height: 26vw; */
  text-shadow: 0.35vw 0.35vw 0px black, 0.5vw 0.5vw 0 white;
  text-align: center;
  margin: 0;
  padding: 0;
}

.AuthNav__LogInSubLine {
  position: absolute;
  top: 40.5vw;
  left: 8.35%;
  transition: left 0.6s;
}

.AuthNav__SignUpSubLine {
  position: absolute;
  top: 40.5vw;
  left: 51%;
  transition: left 0.6s;
}
