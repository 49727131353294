#ResponseDetailsDiv{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 2.5vw;
}
#ResponseDetailsDiv span:nth-child(1){
    position: absolute;
    left: 0%;
    width: 25%;
    text-align: center;
}
#ResponseDetailsDiv i:nth-child(2){
    position: absolute;
    left: 25%;

}#ResponseDetailsDiv span:nth-child(3){
    position: absolute;
    left: 25.5%;
    width: 25%;
    text-align: center;

}#ResponseDetailsDiv i:nth-child(4){
    position: absolute;
    left: 50%;

}#ResponseDetailsDiv span:nth-child(5){
    position: absolute;
    left: 50.5%;
    width: 25%;
    text-align: center;

}#ResponseDetailsDiv i:nth-child(6){
    position: absolute;
    left: 75%;

}#ResponseDetailsDiv span:nth-child(7){
    position: absolute;
    left: 75%;
    width: 25%;
    text-align: center;

}
#BullSRImg {
    position: relative;
    top: 0.3vw;
    left: -1vw;
    width: 3.6vw;
}
#BearSRImg {
    position: relative;
    top: 0.3vw;
    left: -1vw;
    width: 3.6vw;
}