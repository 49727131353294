#SMIMyPSDiv{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}
#BackPageSMIMyPS {
    position: absolute;
    top: 21vw;
    left: 0%;
    width: 13vw;
}
#MyPSReStocksTitle{
    position: absolute;
    top: 22vw;
    left: 17%;
    margin: 0;
    font-size: 5vw;
    line-height: 8vw;
}
#MyPSReStocksTitle spam{
    color: #ffbf12;
}

#TotalPSAuthAmount{
    position: absolute;
    top: 43vw;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 14vw;
    line-height: 10vw;
}
#TotalPSATxt{
    position: absolute;
    top: 56vw;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 4vw;
    line-height: 5vw;
}
#HideIssuedDiv{
    display: none;
}
#ZeroIssuedIPO{
    position: absolute;
    top: 76vw;
    left: 5%;
    width: 90%;
    height: 87vw;
    background-color: #363636;
    border: 1.5px solid white;
    border-radius: 22px;
}
#IssuedPSDiv{
    position: absolute;
    top: 76vw;
    left: 5%;
    width: 90%;
    height: 87vw;
    background-color: #363636;
    border: 1.5px solid white;
    border-radius: 22px;
}
#IssuedPSAmount{
    position: absolute;
    top: 5vw;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 14vw;
    line-height: 8vw;
}
#IssuedPSTxt{
    position: absolute;
    top: 17vw;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 4vw;
    line-height: 5vw;
}
#ReleaswExpTxt{
    position: absolute;
    top: 31vw;
    left: 7%;
    margin: 0;
    font-size: 4.5vw;
    line-height: 5vw;
    text-transform: none;
}
#InputReleaseAmount{
    position: absolute;
    top: 64vw;
    left: 7%;
    width: 86%;
    line-height: 10vw;
    font-size: 7vw;
    border: none;
    border-radius: 12px;
    text-align: center;
}
#PSTxtInput{
    position: absolute;
    top: 66.5vw;
    left: 11%;
    line-height: 6vw;
    font-size: 4vw;
    color: black;
    margin: 0;
}
#GoLiveBtn{
    position: absolute;
    top: 82vw;
    left: 33%;
    line-height: 10vw;
    font-size: 4.5vw;
    padding: 0 8vw;
    margin: 0;
    background-color: #ffbf12;
    border-radius: 10px;
}
#ReqPSBtn{
    position: absolute;
    top: 82vw;
    left: 13.5%;
    line-height: 10vw;
    font-size: 4.5vw;
    padding: 0 8vw;
    margin: 0;
    background-color: #902366;
    border-radius: 10px;
}
#ZeroReleaswExpTxt {
    position: absolute;
    top: 31vw;
    left: 7%;
    margin: 0;
    font-size: 4.3vw;
    line-height: 5vw;
    text-transform: none;
}
#ZeroInputReleaseAmount {
    position: absolute;
    top: 64vw;
    left: 7%;
    width: 86%;
    background-color: #707070;
    line-height: 10vw;
    font-size: 7vw;
    border: none;
    color: white;
    border-radius: 12px;
    text-align: center;
}
#ZeroPSTxtInput {
    position: absolute;
    top: 66vw;
    left: 11%;
    line-height: 6vw;
    font-size: 6vw;
    color: #757575;
    margin: 0;
}
#SoldOutStats{
    position: absolute;
    top: 250vw;
    left: 0%;
}

#OnMarketStats{
    position: absolute;
    top: 250vw;
    left: 0%;
}
#MessageStatus{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 5vw;
    line-height: 25vw;
    background-color: black;
    text-align: center;
    margin: 0;
}
#HideM{
    display: none;
}
