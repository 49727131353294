.SaleView{
  padding-top: 20vw;
}
.SaleView__SaleCardAndComment {
  margin: 4vw 0;
}

/* #ErrorMessageSV {
  position: fixed;
  bottom: 0vw;
  left: 0%;
  background-color: #363636;
  width: 100%;
  font-size: 4vw;
  text-align: center;
  padding: 10vw 3vw;
  line-height: 5vw;
  z-index: 70;
}
#HideESV {
  display: none;
} */

.SaleView__BrandDetails {
  position: relative;
  width: 100%;
  height: 50vw;
  margin: 8vw 0 16vw;
}
.SaleView__OwnersPics {
  position: absolute;
  top: 1vw;
  left: 5%;
  width: 90%;
  height: 33vw;
}
.SaleView__SMIPicIPO {
  position: absolute;
  left: 2vw;
  top: 3vw;
  width: 31vw;
  height: 31vw;
  border: 5px solid #24beff;
  border-radius: 50%;
  background-size: 29vw;
}
.SaleView__SMIPicP2P {
  position: absolute;
  left: 22%;
  top: 5vw;
  width: 22vw;
  height: 22vw;
  border: 4px solid #24beff;
  border-radius: 50%;
  background-position: 0px 0px;
  background-size: 20vw 20vw;
  z-index: 2;
}
.SaleView__SMIRatingIPO {
  position: absolute;
  top: 16vw;
  left: 29vw;
  background-color: #24beff;
  border-radius: 50px;
  font-size: 4vw;
  line-height: 7vw;
  padding: 0 2.3vw;
  margin: 0;
  box-shadow: 0px 8px 20px 2px #121212;
  z-index: 4;
}
.SaleView__SMIRatingP2P {
  position: absolute;
  top: 23vw;
  left: 28vw;
  background-color: #24beff;
  border-radius: 50px;
  font-size: 4vw;
  line-height: 7vw;
  padding: 0 2.3vw;
  margin: 0;
  box-shadow: 0px 8px 20px 2px #121212;
  z-index: 4;
}
.SaleView__TraderPic {
  position: absolute;
  left: 2%;
  top: 1vw;
  width: 29.5vw;
  height: 29.5vw;
  border-radius: 50%;
  background-position: 0px 0px;
  background-size: 29.5vw auto;
  z-index: 1;
}
.SaleView__BrandName {
  position: absolute;
  left: 8%;
  top: 30vw;
  font-size: 5.5vw;
}
.SaleView__BrandNameIPO {
  position: absolute;
  left: 47%;
  width: 52vw;
  top: 4vw;
  font-size: 5vw;
  margin: 0;
  line-height: 8vw;
  font-weight: initial;
}
.SaleView__TraderName {
  position: absolute;
  left: 8%;
  top: 37vw;
  font-size: 5vw;
  font-weight: normal;
}
.SaleView__IPOStocksLeftTxt {
  position: absolute;
  left: 46%;
  top: 24vw;
  font-size: 5.5vw;
  margin: 0;
  line-height: 8vw;
  color: #707070;
}
.SaleView__IPOStocksLeftTxt span {
  color: #ffbf12;
  font-size: 11vw;
}

.SaleView__SharingBtn {
  position: absolute;
  left: 85%;
  top: 40vw;
  width: 10vw;
}
/* #SharePUStockVPos {
} */
.SaleView__UnshareByClickingOut {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
}
/* 
#ProductTagCircle {
  position: absolute;
  left: 8%;
  top: 55vw;
}
#ProductTag {
  position: absolute;
  left: 11.5%;
  top: 58.5vw;
  width: 7%;
  z-index: 8;
}
#StoryMention {
  position: absolute;
  left: 14.5%;
  top: 54vw;
  background-color: white;
  color: black;
  font-size: 4vw;
  line-height: 7.5vw;
  padding: 0px 7vw;
  border-radius: 8px;
} */

.SaleView__SMValuesPos {
  position: relative;
  width: 100%;
  height: 60vw;
}
/* #SMISocialMediaDetails {
  position: absolute;
  left: 5%;
  top: 84vw;
  width: 90%;
  height: 57vw;
  background-color: #2e2e2e;
  border-radius: 24px;
}
#GrowthData {
  position: absolute;
  left: 0%;
  top: 146vw;
  width: 100%;
  height: 70vw;
}

#ProjectedValue {
  position: absolute;
  left: 5%;
  top: 0vw;
  width: 26%;
  height: 26vw;
  background-color: #2e2e2e;
  border-radius: 16px;
}
#GrowthFromIPO {
  position: absolute;
  left: 36.5%;
  top: 0vw;
  width: 26%;
  height: 26vw;
  background-color: #2e2e2e;
  border-radius: 16px;
}
#GrowthRate {
  position: absolute;
  left: 68%;
  top: 0vw;
  width: 26%;
  height: 26vw;
  background-color: #2e2e2e;
  border-radius: 16px;
}
.TypeOfGrowth {
  position: absolute;
  top: 10vw;
  width: 100%;
  text-align: center;
  font-size: 2.7vw;
}
.ValueOfGrowth {
  position: absolute;
  top: 3vw;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 8vw;
  color: hsl(44, 100%, 54%);
}
#Hashtags {
  position: absolute;
  top: 23vw;
  left: 5%;
  width: 90%;
  text-align: left;
  font-size: 3vw;
  font-weight: normal;
} */
.SaleView__Buttons {
  position: fixed;
  display: flex;
  width: 100%;
  height: 19vw;
  bottom: 0vw;
  background-color: #121212db;
  box-shadow: 0vw -7vw 13vw 0px #121212e8;
  z-index: 10;
  flex-direction: row;
  justify-content: space-evenly;
}
.SaleView__ViewProfileBtn {
  background-color: #121212;
  border: 0.8vw solid #902366;
  border-radius: 3vw;
  font-size: 4vw;
  width: fit-content;
  text-align: center;
  padding: 0 3vw;
  line-height: 10vw;
}
.SaleView__BuyBtn {
  background-color: #902366;
  border: 0.8vw solid #902366;
  width: 35vw;
  border-radius: 3vw;
  font-size: 4vw;
  text-align: center;
  padding: 0 3vw;
  line-height: 10vw;
}
.SaleView__BuyBtn--off {
  background-color: #707070;
  border: 0.8 solid #707070;
  width: 35vw;
  border-radius: 3vw;
  font-size: 4vw;
  text-align: center;
  padding: 0 3vw;
  line-height: 10vw;
}
