/* #CongratulationsDiv{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    /* height: 105vh; */
    /* background-color: #121212; */

#CelebrateSmileyT {
    position: absolute;
    top: 9vw;
    left: 38%;
    width: 23%;
    z-index: 888;
}
#CongratulationsTxt{
    position: absolute;
    top: 33vw;
    left: 0%;
    width: 100%;
    margin: 0;
    font-size: 10vw;
    line-height: 12vw;
    text-align: center;
    text-transform: initial;
}
#SCPosC{
    position: absolute;
    top: 50vw;
    left: 5%;
    width: 90%;
    height: 34vw;
}
#OfferingDetails{
    position: absolute;
    top: 87vw;
    left: 5%;
    width: 90%;
    height: 26vw;
    background-color: black;
    border-radius: 8px;
}
#OfferingDetails p:nth-child(1){
    position: absolute;
    top: 0vw;
    left: 0%;
    width: 100%;
    margin: 0;
    font-size: 4vw;
    font-weight: 200;
    line-height: 10vw;
    text-align: center;
}
#OfferingDetails p:nth-child(2){
    position: absolute;
    top: 9vw;
    left: 0%;
    width: 100%;
    margin: 0;
    font-size: 6vw;
    line-height: 6vw;
    text-align: center;
}
#OfferingDetails p:nth-child(3){
    position: absolute;
    top: 16vw;
    left: 0%;
    width: 100%;
    margin: 0;
    font-size: 6vw;
    line-height: 6vw;
    text-align: center;
}
#OfferingDetails span{
    color: #ffbf12;
}
#InitialRDiv{
    position: absolute;
    top: 120vw;
    left: 5%;
    width: 90%;
    height: 21vw;
    background-color: black;
    border-radius: 8px;
}
#InitialRDiv p{
    position: absolute;
    top: 0vw;
    left: 0%;
    width: 100%;
    margin: 0;
    font-size: 4vw;
    font-weight: 200;
    line-height: 10vw;
    text-align: center;
}
#RBlue{
    position: absolute;
    top: 11vw;
    left: 32%;
    width: 5.5vw;
    height: 5.5vw;
    border-radius: 50%;
    background-color: #24BEFF;
}
#RGreen{
    position: absolute;
    top: 11vw;
    left: 42%;
    width: 5.5vw;
    height: 5.5vw;
    border-radius: 50%;
    background-color: #34C100;
}#RPur{
    position: absolute;
    top: 11vw;
    left: 52%;
    width: 5.5vw;
    height: 5.5vw;
    border-radius: 50%;
    background-color: #902366;
}#RYellow{
    position: absolute;
    top: 11vw;
    left: 62%;
    width: 5.5vw;
    height: 5.5vw;
    border-radius: 50%;
    background-color: #FFBF12;
}
.Surround{
    border: 0.75vw solid white;
    width: 4vw !important;
    height: 4vw !important;
}
#OverallRDiv{
    position: absolute;
    top: 147vw;
    left: 5%;
    width: 90%;
    height: 34vw;
    background-color: black;
    border-radius: 8px;
}
#OverallRDiv p:nth-child(1){
    position: absolute;
    top: 0vw;
    left: 0%;
    width: 100%;
    margin: 0;
    font-size: 4vw;
    font-weight: 200;
    line-height: 10vw;
    text-align: center;
}
#OverallRDiv p:nth-child(2){
    position: absolute;
    top: 9vw;
    left: 0%;
    width: 100%;
    margin: 0;
    padding-left: 2vw;
    font-size: 6vw;
    line-height: 6vw;
    text-align: center;
}
#OverallRDiv span{
    padding: 1vw 3vw;
    position: absolute;
    top: -0.5vw;
    left: 32vw;
    border-radius: 50%;
    background-color: #ffbf12;
    font-size: 4vw;
}
#NextMCBtnC{
    position: absolute;
    top: 172vw;
    left: 28%;
    font-size: 5vw;
    line-height: 9vw;
    text-align: center;
    padding: 0px 17vw;
    margin: 0;
    border-radius: 15px;
    background-color: #ffbf12;
}