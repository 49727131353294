#MenuHomeBlur{
    position: fixed;
    top: 3vw;
    right: 0%;
    width: 13vw;
    height: 8vw;
    z-index: 16;
    opacity: 0.2;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}
#MenuHome{
    position: fixed;
    top: 5vw;
    right: 3%;
    width: 6vw;
    z-index: 18;
}