/* .StockComment{
    position: absolute;
    top: 30vw;
    left: 5%;
    width: 90%;
    height: 38vw;
    background-color: #1F1F1F;
} */
.StockComment {
    position: relative;
    left: 5%;
    width: 90%;
    /* height: 38vw; */
    /* margin-bottom: 12vw; */
    background-color: #1F1F1F;
}
.StockCommentAllCommentsSlide {
    position: relative;
    /* top: 30vw; */
    left: 5%;
    width: 90%;
    height: 22vw;
    background-color: #1F1F1F;
}
#SaleButtonsSC{
    position: relative;
    width: 100%;
    height: 22vw;
}
#Bull {
    position: absolute;
    top: 4.5vw;
    left: 5%;
    width: 5%;
}
#BullTxt {
    position: absolute;
    width: 29vw;
    text-align: center;
    top: 1vw;
    left: 0vw;
    font-size: 3.5vw;
    line-height: 10vw;
    margin: 0;
}
#CommentTxt{
    position: absolute;
    top: -2.75vw;
    left: 41%;
    font-size: 3.5vw;
}
#Bear{
    position: absolute;
    top: 4.5vw;
    right: 4%;
    width: 5%;
}
#BearTxt{
    position: absolute;
    width: 29vw;
    text-align: center;
    top: 1vw;
    right: 0vw;
    font-size: 3.5vw;
    line-height: 10vw;
    margin: 0;
}
.shadowBull {
    color: #34c100;
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
 }
 .shadowBear {
    color: #e66100;
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
 }
#CommentsListLine{
    position: absolute;
    top: 11vw;
    left: 0%;
}
#StockResponseDetails{
    position: absolute;
    top: 9vw;
    left: 0%;
    width: 100%;
    font-size: 2.5vw;
    padding: 0;
}
#StockResponseDetails span:nth-child(1){
    position: absolute;
    left: 0%;
    width: 25%;
    text-align: center;
}
#StockResponseDetails span:nth-child(2){
    position: absolute;
    left: 25%;

}#StockResponseDetails span:nth-child(3){
    position: absolute;
    left: 25.5%;
    width: 25%;
    text-align: center;

}#StockResponseDetails span:nth-child(4){
    position: absolute;
    left: 50%;

}#StockResponseDetails span:nth-child(5){
    position: absolute;
    left: 50.5%;
    width: 25%;
    text-align: center;

}#StockResponseDetails span:nth-child(6){
    position: absolute;
    left: 75%;

}#StockResponseDetails span:nth-child(7){
    position: absolute;
    left: 75%;
    width: 25%;
    text-align: center;

}
#CommentsPreviewSC{
    position: relative;
}
#LastCommentSC{
    position: relative;
    /* height: 17vw; */
}
#LastComment {
    font-size: 2.5vw;
    line-height: 3.5vw;
    padding: 3.5vw 0 0 3%;
    font-weight: 400;
    margin: 0;
    width: 75%;
}
#LastComment span{
    font-weight: bold;
    padding-right: 1vw;
}
#ResponseBack{
    position: absolute;
    top: 4.5vw;
    left: 80%;
    width: 3%;
}
#ResponseBackValue{
    position: absolute;
    top: 0vw;
    left: 84%;
    font-size: 2.5vw;
}
#Like{
    position: absolute;
    top: 4.5vw;
    left: 87%;
    width: 3%;
}
#LikeValue{
    position: absolute;
    top: 0vw;
    left: 91%;
    font-size: 2.5vw;
}
#ViewCommentsExchange {
    padding: 3vw 0 0 3%;
    font-weight: bold;
    font-size: 2.5vw;
    margin: 0;
    line-height: 2vw;
}
#ProfilePic{
    position: absolute;
    top: 41vw;
    left: 2%;
    width: 9%;
    border-radius: 50px;
}
#ProfilePic1 {
    position: absolute;
    /* display: inline; */
    /* top: 25vw; */
    left: 2%;
    width: 8vw;
    height: 8vw;
    /* margin-bottom: 0vw; */
    border-radius: 50px;
    background-position: 0px 0px;
    background-size: 8vw auto;
}
#AddCommentSaleOffer {
    position: relative;
    /* bottom: 4vw; */
    left: 11vw;
    width: 82%;
    border: none;
    background: none;
    /* height: 30vw; */
    font-size: 4vw;
    font-weight: normal;
    line-height: 9vw;
    padding: 0vw 1vw;
    border-radius: 20px;
}
#AddCommentSaleOffer::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    font-weight: normal;
    font-size: 3.5vw;
    /* opacity: 1; Firefox */
  }
#AddComment{
    position: absolute;
    top: 21.5vw;
    left: 14%;
    font-size: 2.5vw;
}