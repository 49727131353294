/* #ProfileDiv {
  position: absolute;
  top: 0vw;
  left: 0%;
  width: 100%;
  height: 150vw;
} */
.Influencer3rdProfile__BckgImage {
  position: relative;
  top: 15vw;
  left: 0%;
  width: 100%;
  height: 57vw;
  background-size: 100vw auto;
}
/* #StockPriceChangesTxt {
  position: absolute;
  top: 45vw;
  left: 75.65%;
  background-color: #1f1f1f;
  color: #34c100;
  border-radius: 15px;
  padding: 2.5vw;
  padding-bottom: 6vw;
  font-size: 3vw;
  line-height: 4vw;
  text-align: right;
} */
.Influencer3rdProfile__CreativeCardPosition {
  margin: 0vw auto;
  width: 84vw;
  height: 34vw;
}
.Influencer3rdProfile__ResponsesPos {
  position: relative;
  width: 84vw;
  margin: -1vw auto 16vw;
}
.Influencer3rdProfile__InteractBtnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 84vw;
  margin: 0 auto;
}
.Influencer3rdProfile__InvestBtn {
  padding: 0 auto;
  font-size: 4vw;
  line-height: 8vw;
  background-color: #ffbf12;
  border: 0.9vw solid #ffbf12;
  border-radius: 3vw;
  /* height: 8vw; */
  width: 35vw;
  margin: 0;
  text-align: center;
}
.Influencer3rdProfile__FollowTxtBtn {
  width: 35vw;
  font-size: 4vw;
  line-height: 8vw;
  border: 0.9vw solid #902366;
  border-radius: 3vw;
  /* height: 8vw; */
  margin: 0 2vw;
  text-align: center;
}
.Influencer3rdProfile__FollowingTxtBtn {
  font-size: 4vw;
  line-height: 8vw;
  border: 0.9vw solid #902366;
  background-color: #902366;
  border-radius: 3vw;
  width: 35vw;
  margin: 0 2vw;
  text-align: center;
}
.Influencer3rdProfile__SharingBtn {
  width: 10vw;
  height: 8vw;
  padding-top: 0.5vw;
}
.Influencer3rdProfile__BackFromShare {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
}

.Influencer3rdProfile__Metrics {
  width: 92vw;
  text-align: left;
  font-size: 3.5vw;
  line-height: 7vw;
  margin: 9vw 0 7vw 8vw;
}
.Influencer3rdProfile__Metrics span {
  color: #ffbf12;
  padding-right: 2vw;
}
.Influencer3rdProfile__Metrics img {
  position: relative;
  left: -2vw;
  width: 3.5vw;
}
.Influencer3rdProfile__AgentTagSizePos {
  width: 84vw;
  height: 13vw;
  margin: 5vw 8vw;
}
.Influencer3rdProfile__Description {
  width: 84vw;
  text-align: left;
  margin: 9vw 8vw;
  font-size: 4vw;
  line-height: 6vw;
}
.Influencer3rdProfile__RemainingStocks {
  font-size: 5vw;
  margin: 5vw 8vw;
  width: 84vw;
  line-height: 5vw;
}
.Influencer3rdProfile__RemainingStocks span {
  color: #ffbf12;
}
.Influencer3rdProfile__SocialMPos {
  position: relative;
  width: 84vw;
  height: 60vw;
  margin: 12vw 8vw;
}

.Influencer3rdProfile__StatsPrdctContainer {
  position: relative;
  width: 84vw;
  margin: 0 8vw;
}
.Influencer3rdProfile__PrdctStatsBtns {
  width: 84vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.Influencer3rdProfile__StatsBtn {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 5vw;
  text-align: center;
  margin: 0;
  line-height: 17vw;
  height: 0vw;
}
.Influencer3rdProfile__ProductsBtn {
  width: fit-content;
  font-size: 5vw;
  text-align: center;
  margin: 0;
  line-height: 6vw;
}
.Influencer3rdProfile__BasicLine {
  display: block;
  width: 84vw;
  margin: 3vw 0;
}
.Influencer3rdProfile__StatsSwitchLine {
  position: absolute;
  top: 14.8vw;
  left: 3vw;
  transition: right 0.5s;
}
.Influencer3rdProfile__PrdctSwitchLine {
  position: absolute;
  top: 14.8vw;
  right: 5vw;
  transition: left 0.5s;
}
.Influencer3rdProfile__StockInformation {
  position: relative;
  width: 100%;
  margin-top: 10vw;
}
.Influencer3rdProfile__GraphPos {
  position: relative;
  width: 100%;
  height: 100vw;
}

.Influencer3rdProfile__ProductsInformation {
  position: relative;
  width: 100%;
  /* height: 150vw; */
  margin-top: 10vw;
}
#SMIOPUDSMPos {
  position: absolute;
  top: 13vw;
  left: 0%;
  width: 100%;
}
#SMBuyBtn {
  width: fit-content;
  padding: 0 14.5vw;
  font-size: 4vw;
  line-height: 8vw;
  background-color: #902366;
  border: 3.3px solid #902366;
  border-radius: 8px;
  margin: 7vw auto 0;
}
