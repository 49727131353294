.Category {
  font-size: 0.7rem;
  margin: 0 3vw;
  color: white;
}

.Select {
  /* border: 1px solid white; */
  border-radius: 15px;
  padding: 0 3vw;
  background-color: #ffbf12;
}

.Options {
  width: 100%;
  display: flex;
  justify-content: center;
}
