#NotificationDiv{
    position: absolute;
    top: 15vw;
    left: 0%;
    width: 100%;
    padding-bottom: 8vh;
    transition: left .8s;
}
#NotificationDiv a:link,a:visited ,a:hover ,a:active {
    text-decoration: none;
  }
#BackPageNot {
    position: absolute;
    top: 6vw;
    left: 0%;
    width: 13vw;
    z-index: 35;
}

#Hide{
    display: none;
}
#UpdatesTxt{
    position: absolute;
    top: 1vw;
    left: 6%;
    width: 45.5%;
    height: 25vw;
    font-size: 4.7vw;
    line-height: 26vw;
    text-align: center;
    margin: 0;
    padding: 0;
}
#EducationTxt{
    position: absolute;
    top: 1vw;
    right: 3%;
    width: 45.5%;
    height: 25vw;
    font-size: 5vw;
    line-height: 26vw;
    text-align: center;
    margin: 0;
    padding: 0;
}
#UpdatesSubLine{
    position: absolute;
    top: 19vw;
    left: 8.5%;
}
#EducationSubLine{
    position: absolute;
    top: 19vw;
    right: 6.2%;
}

#UpdatesForm {
    position: absolute;
    top: 23vw;
    left: 0%;
    width: 100%;
    /* height: 100vw; */
}
#DemoUpdates{
    position: absolute;
    top: 0vw;
    left: 0;
    width: 100%;
    height: 100vw;
}
#SimleyUDemo{
    position: absolute;
    top: 8vw;
    left: 41vw;
    width: 19vw;
}
#UpdatesDemoTxt{
    position: absolute;
    top: 30vw;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 7.8vw;
    margin: 0;
    line-height: 11vw;
}

#UpdatesLogIn{
    position: absolute;
    top: 0vw;
    left: 0;
    width: 100%;
    height: 100vw;
}
#SimleyUCS{
    position: absolute;
    top: 8vw;
    left: 41vw;
    width: 19vw;
}
#UpdatesCSTxt{
    position: absolute;
    top: 30vw;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 7.8vw;
    margin: 0;
    line-height: 11vw;
}


#SignUpUDemoBtn{
    position: absolute;
    top: 80vw;
    left: 29vw;
    font-size: 5vw;
    line-height: 9vw;
    padding: 0 12vw;
    margin: 0;
    color: #ffbf12;
    background-color: white;
    border-radius: 25px;
}
#SignInUDemoBtn{
    position: absolute;
    top: 97vw;
    left: 29vw;
    font-size: 5vw;
    line-height: 9vw;
    padding: 0 12.7vw;
    margin: 0;
    color: #ffbf12;
    border: 1px solid white;
    border-radius: 25px;
}

#EducationForm{
    position: absolute;
    top: 23vw;
    left: 0;
    width: 100%;
    height: 100vw;
}
#SearchEducationInput{
    position: absolute;
    top: 8vw;
    left: 16vw;
    width: 60vw;
    border: none;
    border-radius: 10px;
    line-height: 6vw;
    text-align: center;
}
#SearchEducation{
    position: absolute;
    top: 8.85vw;
    left: 79vw;
    width: 4.5vw;
}

#EducationNotes{
    position: absolute;
    top: 23vw;
    left: 0vw;
    width: 100%;
}
#LineEducationNotes{
    position: relative;
    left: 5vw;
    margin: 2vw 0;
}
.EducationBtn{
    position: relative;
    top: 0vw;
    margin: 3vw 3vw;
    padding-left: 7vw;
    width: 93%;
    line-height: 23vw;
    font-size: 6vw;
    background-color: #1f1f1f;
}
.ArrowEducationBtn{
    position: absolute;
    top: 7vw;
    right: 11vw;
    width: 8.5vw;
}
.ArrowEducationBtn span{
    line-height: 7vw;
}
#PSVSSMBtn{
    position: relative;
    top: 0vw;
    /* margin: 4vw 0; */
    padding-left: 7vw;
    padding-top: 5vw;
    width: 93%;
    height: 24vw !important;
    line-height: 7vw;
    font-size: 6vw;
    background-color: #1f1f1f;
}
#ExtraSpaceEdctn{
    width: 100%;
    height: 10vw;
}