.BasicMenu {
  display: flex;
  flex-direction: column;
  /* margin-top: 60px; */
}
.BasicMenu button {
    display: flex;
    font-size: 16px;
    line-height: 40px;
    font-weight: lighter;
    width: 100%;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid white;
    background: 0;
    text-align: left;
    margin-top: -1px;
    padding-left: 8px;
    align-items: center;
    color: white;
    box-shadow: 0;
}
.BasicMenu img {
  width: 20px;
  padding-right: 8px;
  box-sizing: content-box;
}
