.SharePopUp {
    position: fixed;
    bottom: 23vw;
    left: 5vw;
    width: 90vw;
    height: 45vw;
    background-color: #e8e8e8;
    z-index: 100;
    color: black;
    border-radius: 6vw;
    border-bottom: 0.5vw solid #707070;
    border-left: 0.5vw solid #707070;
    box-shadow: 0 0 3vw 0 #ffbf12;
}
.SharePopUp p{
    font-size: 4vw;
    text-align: center;
}
.SharePopUp__Buttons {
    position: relative;
    display: inline-flex;
    width: 90vw;
    max-height: 25vw;
    overflow-x: scroll;
}
.SharePopUp__Buttons::-webkit-scrollbar{
    width: 0;
}
.SharePopUp__Button {
    display: inline-block;
    margin: 0 2.5vw;
    height: 18vw;
}
.SharePopUp__Button p {
    text-align: center;
    margin: 0;
    line-height: 0;
    font-weight:lighter;
    color: #363636;
    font-size: 3vw;
}