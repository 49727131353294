
#OpeningSlideHDiv::after {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    -ms-box-shadow: inset 0px 0px 10px 7px #121212;
    -o-box-shadow: inset 0px 0px 10px 7px #121212,
      inset 0px -18px 20px 20px #121212;
    box-shadow: inset 1px 1px 20px 20px #121212, inset 0px -18px 20px 20px #121212;
  }
#MenuHomeBlur{
    position: fixed;
    top: 3vw;
    right: 0%;
    width: 13vw;
    height: 8vw;
    z-index: 16;
    opacity: 0.2;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}
#MenuHome{
    position: fixed;
    top: 5vw;
    right: 3%;
    width: 6vw;
    z-index: 18;
}
#ChatHomeBlur{
    position: fixed;
    bottom: 24.5vw;
    left: 0%;
    width: 16vw;
    height: 13vw;
    z-index: 15;
    opacity: 0.5;
    background-color: white;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}
#ChatHome{
    position: fixed;
    bottom: 26vw;
    left: 3%;
    width: 10vw;
    z-index: 15;
}
.Slide1{
    position: relative;
    width: 100%;
    height: 160vw;
    overflow-y: hidden;
}
.Slide2{
    position: relative;
    width: 100%;
    height: 160vw;
    overflow-y: hidden;
}

.Backg{
    /* position:absolute;
    top:0px;
    left:0px; */
    clip:rect(0,100%,100%,0);
    width: 100%;
    height: auto;
}
.LongLogoS1{
    position: absolute;
    width: 86%;
    top: 53%;
    left: 5%;
}
.glow {
    font-size: 80px;
    color: #fff;
    text-align: center;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ffbf12, 0 0 40px #ffbf12, 0 0 50px #ffbf12, 0 0 60px #ffbf12, 0 0 70px #ffbf12;
  }
  

.ShortLogoWS2{
    position: absolute;
    width:58%;
    height: auto;
    top: 9%;
    left: 9%;
}
.LineS2{
    position: absolute;
    top: 38%;
    left: 9%;
}
.Slide2>p{
    position: absolute;
    top: 38%;
    left: 9%;
    text-align: left;
}

#WhereTxtHome{
    position: absolute;
    top: 106vw;
    left: 0;
    width: 100%;
    font-size: 8vw;
    line-height: 7vw;
    margin: 0;
    text-align: center;
}
#ScrollDivHomeS1{
    position: absolute;
    top: 114vw;
    left: 29vw;
    height: 10vw;
    width: 42vw;
    overflow: hidden;
}
#ScrollDivHomeS1 p{
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 8vw;
    line-height: 10vw;
    text-shadow:0 0 0.1vw #fff, 0 0 0.1vw #fff, 0 0 3.1vw #ffbf12, 0 0 3.1vw #ffbf12;
}
#InPeopleTxtHome{
    position: absolute;
    top: 125vw;
    left: 0;
    width: 100%;
    font-size: 8vw;
    line-height: 7vw;
    margin: 0;
    text-align: center;
}


