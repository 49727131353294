#SaleComplete{
    position: absolute;
    top: 0vw;
    left: -5%;
    width: 110%;
    height: 100vh;
    overflow: hidden;
}
#CelebrationPic{
    position: absolute;
    top:0vw;
    left:-10%;
    width: 120%;
}
#BlurWrap{
    position: absolute;
    top: 27vw;
    left:10%;
    width: 80%;
    height: 130vw;
    border-radius: 15px;
    overflow: hidden;
}
#BlurDiv{
    position: absolute;
    top: 0vw;
    left: 0%;
    width: 100%;
    height: 130vw;
    border-radius: 15px;
    background-color: rgb(255, 255, 255,0.3);
    filter: blur(2px);
    -webkit-filter: blur(3px);
}
#SaleMessage{
    position: absolute;
    top: 20vw;
    left:10%;
    width: 80%;
    height: 100vw;
}
#CelebrateSmiley {
    position: absolute;
    top: 15vw;
    left: 43.5vw;
    width: 23vw;
}
#SaleMessage h1{
    position: absolute;
    top: 20vw;
    left: 0%;
    width: 100%;
    font-size: 10vw;
    text-align: center;
}
#YouOwnTxt{
    position: absolute;
    top: 35vw;
    left: 0%;
    width: 100%;
    font-size: 5.7vw;
    text-align: center;
}
#YouOwnTxt spam{
    color: #FFBF12;
    font-size: 6.2vw;
}
#WrapStockCC{
    position: absolute;
    top: 51vw;
    left: 1.5%;
    width: 97%;
    
}
#ForTxtPrice{
    position: absolute;
    top: 82vw;
    left: 0%;
    width: 100%;
    font-size: 5.7vw;
    text-align: center;
}
#ForTxtPrice spam{
    color: #FFBF12;
    font-size: 6.2vw;
}
#ManageTxt{
    position: absolute;
    top: 96vw;
    left: 0%;
    width: 100%;
    font-size: 4vw;
    text-align: center;
}
#DoneComplete{
    position: absolute;
    top: 117vw;
    left: 43%;
    width: 16%;
}