.BtnCheck{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #34C100;
    border-radius: 20px;
    width: 100%;
    height: 100%;
}
.BtnNotCheck{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #707070;
    border-radius: 20px;
    width: 100%;
    height: 100%;
}
.SwitchCircleNotCheck{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    border-radius: 50px;
    width: 44%;
    height: 100%;
}
.SwitchCircleCheck{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 50px;
    width: 44%;
    height: 100%;
}