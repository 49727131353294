#WalletDivW::-webkit-scrollbar {
  width: 0 !important;
}
.Wallet {
  position: absolute;
  left: 0vw;
  width: 100%;
  transition: left 0.8s;
  padding-bottom: 50vw;
}
#axis {
  position: relative;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}
/* #DemoStatus{
    position: fixed;
    left:0;
    top:60vw;
    width: 20vw;
    height: 40vw;
    background-color: #24beff;
} */
.Wallet__Balance {
  position: relative;
  width: 100%;
  margin: 6vw 0;
  line-height: 9vw;
  font-size: 8vw;
  text-align: center;
}
#Total {
  position: relative;
  /* top: 126vw; */
  left: 5%;
  width: 90%;
  height: 65vw;
  text-align: left;
  background-color: #e8e8e8;
  box-shadow: 0 0 1.5vw #ffbf12;
  border-radius: 28px;
}
#Total > p {
  color: black;
}
#TotalEquity {
  position: absolute;
  top: -5vw;
  left: 5%;
  width: 100%;
  text-align: left;
  font-size: 12vw;
}
#TitleTotalEquity {
  position: absolute;
  top: 19.3vw;
  left: 5%;
  width: 100%;
  text-align: left;
  font-size: 4.3vw;
  line-height: 5vw;
  color: #353535 !important;
  margin: 0;
}
#WithdrawValue {
  position: absolute;
  top: 19vw;
  left: 5%;
  width: 100%;
  text-align: left;
  font-size: 11vw;
}
#TitleWithdrawValue {
  position: absolute;
  top: 41.5vw;
  left: 5%;
  width: 94%;
  text-align: left;
  font-size: 5vw;
  font-size: 4.3vw;
  line-height: 5vw;
  color: #353535 !important;
  margin: 0;
}
#ButtonDeposit {
  position: absolute;
  top: 50.5vw;
  left: 5%;
  width: 40%;
  text-align: center;
  font-size: 4vw;
  background-color: #ffbf12;
  border-radius: 8px;
  line-height: 0vw;
  color: black;
  font-weight: normal;
  box-shadow: 0 0 3px 1px #90909054;
}
#ButtonWithdraw {
  position: absolute;
  top: 50.25vw;
  left: 55%;
  width: 38%;
  height: 7vw;
  text-align: center;
  font-size: 4vw;
  line-height: 0vw;
  border: 2px solid #ffbf12;
  border-radius: 8px;
  color: black;
  box-shadow: inset 0px 0px 6px 0px #90909054, 0 0 6px #90909054;
}
#ButtonWithdraw > p {
  position: absolute;
  top: -0.35vw;
  left: 25%;
  font-weight: normal;
}
#WalletStatistics {
  position: absolute;
  top: 185vw;
  left: 0%;
  width: 100%;
  height: 130vw;
}
#WalletStatistics * {
  margin: 0;
  color: #121212;
}
#WalletStatistics h2 {
  position: absolute;
  left: 2vw;
  top: 4vw;
  font-size: 5vw;
  line-height: 5vw;
}
#WalletStatistics h3 {
  position: absolute;
  left: 2vw;
  top: 12vw;
  font-size: 4vw;
  line-height: 5vw;
  font-weight: normal;
}
#WalletStatistics p {
  position: absolute;
  left: 2vw;
  top: 26vw;
  font-size: 12vw;
  line-height: 5vw;
}
#WalletStatistics i {
  position: absolute;
  left: 2vw;
  top: 44vw;
  font-size: 4.5vw;
  line-height: 5vw;
}

#StockHolding {
  position: absolute;
  top: 5vw;
  left: 8%;
  width: 40%;
  height: 42vw;
  text-align: left;
  background-color: #e8e8e8;
  border-radius: 15px;
}
#Sector {
  position: absolute;
  top: 51vw;
  left: 8%;
  width: 40%;
  height: 63vw;
  text-align: left;
  background-color: #e8e8e8;
  border-radius: 15px;
}
#blurCurrentlySector {
  position: absolute;
  top: 9.8vw;
  left: 0%;
  width: 100%;
  height: 85%;
  opacity: 0.92;
  z-index: 4;
}
#CurrentlySectorTxt {
  position: absolute;
  top: 15.8vw;
  left: 8%;
  color: black;
  font-size: 5.5vw;
  line-height: 7.5vw;
  margin: 0;
  z-index: 5;
}
#SectorC {
  position: absolute !important;
  top: 19vw !important;
  left: 5vw !important;
  border: 9px solid #707070;
  border-radius: 66px;
  font-size: 10vw !important;
  line-height: 25vw !important;
  padding: 0 2vw;
}
#HalfC {
  position: absolute;
  top: 19.5vw;
  left: 39%;
  width: 21vw;
  height: 32.3vw;
}
#OfYourTxt {
  position: absolute;
  left: 2vw;
  top: 54vw;
  font-size: 4.5vw;
  line-height: 5vw;
}
#Return {
  position: absolute;
  top: 5vw;
  left: 52%;
  width: 40%;
  height: 52.5vw;
  text-align: left;
  background-color: #e8e8e8;
  border-radius: 15px;
}
#Dividends {
  position: absolute;
  top: 62vw;
  left: 51.5%;
  width: 40%;
  height: 52.5vw;
  text-align: left;
  background-color: #e8e8e8;
  border-radius: 15px;
}
#blurCurrentlyDividends {
  position: absolute;
  top: 9.8vw;
  left: 0%;
  width: 100%;
  height: 81%;
  opacity: 0.92;
  z-index: 4;
}
#CurrentlyDividendsTxt {
  position: absolute;
  top: 10.8vw;
  left: 8%;
  color: black;
  font-size: 5.5vw;
  line-height: 7.5vw;
  margin: 0;
  z-index: 5;
}
#WalletBWrap {
  position: absolute;
  top: 300vw;
  left: 5%;
  width: 90%;
  height: 130vw;
  margin: 0;
}
#WalletBWrap p {
  margin: 0;
  line-height: 5vw;
}
#WalletBTitle {
  position: absolute;
  top: 0vw;
  left: 0%;
  width: 100%;
  height: 33vw;
  background-color: #1f1f1f;
  border-radius: 28px;
  text-align: left;
  box-shadow: 0px 14px 9px -6px #121212;
  z-index: 4;
}
#WalletBText {
  position: absolute;
  top: 10vw;
  left: 5%;
  z-index: 5;
  font-size: 7vw;
  color: white;
  margin: 0;
  line-height: 5vw;
}
#ArrowDropDown {
  position: absolute;
  top: 11vw;
  left: 83%;
  width: 9%;
  z-index: 10;
}

#WalletBreakdown {
  display: block;
  position: absolute;
  top: 0vw;
  left: 0%;
  width: 100%;
  height: 183vw;
  background-color: #1f1f1f;
  border-radius: 28px;
  color: white;
}
#blurCurrentlyBreakdown {
  position: absolute;
  top: 9.8vw;
  left: 0%;
  width: 100%;
  height: 81%;
  opacity: 0.92;
  z-index: 4;
}
#CurrentlyBreakdownTxt {
  position: absolute;
  top: 10.8vw;
  left: 8%;
  color: black;
  font-size: 5.5vw;
  line-height: 7.5vw;
  margin: 0;
  z-index: 5;
}
#HideWalletB {
  display: none;
}

/* #AssetsTitle{
    position: absolute;
    top: 38.5vw;
    left: 5%;
    z-index: 5;
    font-size: 5vw;
}
#AssetsValue{
    position: absolute;
    top: 38.5vw;
    right: 4vw;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: right;
}
#AssetsList{
    position: absolute;
    top: 46vw;
    left: 0%;
    width: 100%;
    height: 23vw;
    background-color: #434343;
    border-radius: 28px;
    text-align: left;
}
.WBList p:nth-child(1){
    position: absolute;
    top: 5vw;
    left: 5%;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: left;
}
.WBList p:nth-child(2){
    position: absolute;
    top: 5vw;
    right: 4vw;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: right;
}
.WBList p:nth-child(3){
    position: absolute;
    top: 13vw;
    left: 5%;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: left;
}
.WBList p:nth-child(4){
    position: absolute;
    top: 13vw;
    right: 4vw;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: right;
}
.WBList p:nth-child(5){
    position: absolute;
    top: 21vw;
    left: 5%;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: left;
}
.WBList p:nth-child(6){
    position: absolute;
    top: 21vw;
    right: 4vw;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: right;
}
#ProfitsTitle{
    position: absolute;
    top: 74vw;
    left: 5%;
    z-index: 5;
    font-size: 5vw;
}
#ProfitsValue{
    position: absolute;
    top: 74vw;
    right: 4vw;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: right;
}
#ProfitsList{
    position: absolute;
    top: 82vw;
    left: 0%;
    width: 100%;
    height: 32vw;
    background-color: #434343;
    border-radius: 28px;
    text-align: left;
}
#AngelDustTitle{
    position: absolute;
    top: 119vw;
    left: 5%;
    z-index: 5;
    font-size: 5vw;
}
#AngelDustValue{
    position: absolute;
    top: 119vw;
    right: 4vw;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: right;
}
#AngelDustList{
    position: absolute;
    top: 127vw;
    left: 0%;
    width: 100%;
    height: 32vw;
    background-color: #434343;
    border: 1px solid #707070;
    border-radius: 28px;
    text-align: left;
}
#TotalTitle{
    position: absolute;
    top: 171vw;
    left: 5%;
    z-index: 5;
    font-size: 5vw;
}
#TotalValue{
    position: absolute;
    top: 172vw;
    right: 4vw;
    z-index: 5;
    width: 100%;
    font-size: 5vw;
    text-align: right;
} */
.TransPositionDown {
  position: absolute;
  top: 483vw;
  left: 0vw;
}
.TransPositionUp {
  position: absolute;
  top: 337vw;
  left: 0vw;
}
#Transactions {
  width: 100%;
  height: 70vw;
  color: white;
}

#TransactionsTitle {
  position: absolute;
  top: 0vw;
  left: 5%;
  width: 50%;
  text-align: left;
  font-size: 7vw;
}
#TransTitleLine {
  position: absolute;
  top: 19vw;
  left: 5%;
}
#MonthButton {
  position: absolute;
  top: 10.5vw;
  right: 3vw;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid white;
  border-radius: 20px;
  height: -moz-fit-content;
  height: fit-content;
}
#MonthButton > p {
  position: relative;
  top: 0vw;
  left: 0%;
  font-size: 3vw;
  text-align: center;
  line-height: 0vw;
  padding: 0 6vw;
}
#Columns {
  position: absolute;
  top: 16vw;
  left: 5%;
  width: 95%;
}
#Columns span:nth-child(1) {
  position: absolute;
  top: 0vw;
  left: 0%;
  font-size: 4vw;
}
#Columns span:nth-child(2) {
  position: absolute;
  top: 0vw;
  left: 13%;
  font-size: 4vw;
}
#Columns span:nth-child(3) {
  position: absolute;
  top: 0vw;
  left: 60%;
  font-size: 4vw;
}
#Columns span:nth-child(4) {
  position: absolute;
  top: 0vw;
  left: 80%;
  font-size: 4vw;
}
#TransListLine {
  position: absolute;
  top: 35vw;
  left: 5%;
}
#TransactionsList {
  position: absolute;
  top: 32vw;
  left: 0vw;
  width: 100%;
  height: 100vw;
}
#TransactionsList > ul {
  position: absolute;
  top: 0vw;
  left: 5%;
  width: 95%;
  font-size: 4vw;
  padding: 0;
  height: 50vw;
  overflow: scroll;
}
#TransactionsList li {
  list-style-type: none;
  width: 100%;
}
#TransactionsList span:nth-child(1) {
  position: relative;
  left: 0vw;
}
#TransactionsList span:nth-child(2) {
  position: absolute;
  left: 11vw;
}
#TransactionsList span:nth-child(3) {
  position: absolute;
  width: 25%;
  text-align: right;
  left: 43vw;
}
#TransactionsList span:nth-child(4) {
  position: absolute;
  left: 67vw;
  width: 25%;
  text-align: right;
}
