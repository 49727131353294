.IPOSelectAmount{
  padding: 15vw 0 5vw;
}
.IPOSelectAmount__BackBtn {
  position: absolute;
  top: 4vw;
  left: 0%;
  width: 13vw;
  z-index: 35;
}
.IPOSelectAmount__SaleCardAndComment {
  margin: 4vw 0;
}
.IPOSelectAmount__OnlyTxt {
  width: 100%;
  text-align: center;
  font-size: 9vw;
  line-height: 8vw;
  margin: 0;
}
.IPOSelectAmount__OnlyTxt span {
  font-size: 17vw;
  color: #ffbf12;
}
.IPOSelectAmount__HowTxt {
  width: 100%;
  text-align: center;
  font-size: 4.5vw;
  line-height: 6vw;
  margin: 0;
  color: #a7a7a7;
}
.IPOSelectAmount__InputAmount {
  text-align: center;
  width: 80vw;
  border-radius: 2vw;
  margin-top: 1.5vw;
  margin-bottom: 4vw;
}
.IPOSelectAmount__InputAmount::placeholder {
  text-align: center;
  color: #707070;
}
.IPOSelectAmount__PSTxt {
  position: absolute;
  top: 4vw;
  left: 16%;
  line-height: 6vw;
  font-size: 6vw;
  color: black;
  margin: 0;
}
.IPOSelectAmount__Line {
  display: block;
  margin: 0 auto 2vw;
}
.IPOSelectAmount__TotalPCTxt {
  width: 100%;
  text-align: center;
  font-size: 4vw;
  line-height: 4vw;
  margin: 0;
  font-weight: normal;
}
.IPOSelectAmount__BuyBtn {
  width: 90vw;
  line-height: 10vw;
  font-size: 4.5vw;
  text-align: center;
  margin: 0 5vw;
  background-color: #902366;
  border-radius: 10px;
}
