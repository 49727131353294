.FindAgency__FindTxt {
  font-size: 10.5vw;
  line-height: 12vw;
  margin: 33vw 8vw 4vw;
}
.FindAgency__AgenciesTxt {
  margin: 0 8vw 4vw;
  font-size: 4vw;
  line-height: 6vw;
}
.FindAgency__Line {
  margin-left: 8vw;
}

.FindAgency__JoinOffers {
  width: 100%;
}
.FindAgency__JoinOffersTxt {
  width: fit-content;
  color: #909090;
  font-size: 4.5vw;
  line-height: 4vw;
  font-weight: normal;
}
.JoinOfferInfoAllSlide {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 42vw;
}
.JoinOfferInfoAllSlide * {
  position: relative;
  top: 0;
  left: 0;
}
#AgencyJoinOfferCard {
  position: relative;
  top: 0vw;
  left: 8vw;
  width: 84%;
  height: 36vw;
  color: #ffffff;
  border: 2px solid #24beff;
  border-radius: 20px;
}
.AgencyPicJoinOffer {
  position: absolute;
  left: 4.5%;
  top: 13%;
  width: 22vw;
  height: 22vw;
  border: 5px solid #24beff;
  border-radius: 50%;
  box-shadow: 0px 7px 20px -7px black;
  background-size: 22vw auto;
}
#RatingNumAJOO {
  position: absolute;
  left: 27%;
  top: 24%;
  width: 10%;
  font-size: 5vw;
  line-height: 8vw;
  text-align: center;
  background-color: #24beff;
  border-radius: 50%;
  color: white;
  box-shadow: 0px 7px 20px -3px black;
}
.AgencyNameJoinOffer {
  position: absolute;
  left: 41%;
  top: 6vw;
  line-height: 6vw;
  font-size: 6vw;
  margin: 0;
}
.AgencyTxtJoinOffer {
  position: absolute;
  left: 41%;
  top: 13.5vw;
  font-size: 3vw;
  line-height: 3vw;
  font-weight: normal;
  margin: 0;
}
.AgencyEmailJoinOffer {
  position: absolute;
  left: 41%;
  top: 20.2vw;
  font-size: 3vw;
  line-height: 3vw;
  text-align: left;
  margin: 0;
}
.AgencyPhoneNJoinOffer {
  position: absolute;
  left: 41%;
  top: 24.2vw;
  font-size: 3vw;
  line-height: 3vw;
  text-align: left;
  margin: 0;
}
#JoinOfferAcceptBtn {
  position: relative;
  top: -5.2vw;
  left: 64vw;
  background-color: black;
  font-size: 4vw;
  line-height: 9vw;
  margin: 0vw 0;
  width: 14vw;
  color: #ffbf12;
  border: 2px solid #ffbf12;
  border-left: none;
  padding: 0px 4vw;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
#JoinOfferIgnoreBtn {
  position: relative;
  top: -15vw;
  left: 42vw;
  background-color: black;
  font-size: 4vw;
  line-height: 9vw;
  margin: 0vw 0;
  width: 14vw;
  color: #e66100;
  border: 2px solid #e66100;
  border-right: none;
  padding: 0px 4vw;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
#JoinOfferViewProfileBtn {
  position: relative;
  top: -5vw;
  left: 34vw;
  background-color: black;
  font-size: 4vw;
  line-height: 9vw;
  margin: 0vw 0;
  width: 14vw;
  border: 2px solid white;
  border-radius: 20px;
  padding: 0px 4vw;
}

.FindAgency__JoinOffersLine {
  position: relative;
  top: 0;
  left: 8%;
  margin: 2vw 0;
}

.AgencyItemList {
  position: relative;
  top: 0vw;
  left: 8%;
  width: 84%;
  height: 60vw;
}
#AgencyItemStarsLine {
  display: inline;
  position: absolute;
  top: 36.5vw;
  left: 0%;
  width: 52%;
}
.RateStars {
  display: inline;
  width: 17%;
  padding: 0.5vw;
}
.ViewAgencyBtn {
  position: absolute;
  top: 33vw;
  left: 61%;
  background-color: #ffbf12;
  border-radius: 10px;
  padding: 0 11vw;
  font-size: 4.5vw;
  line-height: 9vw;
}
