.animate-slide-to-top {
    top: 100vh !important;
}

.animate-to-left {
    left: 100vw !important;
    top: 0 !important;
}

.animate-to-right {
    right: 100vw !important;
    top: 0 !important;
}


.AuthPage {
    /* position: absolute;
    top: 0vw;
    left: 0%;
    width: 100%;
    height: 150vw; */
    transition: top 0.8s;
    overflow: hidden;
}

#Hide {
    display: none;
}


#None {
    display: none;
}

.CorrectInp {
    border: none;
}

/* .WrongInp {
    border: 0.5vw solid red !important;
} */
