.CreativeCard__TotalHolding {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 5px;
    z-index: 30;
  }
  .CreativeCard__TotalHolding p {
    margin: 0;
    line-height: 15px;
  }
  