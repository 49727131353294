.VSSlide{
    position: relative;
    width: 92%;
    height: 40vw;
    left: 4%;
    background-color: #902366;
    border-radius: 32px;
}
#VSS1WhatTxt,#VSS2WhyTxt,#VSS3WhenQstnTxt,#VSS4WhyTxt,#VSS5WhereTxt,#VSS6IfTxt{
    position: absolute;
    width: 100%;
    top: -10%;
    left: 0%;
    font-size: 4vw;
    text-align: center;
}
#VSS1StocksinTxt,#VSS2Because1Txt,#VSS3When1Txt,#VSS4To1Txt,#VSS5Ans1Txt,#VSS6AnsTxt{
    position: absolute;
    top: 36%;
    left: 5%;
    font-size: 3vw;
    line-height: 4vw;
    text-align: left;

}
#VSS1Circle{
    position: absolute;
    top: 12vw;
    left: 22%;
}
#VSS1facebook{
    position: absolute;
    top: 23vw;
    left: 37%;
    width: 12vw;
}
#VSS1instegram{
    position: absolute;
    top: 11vw;
    left: 38%;
    width: 11vw;
}
#VSS1youtube{
    position: absolute;
    top: 23vw;
    left: 18%;
    width: 12vw;
}
#VSS1tiktok{
    position: absolute;
    top: 12vw;
    left: 19%;
    width: 8.3vw;
}
#CharlisTxt{
    position: absolute;
    top: 17vw;
    left: 23.2%;
    font-size: 3vw;
    margin: 0;
    line-height: 4vw;
}
#VSS1Line,#VSS2Line{
    position: absolute;
    top: 39%;
    left: 48%;
}
#VSS1Stocksin2Txt,#VSS2Because2Txt,#VSS3When2Txt,#VSS4To2Txt,#VSS5Ans2Txt,#VSS6Ans2Txt{
    position: absolute;
    top: 36%;
    left: 54%;
    font-size: 3vw;
    line-height: 4vw;
    text-align: left;
}
#tesla{
    position: absolute;
    top: 20%;
    left: 70%;
}
#VSS2SmiExpPic{
    position: absolute;
    top: 27%;
    left: 20.75%;
}
#VSS2ElonMPic{
    position: absolute;
    top: 27%;
    left: 71%;
}