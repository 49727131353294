.MyAgencyDetails__BasicDetails {
  position: relative;
  margin: 20vw 5vw 0;
  padding: 3vw 0 6vw;
  width: 90vw;
  height: fit-content;
  background-color: #1f1f1f;
}
.MyAgencyDetails__ActiveSinceTxt {
  text-align: center;
  font-size: 3vw;
  line-height: 3vw;
  line-height: 5vw;
  margin: 0 auto;
  font-weight: normal;
}
.MyAgencyDetails__AvatarPosition {
  width: 27vw;
  height: 27vw;
  margin: 5vw auto;
}
.MyAgencyDetails__StarsLine {
  width: 50vw;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.RateStars {
  display: inline;
  width: 17%;
  padding: 0.5vw;
}
.MyAgencyDetails__Name {
  width: 100%;
  text-align: center;
  font-size: 7vw;
  line-height: 7vw;
  margin: 5vw 0 0;
}
.MyAgencyDetails__Email {
  font-size: 4.25vw;
  line-height: 4vw;
  margin: 5vw 5vw 3vw;
}
.MyAgencyDetails__PhoneNumber {
  font-size: 4.25vw;
  line-height: 4vw;
  margin: 0 5vw;
}

.MyAgencyDetails__Metrics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
  margin: 7vw auto;
}
.MyAgencyDetails__SquareData {
  width: 100%;
  margin: 0 1vw;
  height: 37vw;
  background-color: #2f2f2f;
  border-radius: 10px;
}
.MyAgencyDetails__SquareData p:nth-child(1) {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  margin: 30% auto 0;
  color: #ffbf12;
  font-size: 7vw;
}
.MyAgencyDetails__SquareData p:nth-child(2) {
  padding: 0 1vw;
  margin: 3vw auto 15%;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 3.3vw;
  text-align: center;
  line-height: 4vw;
}
.MyAgencyDetails__ActivityLog {
  position: relative;
  width: 90vw;
  margin: 15vw auto 0;
}

.MyAgencyDetails__ActivityLogTxt {
  font-size: 7vw;
  margin: 0;
}
.MyAgencyDetails__ActivityLine,.MyAgencyDetails__ActivityLListLine {
  position: relative;
  display: block;
  margin: 2vw 0 0;
}
.MyAgencyDetails__MonthSelectList {
  position: absolute;
  top: 3.5vw;
  right: 0vw;
  -webkit-appearance: none;
  appearance: none;
  background-color: black;
  text-align: center;
  color: white;
  padding: 0 3vw;
  font-size: 3.35vw;
  line-height: 6vw;
  border: 0.15vw solid white;
  border-radius: 10vw;
}
/* #ALMonthButton {
  position: absolute;
  top: 10.5vw;
  right: 3vw;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid white;
  border-radius: 20px;
  height: -moz-fit-content;
  height: fit-content;
}
#ALMonthButton > p {
  position: relative;
  top: 0vw;
  left: 0%;
  font-size: 3vw;
  text-align: center;
  line-height: 0vw;
  padding: 0 6vw;
} */
.MyAgencyDetails__ActivityColumnsHeads {
  font-size: 3.5vw;
  line-height: 4vw;
  margin: 4vw 0 2vw;
  width: 90vw;
  text-transform: none;
  color: #b8b8b8;
}
.MyAgencyDetails__ActivityColumnsHeads span:nth-child(1) {
}
.MyAgencyDetails__ActivityColumnsHeads span:nth-child(2) {
  position: relative;
  left: 6vw;
}
.MyAgencyDetails__ActivityColumnsHeads span:nth-child(3) {
  position: relative;
  left: 53vw;
}

.MyAgencyDetails__ActivityLListLine {
}
.MyAgencyDetails__ActivityLogList {
  position: relative;
  width: 100%;
  height: 65vw;
  overflow: scroll;
}
.MyAgencyDetails__ActivityLogList > ul {
  width: 95%;
  font-size: 4vw;
  padding: 0;
  height: 50vw;
  overflow: scroll;
}
.MyAgencyDetails__ActivityItem {
  list-style-type: none;
  width: 100%;
  height: 10vw;
}
.MyAgencyDetails__ActivityItem span:nth-child(1) {
  position: absolute;
  line-height: 6vw;
  margin: 0;
}
.MyAgencyDetails__ActivityItem span:nth-child(2) {
    position: absolute;
    left: 13.5vw;
    width: 59vw;
    line-height: 6vw;
    margin: 0;
}
.MyAgencyDetails__ActivityItem span:nth-child(3) {
  position: absolute;
  left: 75.5vw;
  line-height: 6vw;
  margin: 0;
}

.MyAgencyDetails__LeaveBtn {
  font-size: 4.5vw;
  line-height: 9vw;
  border: 3px solid #902366;
  border-radius: 9px;
  padding: 0 11vw;
  width: fit-content;
  margin: 5vh auto 5vh;
}
