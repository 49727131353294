.GeneralAppMessage {
    position: fixed;
    padding: 5vw;
    background: #363636;
    border-radius: 4.5vw;
    margin: 0;
    width: 84vw;
    box-shadow: 0 0vw 2.5vw 0 #fff;
    z-index: 60;
}
.GeneralAppMessage__Txt {
    font-size: 4.8vw;
    text-align: center;
    line-height: 6vw;
    margin: 0 auto 7vw;
    color: white;
}
.GeneralAppMessage__buttonsContainer{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
.GeneralAppMessage__Btn {
    border: 0;
    font-size: 4.3vw;
    height: 7vw;
    width: 14vw;
    text-align: center;
    border-radius: 10vw;
}