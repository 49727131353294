.CreatorsHub__ManagersHubBtn {
    position: fixed;
    top: 3vw;
    right: 0%;
    z-index:100;
    margin: 0;
    padding-left: 4vw;
    padding-right: 2vw;
    line-height: 7vw;
    font-size: 4vw;
    color: #121212;
    text-transform: none;
    background-color: #fff;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border: 0;

}

.WellcomeMC__BackBtn {
  position: absolute;
  top: 7vw;
  left: 0;
  width: 12vw;
  z-index: 90;
  /* border: 0.5vw solid steelblue; */
  box-shadow: 0px 4vw 14.5vw 0vw black;
  /* border-left: 0; */
  border-bottom-right-radius: 10vw;
  border-top-right-radius: 10vw;
}
.WellcomeMC__BackgroundImg {
  width: 100%;
  height: 97vw;
  background-position: 0 0;
  background-size: contain;
  z-index: -1;
}
.CreatorsHub__WelcomeTxt {
  width: 100%;
  text-align: center;
  font-size: 6vw;
  line-height: 7vw;
  margin: -29vw 0;
  z-index: 2;
}
.CreatorsHub__Shadow {
  margin: 38vw 0 0 0;
  width: 100%;
  height: 30vw;
  background-color: #121212;
  box-shadow: 0 -12vw 11vw 8vw #121212;
}

.CreatorsHub__QuotationMCBegin {
  position: absolute;
  top: 92vw;
  left: 8vw;
  width: 13%;
}
.CreatorsHub__CreatingTxt {
  width: 100%;
  text-align: center;
  font-size: 3.2vw;
  line-height: 5vw;
  margin: -34vw 0;
  font-weight: 500;
}
.CreatorsHub__QuotationMCEnd {
  position: absolute;
  top: 114vw;
  left: 84vw;
  width: 13%;
}
.CreatorsHub__BtnsContainer {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 90vw;
  flex-direction: row;
  margin: 0 auto;
}
.CreatorsHub__Btn {
  font-size: 3.5vw;
  line-height: 10vw;
  margin: 0;
  padding: 0 2.2vw;
  text-align: center;
  color: #ffbf12;
}

.WellcomeMC__selectedBtn {
    position: absolute;
    top: -1.2vw;
    height: 10vw;
    border: 0.7vw solid #ffffff;
    border-radius: 10vw;
    transition: .5s;
    margin: 0;
}
/* .WellcomeMC__WhatsTheVibeDiv{
    width: 100%;
    height: 120vw;
} */
.CreatorsHub__CheckBtn {
  width: -moz-fit-content;
  width: fit-content;
  margin: 15vw auto ;
  padding: 0 4vw;
  font-size: 3.75vw;
  line-height: 7vw;
  border: 0.75vw solid white;
  background: 0;
  color: #ffbf12;
  border-radius: 10vw;
  position: relative;
  display: block;
  text-transform: uppercase;
}