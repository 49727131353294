
#PassionStockDiv{
    position: absolute;
    top:15vw;
    left:0;
    width: 100%;
    height: 578vw;
    overflow-x: hidden;
}
#PSTitle {
    position: absolute;
    top: 25vw;
    left: 8vw;
    width: 84vw;
    padding-left: 9vw;
    line-height: 18vw;
    margin: 0;
    font-size: 5.5vw;
    background-color: #292929;
    border-radius: 14px;
    text-align: center;
}
#PSPic1 {
    position: absolute;
    top: 30vw;
    left: 10vw;
    width: 19.5vw;
}
#WhatQstTitlePS {
    position: absolute;
    top: 52vw;
    left: 10vw;
    line-height: 5vw;
    margin: 0;
    font-size: 4vw;
    font-weight: normal;
}
#WhatQstTitlePS span,#WhereQstPS span,#WhatDoQstTPC span{
    font-size: 5vw;
    font-weight: bold;
    color: #ffbf12;
}
#PSPic2 {
    position: absolute;
    top: 94vw;
    left: 14vw;
    width: 70vw;
}
#WhereQstPS {
    position: absolute;
    top: 155vw;
    left: 10vw;
    font-size: 4vw;
    line-height: 5vw;
    font-weight: normal;
    margin: 0;
}
#PSPic3 {
    position: absolute;
    top: 189vw;
    left: 10vw;
    width: 80vw;
}
#IPOBtnPS {
    position: absolute;
    top: 308vw;
    left: 8vw;
    color: #ffbf12;
    font-size: 4vw;
    line-height: 5vw;
    margin: 0;
}
#PostIPOBtnPS {
    position: absolute;
    top: 308vw;
    right: 7vw;
    color: #ffbf12;
    font-size: 4vw;
    line-height: 5vw;
    margin: 0;
}
#IPOSubLine {
    position: absolute;
    top: 315vw;
    left: 12.5vw;
}
#PostIPOSubLine {
    position: absolute;
    top: 315vw;
    right: 18vw;
}
#IPOExpPS{
    position: absolute;
    left: 0;
    top: 325vw;
    width: 100%;
    height: 50vw;
}
#IPOCardPS{
    position: absolute;
    top: 5vw;
    left: 5vw;
    width: 90%;
    height: 33vw;
}
#IPOCardExpPS {
    position: absolute;
    top: 46vw;
    left: 9vw;
    font-size: 4vw;
    line-height: 5vw;
    font-weight: normal;
    margin: 0;
}

#Post-IPOExpPS{
    position: absolute;
    left: 0;
    top: 325vw;
    width: 100%;
    height: 50vw;
}
#PostIPOCardPS{
    position: absolute;
    top: 5vw;
    left: 5vw;
    width: 90%;
    height: 34vw;
}
#PostIPOCardExpPS {
    position: absolute;
    top: 46vw;
    left: 9vw;
    font-size: 4vw;
    line-height: 5vw;
    font-weight: normal;
    margin: 0;
}

#WhatValuesPS{
    position: absolute;
    top:412vw;
    left: 9vw;
    font-size: 6vw;
    line-height: 6vw;
    margin: 0;
}

#CardExpBtnPS {
    position: absolute;
    top: 434vw;
    left: 0%;
    width: 87%;
    height: 52vw;
}
.StockCardPS{
    position: relative;
    left: 7vw;
    height: 31vw;
    background-color: #1F1F1F;
    margin-bottom: 5vw;
    box-shadow: 0px 14px 9px -6px #121212;
    z-index: 5;
}
.OpacityPS{
    opacity: 0.6;
}
.BoldPS{
    z-index: 5;
    filter: brightness(1.5);
}
.BorderPS{
    border:2px solid #ffffff;
    border-radius: 25px;
    padding: 0 2vw;
}
.BorderTradePS {
    border: 2px solid #ffffff;
    border-radius: 16px;
    padding: 2vw;
    padding-bottom: 0.5vw;
}

#LCBorderPS {
    position: absolute;
    top: 24%;
    right: 1%;
    width: fit-content;
    height: 10vw;
    margin: 0;
}
.PCChangeFromLastTrnsctnPS {
    position: relative;
    top: 0%;
    right: 0%;
    font-size: 2.5vw;
    line-height: 2.7vw;
    text-align: right;
    margin: 0;
}
.StockPercentChangeFromLastTrnsctnPS {
    position: relative;
    top: 0%;
    right: 0%;
    font-size: 2.5vw;
    line-height: 2.7vw;
    text-align: right;
    overflow: hidden;
    margin: 0;
}

#ArrowRightCardPicPS{
    position: absolute;

    width: 15vw;
    z-index: 7;
}
.ARRankPosPS {
    top: 22vw;
    left: 3vw;
}
.ARRatePosPS {
    top: 17vw;
    left: 19vw;
}
.ARMarketVPosPS {
    top: 24vw;
    left: 27vw;
}

#ArrowLeftCardPicPS{
    position: absolute;

    width: 15vw;
    z-index: 7;
}
.ALTradePosPS {
    top: 19vw;
    left: 84vw;
}
.ALTradingPosPS {
    top: 18vw;
    left: 68vw;
}

.Hide{
    display: none;
}

#RankingExpDivPS{
    position: absolute;
    top: 43vw;
    left: 7vw;
    width: 100vw;
}
#RankExpPicPS {
    position: absolute;
    top: 0;
    left: 11vw;
    width: 67vw;
}
#RankingExpPS {
    position: absolute;
    top: 40vw;
    left: 0vw;
    line-height: 5vw;
    margin: 0;
    font-size: 4vw;
    font-weight: normal;
}
#RankingExpPS span{
    font-size: 5vw;
    font-weight: bold;
    color: #ffbf12;
}

#RatingExpDivPS{
    position: absolute;
    top: 43vw;
    left: 7vw;
    width: 100vw;
}
#RateExpPicPS {
    position: absolute;
    top: 0;
    left: 23vw;
    width: 39vw;
}
#RatingExpPS {
    position: absolute;
    top: 46vw;
    left: 0vw;
    line-height: 5vw;
    margin: 0;
    font-size: 4vw;
    font-weight: normal;
}
#RatingExpPS span{
    font-size: 5vw;
    font-weight: bold;
    color: #ffbf12;
}

#MarketValueExpDivPS{
    position: absolute;
    top: 43vw;
    left: 7vw;
    width: 100vw;
}
#MVExpPicPS {
    position: absolute;
    top: 2vw;
    left: 0vw;
    width: 93vw;
}
#MVExpPS {
    position: absolute;
    top: 23vw;
    left: 0vw;
    line-height: 5vw;
    margin: 0;
    font-size: 4vw;
    font-weight: normal;
}
#MVExpPS span{
    font-size: 5vw;
    font-weight: bold;
    color: #ffbf12;
}

#TradeValuesExpDivPS{
    position: absolute;
    top: 43vw;
    left: 7vw;
    width: 100vw;
}
#TradeVExpPS {
    position: absolute;
    top: 10vw;
    left: 0vw;
    line-height: 5vw;
    margin: 0;
    font-size: 4vw;
    font-weight: normal;
}
#TradeVExpPS span{
    font-size: 5vw;
    font-weight: bold;
    color: #ffbf12;
}

#TradingVExpPS{
    position: absolute;
    top: 43vw;
    left: 7vw;
    width: 100vw;
}
#TradingVExpPicPS {
    position: absolute;
    top: 2vw;
    left: 0vw;
    width: 93vw;
}

#RightPicPS {
    position: absolute;
    top: 120vw;
    right: -6vw;
}
#LeftPicPS{
    position: absolute;
    top: 120vw;
    left: 7vw;
}

