.CreatorsHubBtn{
    position: fixed;
    top: 3vw;
    left: 0%;
    z-index:100;
    margin: 0;
    padding-left: 3vw;
    padding-right: 4vw;
    line-height: 8vw;
    font-size: 4.75vw;
    color: white;
    text-transform: none;
    background-color: #ffbf12;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}