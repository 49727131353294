.SignUpUsername__UsernameInput {
  background: none;
  border: none;
  border-radius: 10vw;
  width: 90vw;
  font-size: 6.5vw;
  text-align: center;
  display: block;
  margin: 0 auto;
  color: white;
  text-shadow: 0 0 2vw #ffbf12, 0 0 2vw #ffbf12;
}
.SignUpUsername__UsernameInput::placeholder {
  color: white;
  font-size: 6.5vw;
}
.SignUpUsername__UsernameInput:focus{
  border: none;
}
.SignUpUsername__Txt {
  font-size: 1.5em;
  line-height: 1.1em;
  text-align: center;
  margin: 0 auto;
  width: 90vw;
}
.SignUpUsername__ConfirmBtn {
  display: block;
  margin: 4vh auto 0;
  background: 0;
  color: white;
  border: 1vw solid #902366;
  border-radius: 2.5vw;
  font-size: 01em;
  line-height: 1.5em;
}
.SignUpUsername__SkipBtn {
  position: fixed;
  bottom: 5vh;
  right: 0;
  color: black;
  background: white;
  font-size: 0.7em;
  line-height: 3.2em;
  padding-left: 4vw;
  border-top-left-radius: 10vw;
  border-bottom-left-radius: 10vw;
}
