
#SocialMediaValuesDiv{
    position: absolute;
    top: 0;
    left: 0;
    width: 84vw;
    height: 60vw;
}
#SocialMediaValuesDiv *{
    margin: 0;
    padding: 0;
}
#left{
    position: absolute;
    top: 0;
    left: 24%;
    width: 12%;
    z-index: 2;
}
#Ymiddle{
    position: absolute;
    top: 0;
    left: 39%;
    width: 22%;
    z-index: 2;
}
#right{
    position: absolute;
    top: -0.5vw;
    left: 62%;
    width: 16%;
    z-index: 2;
}
#SMValuesDiv{
    position: absolute;
    top: 8vw;
    left: 0%;
    width: 100%;
    height: 50vw;
    background-color: #2E2E2E;
    border-radius: 10px;
}
#SMUserName{
    position: absolute;
    top: 8vw;
    left: 0%;
    width: 100%;
    text-align: center;
    font-size: 5vw;
}
#FollowsV{
    position: absolute;
    top: 20vw;
    left: 0%;
    width: 33%;
    text-align: center;
    font-size: 8vw;
    color: #FFBF12;
}
#FollowsTxt{
    position: absolute;
    top: 36vw;
    left: 0%;
    width: 33.3%;
    text-align: center;
    font-size: 4vw;
    line-height: 6vw;
}
#EngRV{
    position: absolute;
    top: 20vw;
    left: 33.3%;
    width: 33.3%;
    text-align: center;
    font-size: 8vw;
    color: #FFBF12;
}
#EngRateTxt{
    position: absolute;
    top: 36vw;
    left: 33.3%;
    width: 33.3%;
    text-align: center;
    font-size: 4vw;
    line-height: 6vw;
}
#TotalLV{
    position: absolute;
    top: 20vw;
    left: 66.6%;
    width: 33.3%;
    text-align: center;
    font-size: 8vw;
    color: #FFBF12;
}
#TotalLikesTxt{
    position: absolute;
    top: 36vw;
    left: 66.6%;
    width: 33.3%;
    text-align: center;
    font-size: 4vw;
    line-height: 6vw;
}