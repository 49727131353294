#PrivatePolicyDiv{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}
#BackPagePrP {
    position: absolute;
    top: 2vh;
    left: 0%;
    width: 13vw;
    z-index: 55;
    box-shadow: 0 0 0 black;
}
#PrivateScrollDiv {
    position: absolute;
    /* top: 10vh; */
    left: 5vw;
    width: 90vw;
    /* height: 80vh; */
    padding: 5vw;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 3.3vw;
    line-height: 4.5vw;
    border-radius: 10px;
    background-color: #ffffff;
    color: black;
    font-weight: normal;
}
#PrivateScrollDiv span{
    font-weight: bolder;
}
#PrivateScrollDiv i{
    font-style: normal;
    color: red;
}