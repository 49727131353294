
.TradeDetails{
    padding-top:15vw;
}
.TradeDetails__BackBtn {
  position: absolute;
  top: 20vw;
  left: 0%;
  width: 13vw;
  z-index: 35;
}

.TradeDetails__SellTxt {
  font-size: 5vw;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  line-height: 6vw;
  color: #b7b7b7;
}

.TradeDetails__AmountInput {
    box-sizing: border-box;
  width: 80vw;
  font-size: 5vw;
  height: 9vw;
  border-radius: 2vw;
  text-align: center !important;
}
.TradeDetails__AmountInput::placeholder {
  text-align: center !important;
  color: #b7b7b7;
  font-weight: lighter;
}

.TradeDetails__InputPSTxt {
  position: absolute;
  top: 1.4vw;
  left: 7vw;
  font-size: 5.5vw;
  color: #2d2d2d;
  z-index: 1;
  margin: 0;
  line-height: 6vw;
}

.TradeDetails__ForTxt {
  width: 100%;
  font-size: 5vw;
  text-align: center;
  margin: 10vw 0 1vw;
  line-height: 6vw;
  color: #b7b7b7;
}

.TradeDetails__PriceVolumeInput {
  width: 80vw;
  font-size: 5vw;
  height: 9vw;
  border-radius: 2vw;
  text-align: center !important;
}
.TradeDetails__PriceVolumeInput::placeholder {
  text-align: center !important;
  color: #b7b7b7;
  font-weight: lighter;
}
.TradeDetails__CurrencyCodeTxt {
  position: absolute;
  top: 1.4vw;
  left: 7vw;
  font-size: 5vw;
  color: #2d2d2d;
  z-index: 1;
  margin: 0;
  line-height: 6vw;
}

.TradeDetails__EachTxt {
  position: absolute;
  top: 1.3vw;
  right: 7vw;
  font-size: 5vw;
  color: #2d2d2d;
  z-index: 1;
  margin: 0;
  line-height: 6vw;
}

.TradeDetails__BranfFeeTxt,.TradeDetails__TotalTxt {
  font-size: 4vw;
  font-weight: normal;
  margin: 0 auto;
  width: fit-content;
}
.TradeDetails__Line {
  display: block;
  margin: 0 auto;
}
.TradeDetails__HashtagsTxt {
  width: 100%;
  font-size: 5vw;
  text-align: center;
  margin: 0 0 2.5vw;
  line-height: 6vw;
  color: #b7b7b7;
}

.TradeDetails__ToIncreaseTxt {
  width: 100%;
  font-size: 3.5vw;
  line-height: 4vw;
  font-weight: normal;
  text-align: center;
  margin: 0;
  color: #b7b7b7;
}

.TradeDetails__HashtagsInput {
  width: 80vw;
  font-size: 4vw;
  line-height: 8vw;
  border-radius: 2vw;
  text-align: left;
  font-weight: normal;
  padding: 0 4vw;
}

.TradeDetails__HashtagsInput:focus::placeholder {
  color: transparent;
}

.TradeDetails__LoaderPosSize {
  width: 5vw;
  height: 5vw;
  margin: 0 auto;
}
.TradeDetails__ClockPic {
  display: inline;
  width: 3.5vw;
  margin-right: 2vw;
}
.TradeDetails__ExpiresTxt {
  display: inline;
  font-size: 3vw;
  line-height: 4vw;
  font-weight: normal;
  margin: 0;
}

.TradeDetails__CreateTradeBtn {
  width: 90vw;
  padding: 0 1vw;
  font-size: 5vw;
  line-height: 10vw;
  text-align: center;
  background-color: #ffbf12;
  border-radius: 25px;
  margin: 2vw 0;
}
