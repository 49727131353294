.animate-to-left {
    left: 100vw !important;
    top: 0 !important;
    margin-left:100vw ;
}

.animate-to-right {
    right: 100vw !important;
    top: 0 !important;
    margin-right: 100vw !important;
}

.LoginForm {
  position: relative;
  display: flex;
  width: 100vw;
  transition: left 0.5s;
  padding: 0 8vw;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
}
.LoginForm__WelcomeTxt {
  margin: 14vw auto 0 auto;
  font-size: 6.8vw;
  width: fit-content;
}

.LoginForm__Emoji {
  position: relative;
  top: 4vw;
  width: 17vw;
  padding-left: 3vw;
}
/* .LoginForm__inputs {
} */
.LoginForm__InputEmail {
  width: 100%;
  height: 11vw;
  font-family: "open-sans";
  font-weight: bold;
  font-size: 4.25vw;
  line-height: 10vw;
  padding: 0vw 3vw;
  border-radius: 10vw;
}

.LoginForm__InputPassword {
  width: 100%;
  height: 11vw;
  display: inline;
  /* font-size: 4.25vw; */
  /* font-family: "open-sans";
  font-weight: bold; */
  /* line-height: 10vw; */
  padding: 0vw 11vw 0 3vw;
  /* border-radius: 10vw; */
  margin: 4vw 0 0 0;
}
/* .LoginForm__InputPassword::placeholder,
.LoginForm__InputEmail::placeholder {
  text-align: left;
} */

.LoginForm__EyeImgPassword {
    position: absolute;
    top: 7.5vw;
    right: 5vw;
    width: 6vw;
}
.LoginForm__indicate-container{
  height: 12vw;
  padding-top: 5vw;
}


/* .LoginForm__submit-btns {
} */
.LoginForm__ForgotBtn {
  width: fit-content;
  margin: 0 auto;
  color: #666666;
  font-size: 4vw;
}

.LoginForm__LogInButton {
  width: 100%;
  color: #ffbf12;
  text-align: center;
  font-size: 4vw;
  line-height: 10vw;
  background-color: white;
  border-radius: 10vw;
}

#LogInByFacebook {
  position: absolute;
  top: 113vw;
  left: 13%;
  width: 37%;
  font-size: 4vw;
  text-align: center;
  height: 9vw;
  background-color: #4167b2;
  border-radius: 20px;
}

#FacebbokTxtBtn {
  position: absolute;
  top: -1vw;
  left: 33%;
  text-align: center;
  z-index: 10;
  padding: 0;
  line-height: 4vw;
}

#FacebookLogoLog {
  position: absolute;
  top: 1.25vw;
  left: 4.5%;
  width: 19%;
}

#LogInByGoogle {
  position: absolute;
  top: 109vw;
  left: 52%;
  width: 37%;
  font-size: 4vw;
  color: black;
  line-height: 9vw;
  text-align: center;
  background-color: white;
  border-radius: 20px;
}

#EnterYourEmailTxt {
  position: absolute;
  top: 72vw;
  left: 13%;
  font-size: 5vw;
  line-height: 6vw;
  margin: 0;
}

#InputEmailForSendRP {
  position: absolute;
  top: 92vw;
  left: 13%;
  width: 69%;
  font-family: "open-sans";
  font-weight: bold;
  font-size: 4vw;
  padding: 1.5vw 3vw;
  border-radius: 20px;
}

#SendResetPasswordBtn {
  position: absolute;
  top: 123vw;
  left: 12%;
  width: 76%;
  color: #ffbf12;
  text-align: center;
  font-size: 4vw;
  line-height: 9vw;
  background-color: white;
  border-radius: 20px;
}

#NewPasswordForm {
  position: absolute;
  top: 42vw;
  left: 0%;
  width: 100%;
  height: 100v;
}

#ClickResetTxt {
  position: absolute;
  top: 46vw;
  left: 13%;
  font-size: 6vw;
  line-height: 9vw;
}
