#OpeningViewDiv{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffbf12;
    z-index: 1000;
}
#OpeningLogo{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -7.5vw;
    margin-left: -15vw;
    width: 30vw;
    -webkit-animation : pulse 1.75s 1;
    -moz-animation    : pulse 1.75s 1;
    -o-animation      : pulse 1.75s 1;
    animation         : pulse 1.75s 1;
}
@keyframes pulse {
    0%   {  width: 100vw;  
         top: 50%;
        left: 50%;
        margin-top: -25vw;
        margin-left: -50vw;}
    75% { width: 20vw; 
        top: 50%;
        left: 50%;
        margin-top: -5vw;
        margin-left: -10vw;   }
    100%{ width: 30vw;    }
}
@-webkit-keyframes pulse {
    0%   {  width: 100vw; 
        top: 50%;
        left: 50%;
        margin-top: -25vw;
        margin-left: -50vw;}
    75% { width: 20vw; 
        top: 50%;
        left: 50%;
        margin-top: -5vw;
        margin-left: -10vw;   }
    100%{ width: 30vw;    }
 }