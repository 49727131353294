.FPEmailAddress__Emoji {
  /* position: absolute;
    top: 35vw;
    left: 41vw; */
  display: block;
  width: 18vw;
  margin: 15vh auto 0 auto;
}
.FPEmailAddress__EnterTxt {
  width: fit-content;
  text-align: center;
  font-size: 6.35vw;
  line-height: 8vw;
  margin: 6vh auto;
}

.FPEmailAddress__EmailInput {
  width: 84vw;
}

.FPEmailAddress__indicate-container {
  height: 12vw;
  padding-top: 5vw;
}
