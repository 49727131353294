.MenuBtn {
  position: fixed;
  top: 3vw;
  right: 0%;
  z-index: 18;
}
.MenuBtn__Blur {
  width: 13vw;
  height: 8vw;
  z-index: 16;
  opacity: 0.2;
  background-color: white;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
.MenuBtn__Icon {
  position: absolute;
  top: 2vw;
  right: 25%;
  width: 6vw;
}
