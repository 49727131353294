.Desktop__MessageContainer {
  display: flex;
  margin: auto;
  padding: 1em;
  border-radius: 1em;
  border: 0.025em solid white;
  background: #4d4b4b;
  box-shadow: 0px 0px 45px -9px #ffbf12;
  flex-direction: column;
  justify-content: space-evenly;
}
.Desktop__PSLogo {
  display: block;
  margin: 0 auto;
  width: 9.5em;
}
.Desktop__Text {
  font-size: 70%;
  text-align: center;
}
.Desktop__Button {
  font-size: 70%;
  width: -moz-fit-content;
  width: fit-content;
  /* line-height: 2em; */
  padding: 0 2em;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  border: 0;
  border-radius: 10vw;
}
