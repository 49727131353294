.AgentCreativeCard {
  position: relative;
  width: 100%;
  height: 34vw;
  color: #2e2e2e;
  box-shadow: 0px 13px 9px -6px #121212;
  z-index: 12;
}
#AgencyCreativeCard * {
  margin: 0;
  line-height: 5vw;
}
.AgentCreativeCard__opacityBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.87;
}
.AgentCreativeCard__Avatar {
  position: absolute;
  left: 4vw;
  top: 5.5vw;
  width: 23vw;
  height: 23vw;
  border: 5px solid #24beff;
  border-radius: 50%;
  box-shadow: 0px 7px 20px -7px black;
  background-size: 22vw 22vw;
}
.AgentCreativeCard__Rating {
  position: absolute;
  left: 23.3vw;
  top: 12vw;
  font-size: 3vw;
  line-height: 6.5vw;
  padding: 0 2.3vw;
  text-align: center;
  background-color: #24beff;
  border-radius: 50%;
  color: white;
  box-shadow: 0px 7px 20px -3px black;
}
.AgentCreativeCard__DataContainer {
  position: absolute;
  top: 4vw;
  left: 32vw;
  width: 52vw;
  height: 28vw;
}
.AgentCreativeCard__Name {
  font-size: 5vw;
  margin: 2vw 0;
  line-height: 5vw;
  text-align: left;
}
.AgentCreativeCard__NameLong {
  margin: 2vw 0;
  line-height: 5vw;
  font-size: 4vw;
  text-align: left;
}
.AgentCreativeCard__StockPrice {
  margin: 2vw 0;
  font-size: 5vw;
  line-height: 5vw;
  text-align: left;
}
.AgentCreativeCard__Arrow {
  position: relative;
  top: 0vw;
  width: 5.5vw;
  padding-right: 1.5vw;
}
.AgentCreativeCard__AgencyTxt {
  font-size: 3vw;
  font-weight: normal;
  margin: 1.5vw 0;
  line-height: 3vw;
  text-align: left;
}
.AgentCreativeCard__Location {
  font-size: 3vw;
  margin: 1.5vw 0;
  line-height: 3vw;
  text-align: left;
}
