.WithdrawRequestReceived__BackBtn {
  position: absolute;
  top: 20vw;
  left: 0%;
  width: 13vw;
  z-index: 35;
}
.WithdrawAmount__Balance {
  width: 100%;
  text-align: center;
  margin: 0 auto 7vh;
  font-size: 14vw;
  line-height: 10vw;
  text-shadow: 0 0 6vw #ffbf12, -0.25vw 0.25vw #ffbf12, 0.25vw -0.25vw #ffbf12,
    0.25vw 0.25vw #ffbf12, -0.25vw -0.25vw 0 #ffbf12;
}
.WithdrawAmount__WithdrawalAmountTxt {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 4.5vw;
  line-height: 4vw;
  color: #707070;
}
.WithdrawAmount__AmountInput {
  width: 84%;
  font-size: 5vw;
  height: 10vw;
  color: #363636;
  border-radius: 2vw;
  /* border: none; */
  text-align: center !important;
  margin-bottom: 3vw;
  box-sizing: border-box;
}
.WithdrawAmount__AmountInput::placeholder {
  text-align: center !important;
}
.WithdrawAmount__CurrencyTxt {
  position: absolute;
  top: 2vw;
  left: 12vw;
  font-size: 5.5vw;
  color: #2d2d2d;
  z-index: 1;
  margin: 0;
  line-height: 6vw;
}
.WithdrawAmount__AmountRands {
  width: 100%;
  text-align: center;
  font-size: 5.5vw;
  color: #ffbf12;
  z-index: 1;
  margin: 0 0 3vw;
  line-height: 6vw;
}
.WithdrawAmount__Line {
  display: block;
  margin: 0 auto 3vw;
}
.WithdrawAmount__TransFeesTxt {
  width: 100%;
  text-align: center;
  font-size: 4vw;
  color: #b7b7b7;
  z-index: 1;
  margin: 0;
  line-height: 6vw;
}
.WithdrawAmount__NextBtn {
  width: fit-content;
  font-size: 4vw;
  line-height: 9vw;
  margin: 0 auto;
  padding: 0 18vw;
  background-color: #ffbf12;
  border-radius: 20px;
}
